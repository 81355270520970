import { Breadcrumb, Button, Card, Row } from "antd";
import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { ROLES } from "../../../util/Route";

const TagPage = (props) => {
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.Tag}`,
            title: "Tag",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Tag.getAll,

            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
              }));
            },
            column: CONSTANTS.TABLE.TAG,
          }}
          UPDATE={{
            API: CONSTANTS.API.Tag.update,
            message: "Updated Tag successfully",
            modaltitle: "Update Tag",
            modalFields: CONSTANTS.FORM_FIELD.TAG,
            // payloadModifier: (res) => res,
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add a Tag",
              API: CONSTANTS.API.Tag.create,
              message: "Created Tag successfully",
              modaltitle: "Add Tag",
              modalFields: CONSTANTS.FORM_FIELD.TAG,
            }
          }
          DELETE={{
            API: CONSTANTS.API.Tag.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this category?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default TagPage;
