import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator, getClearObject } from "../../../../util/functions";
import useHttp from "../../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { ROLES, ROUTES, appRoot } from "../../../../util/Route";

const BeachMain = ({ beachData, refresher, identity, notdisabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  // const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectedState, setSelectedState] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);
  const navigate = useNavigate();
  const API = useHttp();
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        setEditedData(values);
        setIsEditing(false);

        let payload = getClearObject(values);
        if (payload?.altName) {
          payload.altName = payload?.altName
            ?.split(",")
            ?.map((alt) => alt?.trim());
        }
        if (payload?.longitude && payload?.latitude) {
          payload.GeoLoc = {
            type: "Point",
            coordinates: [payload.longitude, payload.latitude],
          };
        }
        delete payload?.longitude;
        delete payload?.latitude;
        // if (selectedCity !== beachData?.city?.id && selectedCity) {
        //   payload.cityId = selectedCity;
        // }
        if (beachData?.cityId === payload?.cityId) {
          delete payload?.cityId;
          delete payload?.stateId;
          delete payload?.countryId;
        }
        if (beachData?.islandId === payload?.islandId) {
          delete payload?.islandId;
        }

        if (beachData?.islandId && values?.islandId === undefined) {
          payload = { ...payload, islandId: null };
        }

        if (beachData?.droneVideo && !payload?.droneVideo) {
          payload.droneVideo = "";
        }
        if (beachData?.id) {
          const UpadteBeachMain = apiGenerator(CONSTANTS.API.BeachMain.update, {
            id: beachData?.id,
          });
          API.sendRequest(
            UpadteBeachMain,
            () => {
              if (payload?.cityId) {
                navigate(`${appRoot}/${ROUTES.BEACH}`);
              }
              if (payload?.name !== beachData?.name) {
                navigate(`${appRoot}/${ROUTES.BEACH}`);
              }
              refresher();
            },
            payload,
            "BeachMain Details Updated Successfully"
          );
        }
        // onCreate(values, () => {
        //   setDefaultData(null);
        // });
        // onChangedFields(changedFields);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  useEffect(() => {
    setEditedData((prev) => {
      return prev?.name
        ? { ...prev }
        : {
            ...beachData,
            altName: beachData?.altName?.join(", ") || "",
            latitude: beachData?.GeoLoc?.coordinates?.[1] || "",
            longitude: beachData?.GeoLoc?.coordinates?.[0] || "",
            rating100: beachData?.rating100 || "",
            droneVideo: beachData?.droneVideo || "",
            name: beachData?.name,
            // countryId: {
            //   value: beachData?.city?.state?.country?.id,
            //   label: beachData?.city?.state?.country?.name,
            // },
            // cityId: {
            //   value: beachData?.city?.id,
            //   label: beachData?.city?.name,
            // },
            countryId: beachData?.city?.state?.country?.id,
            stateId: beachData?.city?.state?.id,
            cityId: beachData?.city?.id,
            // topFifty: beachData?.TopFifty,
            // favorite: beachData?.favorite,
          };
    });
    // setSelectedCountry(beachData?.city?.state?.country?.id);
    // setSelectedState(beachData?.city?.state?.id);
    // setSelectedCity(beachData?.city?.id);
  }, [beachData]);
  const [form] = Form.useForm();
  return (
    <>
      <Card bordered className="m-4">
        <Typography.Title level={3} className="flex justify-between">
          Beach Main
          {[
            ROLES.ADMIN,
            ROLES.DATA,
            ROLES.IMAGE,
            ROLES.INTERN,
            ROLES.SEO,
            ROLES.SUPER_ADMIN,
            ROLES.SUPERUSER,
            ROLES.WRITER,
          ].some((el) => el === identity) &&
            notdisabled &&
            (isEditing ? (
              <Button
                type="primary"
                danger
                onClick={() => {
                  // setEditedData({});
                  setIsEditing(false);
                  refresher();
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button danger onClick={handleEdit}>
                Edit
              </Button>
            ))}
        </Typography.Title>

        <FormFields
          menuFields={CONSTANTS.FORM_FIELD.BEACHMAIN_EDIT}
          formData={editedData}
          form={form}
          disabled={!isEditing}
        />
        {editedData?.droneVideo &&
          /^https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]{11})$/.test(
            editedData?.droneVideo
          ) && (
            <Row className=" justify-center mt-3">
              <iframe
                title="Beach Video"
                id="videoplayer-beach"
                src={editedData?.droneVideo}
                style={{ minWidth: "400px", minHeight: "250px" }}
              ></iframe>
            </Row>
          )}
        {isEditing && (
          <>
            <Button type="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </>
        )}
      </Card>
    </>
  );
};

export default BeachMain;
