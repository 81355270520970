import { Card, Col, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import UserGraph from "./UserGraph";
import { ROLES } from "../../../util/Route";
// import useIndexedDB from "../../../hooks/use-IndexedDB";
// import UserList from "./UserList";

const Dashboard = (props) => {
  const [data, setData] = useState({});
  const DETAILS = [
    { title: "Total Beaches", count: data?.totalBeach || 0 },
    { title: "Total Cover Photos", count: data?.CoverPhoto || 0 },
    { title: "Total Drone Photos", count: data?.DronePhoto || 0 },
    {
      title: "Total 5-Star Photos",
      count: data?.FiveStarPhoto || 0,
    },
    { title: "Total No. Of Photos", count: data?.totalPhoto || 0 },
    { title: "Total Pro Photos", count: data?.ProPhoto || 0 },
  ];

  const API = useHttp();
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.overview, (res) => {
      setData({ ...res?.data, ...res?.data?.imageCounts });
    });
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} className="mt-6">
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            key={el?.title + i}
          >
            <Card size="small" className="p-5">
              <Statistic title={el?.title} value={el?.count} />
            </Card>
          </Col>
        ))}
      </Row>
      {/* {[
        // ROLES.ADMIN,
        // ROLES.DATA,
        // ROLES.IMAGE,
        // ROLES.INTERN,
        // ROLES.SEO,
        ROLES.SUPER_ADMIN,
        // ROLES.SUPERUSER,
        // ROLES.WRITER,
      ].some((el) => el === props?.profile?.role) && (
        <Card className="mt-5">
          <UserGraph />
        </Card>
      )} */}
    </>
  );
};

export default Dashboard;
