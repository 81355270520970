import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { apiGenerator } from "../../util/functions";
import { Checkbox, Col, Form, Row, Select } from "antd";
const { Option } = Select;

const CitySatateCountry = ({
  disabled = false,
  RequiredObject = {
    cityId: true,
    stateId: true,
    countryId: true,
    islandId: true,
  },
  VisibleObject = {
    cityId: true,
    stateId: true,
    countryId: true,
    islandId: true,
  },
  label = false,
}) => {
  const [clear, setClear] = useState(true);
  const [isIsland, setIsisland] = useState(false);

  const [statesData, setStatesData] = useState({});
  const [citiesData, setCitiesData] = useState({});
  const [islandData, setIslandData] = useState({});

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [islands, setIslands] = useState([]);

  const API = useHttp();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedIsland, setSelectedIsland] = useState(null);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setClear(false);
    const StateAPI = { ...CONSTANTS.API.getAllState };
    StateAPI.endpoint = `${StateAPI.endpoint}${value}`;
    if (value) {
      if (statesData?.[`${value}`]) {
        setStates(statesData[`${value}`]);
      } else {
        API.sendRequest(StateAPI, (res) => {
          setStatesData((prev) => ({
            ...prev,
            [`${value}`]: res?.data?.rows,
          }));
          setStates(res?.data?.rows);
        });
      }
    }
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(null);

    const CityAPI = apiGenerator(CONSTANTS.API.getAllCity, {
      countryId: selectedCountry,
      stateId: value,
    });
    if (value) {
      if (citiesData?.[`${selectedCountry}_${value}`]) {
        setCities(citiesData[`${selectedCountry}_${value}`]);
      } else {
        API.sendRequest(CityAPI, (res) => {
          setCitiesData((prev) => ({
            ...prev,
            [`${selectedCountry}_${value}`]: res?.data?.rows,
          }));
          setCities(res?.data?.rows);
        });
      }
    }
  };
  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCities(citiesData[`${selectedCountry}_${value}`] || []);
  };
  const handleIslandChange = (value) => {
    setSelectedIsland(value);
  };
  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getAllCountry, (res) => {
      setCountries(res?.data?.rows?.filter((el) => el?.name && el?.slug) || []);
    });
  }, []);
  useEffect(() => {
    if (selectedCountry && selectedState) {
      if (statesData?.[`${selectedCountry}`]) {
        setStates(statesData[`${selectedCountry}`]);
      } else {
        const StateAPI = { ...CONSTANTS.API.getAllState };
        StateAPI.endpoint = `${StateAPI.endpoint}${selectedCountry}`;
        API.sendRequest(StateAPI, (res) => {
          setStatesData((prev) => ({
            ...prev,
            [`${selectedCountry}`]: res?.data?.rows,
          }));
          setStates(res?.data?.rows);
        });
      }
    }
    if (selectedCountry && selectedState && selectedCity) {
      if (citiesData?.[`${selectedCountry}_${selectedState}`]) {
        setCities(citiesData[`${selectedCountry}_${selectedState}`]);
      } else {
        const CityAPI = apiGenerator(CONSTANTS.API.getAllCity, {
          countryId: selectedCountry,
          stateId: selectedState,
        });
        API.sendRequest(CityAPI, (res) => {
          setCitiesData((prev) => ({
            ...prev,
            [`${selectedCountry}_${selectedState}`]: res?.data?.rows,
          }));
          setCities(res?.data?.rows);
        });
      }
    }
    if (selectedCountry) {
      if (islandData?.[`${selectedCountry}`]) {
        setIslandData(islandData[`${selectedCountry}`]);
      } else {
        const IslandAPI = { ...CONSTANTS.API.getAllIslands };
        IslandAPI.endpoint = `${IslandAPI.endpoint}${selectedCountry}`;
        API.sendRequest(IslandAPI, (res) => {
          setIslandData((prev) => ({
            ...prev,
            [`${selectedCountry}`]: res?.data?.rows,
          }));
          setIslands(res?.data?.rows);
        });
      }
    }
  }, [selectedCountry, selectedCity, selectedState]);

  return (
    <Row>
      {VisibleObject?.countryId && (
        <Col>
          {label ? <p className="font-semibold ">Country</p> : null}
          <Form.Item
            className="form "
            id="countryId"
            name="countryId"
            getValueProps={(v) => {
              if (v && clear) {
                setSelectedCountry(v);
              }
              return v;
            }}
            rules={[
              () => ({
                validator(_, value) {
                  if ((value && selectedCountry) || !RequiredObject.countryId) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Please Select country"));
                },
              }),
            ]}
            value={selectedCountry}
          >
            <Select
              showSearch
              placeholder="Select a Country"
              onChange={handleCountryChange}
              style={{ width: "200px" }}
              disabled={disabled}
              value={selectedCountry}
              allowClear
              filterOption={(inputValue, option) => {
                const value = `${option?.children}`;
                return value
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
            >
              {countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {VisibleObject?.countryId && VisibleObject?.stateId && (
        <Col>
          {label ? <p className="font-semibold ps-2">State</p> : null}
          <Form.Item
            className="form "
            id="stateId"
            name="stateId"
            dependencies={["countryId"]}
            rules={[
              () => ({
                validator(_, value) {
                  if ((value && selectedState) || !RequiredObject.stateId) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Please Select State"));
                },
              }),
            ]}
            getValueProps={(v) => {
              if (v && selectedCountry && clear) {
                setSelectedState(v);

                return v;
              }
              return null;
            }}
            required={RequiredObject.stateId}
          >
            <Select
              showSearch
              placeholder="Select a State"
              onChange={handleStateChange}
              style={{ width: "200px", margin: "0 8px" }}
              disabled={!selectedCountry || disabled}
              value={selectedState}
              allowClear
              filterOption={(inputValue, option) => {
                const value = `${option?.children}`;
                return value
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
            >
              {states.map((state) => (
                <Option key={state.id} value={state.id}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {VisibleObject?.countryId &&
        VisibleObject?.stateId &&
        VisibleObject?.cityId && (
          <Col>
            {label ? <p className="font-semibold">City</p> : null}
            <Form.Item
              className="form"
              id="cityId"
              name="cityId"
              dependencies={["countryId", "stateId"]}
              getValueProps={(v) => {
                if (v && selectedCountry && selectedState && clear) {
                  setSelectedCity(v);

                  return v;
                }
                return null;
              }}
              rules={[
                () => ({
                  validator(_, value) {
                    if ((value && selectedCity) || !RequiredObject.cityId) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Please Select City"));
                  },
                }),
              ]}
            >
              <Select
                showSearch
                placeholder="Select a City"
                value={selectedCity}
                onChange={handleCityChange}
                style={{ width: "200px" }}
                allowClear
                filterOption={(inputValue, option) => {
                  const value = `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                disabled={!selectedState || !selectedCountry || disabled}
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      {/* {VisibleObject?.islandId && (
        <div className="flex justify-center items-center ps-3">
          Is it an Island Beach?
          <Checkbox
            onChange={() => {
              setIsisland(!isIsland);
            }}
            className="ps-3"
          ></Checkbox>
        </div>
      )} */}
      {VisibleObject?.islandId && (
        <Col>
          {label ? (
            <p className="font-semibold ps-2">Island</p>
          ) : null}
          <Form.Item
            className="form ps-2"
            id="islandId"
            name="islandId"
            // getValueProps={(v) => {
            //   if (v && clear) {
            //     setSelectedIsland(v);
            //   }
            //   return v;
            // }}
            getValueProps={(v) => {
              if (v && clear) {
                setSelectedIsland(v); // Ensure selectedIsland is updated with the value from the form
              } else if (!v) {
                setSelectedIsland(undefined); // Clear selectedIsland if the value is cleared
              }
              return { value: v };
            }}
            rules={[
              () => ({
                validator(_, value) {
                  if ((value && selectedIsland) || !RequiredObject.islandId) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Please Select Island"));
                },
              }),
            ]}
            value={selectedIsland}
          >
            <Select
              showSearch
              placeholder="Select an Island"
              onChange={handleIslandChange}
              style={{ width: "200px" }}
              disabled={disabled}
              value={selectedIsland}
              allowClear
              filterOption={(inputValue, option) => {
                const value = `${option?.children}`;
                return value
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
            >
              {islands.map((island) => (
                <Option key={island.id} value={island.id}>
                  {island.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default CitySatateCountry;
