import {Outlet, useNavigate} from "react-router-dom";
import {Avatar, Button, Col, Drawer, Image, Layout, Menu, Row} from "antd";
import {useEffect, useState} from "react";
import CONSTANTS from "../../util/constant/CONSTANTS";
import Profile from "../../asset/image/dummy-avatar.jpg";
import {getAuthToken} from "../../util/API/authStorage";
import {deleteAuthDetails} from "../../util/API/authStorage";
import logo from "../../asset/logos/icon.svg";
import useHttp from "../../hooks/use-http";
import ALL_ROUTES, {ROLES, loginRoot} from "../../util/Route";
import {generateMenuFromRoutes} from "../../util/functions";
import useLocalStorage from "../../hooks/use-localStorage";
import {MdSettingsSuggest} from "react-icons/md";
import SuggestionForm from "../common/SuggestionForm";

const {Header, Content, Sider} = Layout;
// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }
// const items = [
//   ...data.map((el) =>
//     getItem(
//       el.label,
//       `/app/${el.id}`,
//       el.icon,
//       el.subMenu &&
//         el.subMenu.map((elp) =>
//           getItem(elp.label, `/app/${el.id}/${elp.id}`, elp.icon)
//         )
//     )
//   ),
// ];
// console.log(items);
const AppLayout = (props) => {
  // const [profile, SetProfile] = props;
  const {profile, SetProfile} = props;
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [UserData, setUserData] = useState({});
  // const [profile, SetProfile] = useLocalStorage("profile", { role: ROLES.ADMIN });
  // useEffect(() => {
  // const isLogin = getAuthToken() !== undefined && getAuthToken() !== null;
  // console.log(isLogin, "Login");
  // navigate(loginRoot);

  // if (!isLogin) {
  // }
  // }, [navigate]);
  const api = useHttp();
  useEffect(() => {
    if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
      navigate(loginRoot);
      return;
    }
    if (!CONSTANTS.GETMe) {
      api.sendRequest(CONSTANTS.API.getMe, (res) => {
        // console.log(res, "API");
        CONSTANTS.GETMe = res?.data;
        setUserData(res?.data);
        SetProfile({...res?.data});
      });
    }
    // } else {
    //   setUserData(...CONSTANTS.GETMe);
    // }
  }, []);

  const onClose = () => {
    setVisible(false);
  };
  const handleLogout = () => {
    deleteAuthDetails();
    localStorage.clear();

    navigate("/");
  };

  return (
    <>
      <Layout
        hasSider={true}
        style={{
          minHeight: "100vh",
          // userSelect: "none",
        }}
      >
        <Sider
          // trigger={<MenuOutlined />}
          // style={{
          //   overflow: "hidden",
          //   overflowY: "scroll",
          //   maxHeight: "100vh",
          // }}
          theme="light"
          collapsible
          // collapsed={collapsed}
        >
          <Row
            className="items-center justify-center"
            // style={{ padding: "0px 35px" }}
          >
            <Col span={24} className="flex items-center justify-center py-3">
              <Image preview={false} src={logo} width={40} />
            </Col>
          </Row>
          <Menu
            defaultSelectedKeys={window.location.pathname}
            activeKey={window.location.pathname}
            mode="inline"
            // items={items}
            items={generateMenuFromRoutes(
              ALL_ROUTES(),
              profile?.role || ROLES.ADMIN
            )}
            onClick={(e) => {
              if (e.keyPath.length) {
                navigate(e.key);
              }
            }}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              backgroundColor: "#FFFFFF",
              justifyContent: "space-between",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{padding: "0px 35px"}}
            >
              <Col span={24} className="flex items-end justify-end py-3">
                <SuggestionForm profile={profile} />
                <Avatar
                  src={UserData?.photo || Profile}
                  size={40}
                  style={{
                    border: "1px solid black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <Outlet />

            {/* {getAuthToken() !== undefined && getAuthToken() !== null && (
              <Outlet />
            )} */}
          </Content>
        </Layout>
      </Layout>

      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <div className="flex-x center text-center profile-drawer">
          <div>
            <Avatar
              size={100}
              style={{color: "#fffff", backgroundColor: "#000000"}}
              className="mt-10"
              src={UserData?.photo || Profile}
            />

            <div className="mt-5 text-2xl font-medium">
              {UserData?.name || "Website Admin"}
            </div>
            <div className=" my-1 text-slate-400">
              Role : {UserData?.role || "User"}
            </div>
            {/* <div className="an-24 regular-text mt20">{UserData?.siteName}</div> */}
            <div className="text-slate-500">
              {UserData?.email || "admin@test.com"}
            </div>
            <Button
              danger
              htmlType="submit"
              className="mt-5 w-40 h-10"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </div>
        <hr className="my30" style={{background: "#E4E8F0"}} />
      </Drawer>
    </>
  );
};

export default AppLayout;
