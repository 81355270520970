import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Popconfirm,
  Row,
  Spin,
  Switch,
} from "antd";
import React, { useState } from "react";
import BeachCard from "../../../../component/common/basic/Beach-Card";
import { useEffect } from "react";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import AddBeachImage from "../../../../component/common/Beach/Add-Beach-Image";
import { BeachPhotoEditor } from "../../../../component/common/basic/Beach-Photo-Editor";
import { MdDeleteForever } from "react-icons/md";
import { ROLES } from "../../../../util/Route";

const Photos = ({ beachData, identity, notdisabled = false }) => {
  const [imageData, SetIamgesData] = useState(null);
  const [selection, setSelection] = useState(false);
  const [preview, setPreview] = useState(false);
  const [selected, setSelected] = useState([]);
  const [images, SetIamges] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const API = useHttp();
  const APIPhoto = useHttp();

  useEffect(() => {
    if (beachData?.id) {
      const FinOneBeach = { ...CONSTANTS?.API.Beach.getAll };
      FinOneBeach.endpoint = `${FinOneBeach?.endpoint}/getMultipleImages/${beachData?.id}`;
      // FinOneBeach.endpoint = `${FinOneBeach?.endpoint}/getMultipleImages/${param?.country}/${param?.state}/${param?.city}/${param?.name}`;
      API.sendRequest(FinOneBeach, (res) => {
        // SetIamgesData(res?.data);
        SetIamges(res?.data?.images?.sort((el) => el?.index) || []);
      });
    }
  }, [beachData]);
  useEffect(() => {
    if (beachData?.id) {
      const FinOneBeach = { ...CONSTANTS?.API.Beach.getAll };
      FinOneBeach.endpoint = `${FinOneBeach?.endpoint}/getMultipleImages/${beachData?.id}`;
      // FinOneBeach.endpoint = `${FinOneBeach?.endpoint}/getMultipleImages/${param?.country}/${param?.state}/${param?.city}/${param?.name}`;
      APIPhoto.sendRequest(FinOneBeach, (res) => {
        // SetIamgesData(res?.data);
        SetIamges(res?.data?.images?.sort((el) => el?.index) || []);
      });
    }
  }, [refresh]);
  // useEffect(() => {
  //   SetIamges(
  //     imageData?.images || []

  //     // ?.map((el) => (
  //     //   <BeachCard
  //     //     {...el}
  //     //     key={el?.id}
  //     //     selection={selection}
  //     //     setSelected={setSelected}
  //     //     setSelection={setSelection}
  //     //     selected={selected}
  //     //   />
  //     // )) || []
  //   );
  // }, [imageData]);
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(images, sourceIndex, targetIndex);
    SetIamges(nextState);
    const length = nextState.length;
    // console.log(
    //   nextState,
    //   nextState?.map((el, i) => ({ id: el?.id, index: length - i }))
    // );
    const payload = {
      updateArray: nextState?.map((el, i) => ({
        id: el?.id,
        index: length - i,
      })),
    };
    if (
      payload.updateArray.length &&
      [
        ROLES.ADMIN,
        // ROLES.DATA,
        ROLES.IMAGE,
        // ROLES.INTERN,
        // ROLES.SEO,
        ROLES.SUPER_ADMIN,
        // ROLES.SUPERUSER,
        // ROLES.WRITER,
      ].some((el) => el === identity) &&
      notdisabled
    ) {
      APIPhoto.sendRequest(
        CONSTANTS.API.BeachPhoto.IndexUpdate,
        (res) => {
          // console.log(res);
        },
        payload
      );
    }

    // const nextState = swap(imageData, sourceIndex, targetIndex);
    // SetIamgesData(nextState);
  }
  // console.log(imageData);
  return (
    <>
      <Card bordered className="m-4">
        {API.isLoading ? (
          <Row className=" items-center justify-center">
            <Spin size="large" />
          </Row>
        ) : (
          <>
            <Row className=" justify-end mb-3 gap-2 items-center">
              <span className=" flex items-center justify-center gap-2">
                <Checkbox
                  checked={preview}
                  onChange={() => {
                    setPreview((prev) => !prev);
                  }}
                  disabled={!images?.length}
                />
                <p> Preview</p>
                {/* <Switch
                  unCheckedChildren={<p className=" text-white"> Off</p>}
                  checkedChildren={<p className=" text-white"> On</p>}
                  defaultChecked={preview}
                  onChange={() => {
                    setPreview((prev) => !prev);
                  }}
                /> */}
              </span>

              {[
                ROLES.ADMIN,
                ROLES.DATA,
                ROLES.IMAGE,
                ROLES.INTERN,
                ROLES.SEO,
                ROLES.SUPER_ADMIN,
                ROLES.SUPERUSER,
                ROLES.WRITER,
              ].some((el) => el === identity) &&
                notdisabled && (
                  <>
                    <AddBeachImage
                      beachData={beachData}
                      refresher={() => {
                        setRefresh((prev) => !prev);
                      }}
                    />{" "}
                    <Button
                      type="primary"
                      onClick={() => {
                        setSelection((prev) => !prev);
                        setSelected([]);
                      }}
                      disabled={!images?.length}
                    >
                      {!selection ? "Select" : "UnSelect All"}
                    </Button>
                    {selection && selected.length ? (
                      <Popconfirm
                        title="Are you sure to Delete All this Photos?"
                        onConfirm={() => {
                          const payload = {
                            ids: selected?.map((el) => el?.id),
                          };
                          API.sendRequest(
                            CONSTANTS.API.BeachPhoto.DeletePhoto,
                            (res) => {
                              setSelected([]);
                              setRefresh((prev) => !prev);
                            },
                            payload
                          );
                        }}
                      >
                        <MdDeleteForever className=" text-[25px] text-red-500" />
                      </Popconfirm>
                    ) : (
                      ""
                    )}{" "}
                  </>
                )}
            </Row>
            {images?.length ? (
              <>
                {" "}
                <Row>
                  <BeachPhotoEditor
                    source={imageData?.image_o}
                    imageData={imageData}
                    SetIamgesData={SetIamgesData}
                  />
                </Row>
                <Row>
                  <GridContextProvider onChange={onChange}>
                    <GridDropZone
                      id="items"
                      boxesPerRow={4}
                      rowHeight={250}
                      style={{
                        height: `${Math.ceil(images?.length / 4) * 250}px`,
                        width: "100%",
                      }}
                    >
                      {images.map((item) => (
                        <GridItem
                          key={item?.id}
                          className="griditemUI px-1 lg:px-1 xl:px-2 2xl:px-3"
                          // style={{ height: "400px" }}
                        >
                          {/* {item} */}
                          <BeachCard
                            {...item}
                            selection={selection}
                            setSelected={setSelected}
                            setSelection={setSelection}
                            selected={selected}
                            preview={preview}
                            SetIamgesData={SetIamgesData}
                            API={
                              [
                                ROLES.ADMIN,
                                // ROLES.DATA,
                                ROLES.IMAGE,
                                ROLES.INTERN,
                                // ROLES.SEO,
                                ROLES.SUPER_ADMIN,
                                ROLES.SUPERUSER,
                                // ROLES.WRITER,
                              ].some((el) => el === identity) && notdisabled
                                ? APIPhoto
                                : null
                            }
                            refresher={() => {
                              setRefresh((prev) => !prev);
                            }}
                            deleteAllowed={[
                              ROLES.ADMIN,
                              // ROLES.DATA,
                              ROLES.IMAGE,
                              // ROLES.INTERN,
                              // ROLES.SEO,
                              ROLES.SUPER_ADMIN,
                              ROLES.SUPERUSER,
                              // ROLES.WRITER,
                            ].some((el) => el === identity)}
                            // identity={identity}
                          />
                        </GridItem>
                      ))}
                    </GridDropZone>
                  </GridContextProvider>{" "}
                </Row>
              </>
            ) : (
              <>
                <Alert
                  message="No Beach Photos Found"
                  description="We couldn't find any photos of the specified beach. It's possible that there are no images available for this location. Please check the spelling or try another beach to discover beautiful photos."
                  type="info"
                  showIcon
                />
              </>
            )}
            {/* <Row gutter={[16, 16]}>
            {imageData?.images?.map((el, i) => (
                <Col key={el?.id} xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
                  <BeachCard
                    {...el}
                    selection={selection}
                    setSelected={setSelected}
                    setSelection={setSelection}
                    selected={selected}
                  />
                </Col>
              ))}
            </Row> */}
          </>
        )}
      </Card>
    </>
  );
};

export default Photos;
