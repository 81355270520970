import { Breadcrumb, Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import CRUDComponent, {
  payloadGenerator,
} from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import FilterComponent from "../../../component/common/basic/Filter-Component";
import { ROLES, ROUTES, appRoot } from "../../../util/Route";
import useHttp from "../../../hooks/use-http";
import { apiGenerator, getArticle } from "../../../util/functions";

const Location = (props) => {
  const [extraQuery, setExtraQuery] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [locationType, setListicleType] = useState("Country");
  const [externalRefreshState, setExternalRefreshState] = useState(false);

  // const [refresh, setRefresh] = useState(true);
  // const [modalOpen, setModalOpen] = useState(false);
  // const API = useHttp();

  const LocationQueryGenerator = () => {
    const query = {};
    if (locationType === "Country") {
      setSelectedCountry((prev) => null);
    } else if (locationType === "State") {
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
    } else if (locationType === "Island") {
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
    } else if (locationType === "City") {
      if (selectedState) {
        query.stateId = selectedState;
      }
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
    }

    return query;
  };

  const API = useHttp();
  useEffect(() => {
    const AddLocationTagField =
      CONSTANTS.FORM_FIELD.ADD_COUNTRY_STATE_CITY.find(
        (fil) => fil.id === "tagIds"
      );
    const EditLocationTagField =
      CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.find(
        (fil) => fil.id === "tagIds"
      );

    if (AddLocationTagField?.filters?.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Tag.getAll,
      (res) => {
        const OptionTags = res?.data?.rows?.map((tag) => ({
          id: tag?.id,
          label: tag?.tag,
          value: tag?.id,
        }));
        // console.log(options);
        AddLocationTagField.option = OptionTags;
        EditLocationTagField.option = OptionTags;
      },
      {
        limit: 1000,
      }
    );
  }, []);
  useEffect(() => {
    setExtraQuery(LocationQueryGenerator());
    setExternalRefreshState((prev) => !prev);
  }, [locationType, selectedCountry, selectedCity, selectedState]);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.BEACH}`,
            title: "Location",
          },
          {
            title: locationType,
          },
        ]}
      />

      <Row className="mt-6" gutter={[6, 8]}>
        <Col>
          <Button
            onClick={() => {
              setListicleType("Country");
            }}
            type={`${locationType === "Country" ? "primary" : "default"}`}
          >
            Country
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setListicleType("State");
            }}
            type={`${locationType === "State" ? "primary" : "default"}`}
          >
            State
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setListicleType("City");
            }}
            type={`${locationType === "City" ? "primary" : "default"}`}
          >
            City
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setListicleType("Island");
            }}
            type={`${locationType === "Island" ? "primary" : "default"}`}
          >
            Island
          </Button>
        </Col>
      </Row>

      <Row>
        <CRUDComponent
          AddOnComponent={
            <>
              {/* <Col
                // span={24}
                // sm={24}
                // md={1}
                // lg={1}
                // xl={1}
                // xxl={1}
                className="flex justify-end"
              >
                <Button
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Add a&nbsp;
                  {selectedState
                    ? "City"
                    : selectedCountry
                    ? "State"
                    : "Country"}
                </Button>
              </Col> */}

              <Col span={24} sm={24} md={22} lg={22} xl={17} xxl={17}>
                <FilterComponent
                  selectedCity={selectedCity}
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  setSelectedCity={setSelectedCity}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedState={setSelectedState}
                  VisibleObject={{
                    cityId: false,
                    stateId:
                      locationType !== "State" && locationType !== "Island",
                    countryId: locationType !== "Country",
                  }}
                />
              </Col>
            </>
          }
          GET={{
            API: CONSTANTS.API?.[locationType]?.getAll,
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                // cityName: el?.city?.name,
                stateName: el?.state?.name,
                countryName: el?.state?.country?.name || el?.country?.name,
                rankInput: {
                  value: el?.rank,
                  id: el?.id,
                  onChange: (v) => {
                    if (locationType === "Country") {
                      const rankUpdate = apiGenerator(
                        CONSTANTS.API.Country.updateRank,
                        {},
                        v.id
                      );
                      API.sendRequest(
                        rankUpdate,
                        (res) => {
                          Setrefresh((prev) => !prev);
                        },
                        {
                          rank: v?.value,
                        },
                        "Rank save successfully."
                      );
                    }
                    if (locationType === "State") {
                      const rankUpdate = apiGenerator(
                        CONSTANTS.API.State.updateRank,
                        {},
                        v.id
                      );
                      API.sendRequest(
                        rankUpdate,
                        (res) => {
                          Setrefresh((prev) => !prev);
                        },
                        {
                          rank: v?.value,
                        },
                        "Rank save successfully."
                      );
                    }
                    if (locationType === "City") {
                      const rankUpdate = apiGenerator(
                        CONSTANTS.API.City.updateRank,
                        {},
                        v.id
                      );
                      API.sendRequest(
                        rankUpdate,
                        (res) => {
                          Setrefresh((prev) => !prev);
                        },
                        {
                          rank: v?.value,
                        },
                        "Rank save successfully."
                      );
                    }
                    if (locationType === "Island") {
                      const rankUpdate = apiGenerator(
                        CONSTANTS.API.Island.updateRank,
                        {},
                        v.id
                      );
                      API.sendRequest(
                        rankUpdate,
                        (res) => {
                          Setrefresh((prev) => !prev);
                        },
                        {
                          rank: v?.value,
                        }
                      );
                    }
                  },
                },
                view: {
                  link:
                    locationType === "Island"
                      ? `${appRoot}/${ROUTES.LOCATION}/island/${
                          el?.state?.id ? `${el?.state?.id}/` : ""
                        }${el?.id}`
                      : `${appRoot}/${ROUTES.LOCATION}/${
                          el?.state?.country?.id || el?.country?.id
                            ? `${el?.state?.country?.id || el?.country?.id}/`
                            : ""
                        }${el?.state?.id ? `${el?.state?.id}/` : ""}${el?.id}`,
                  id: el?.id,
                },
              }));
            },
            column: CONSTANTS.TABLE.LOCATION?.filter((el) => {
              if (el?.dataIndex === "stateName") {
                return locationType === "City";
              }
              if (
                el?.dataIndex === "stateName" ||
                el?.dataIndex === "countryName"
              ) {
                return locationType !== "Country";
              }
              return true;
            }),
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: `Add ${getArticle(locationType)} ${locationType}`,
              API: CONSTANTS.API?.[locationType].create,
              message: "Created Beach successfully",
              modaltitle: `Add ${getArticle(locationType)} ${locationType} `,
              modalFields: CONSTANTS.FORM_FIELD.ADD_COUNTRY_STATE_CITY.filter(
                (el) =>
                  !el?.fieldFilter ||
                  !Array.isArray(el?.fieldFilter) ||
                  el?.fieldFilter?.findIndex((fil) => fil === locationType) !==
                    -1
              ),
              isFormData: true,
              payloadModifier: (values, objValue) => {
                // const payload = getClearObject(values);
                // if (payload?.altName) {
                //   payload.altName = payload?.altName
                //     ?.split(",")
                //     ?.map((alt) => alt?.trim());
                // }
                // if (payload?.longitude && payload?.latitude) {
                //   payload.GeoLoc = {
                //     type: "Point",
                //     coordinates: [payload.longitude, payload.latitude],
                //   };
                // }
                // delete payload?.longitude;
                // delete payload?.latitude;
                // if (payload.cityId) {
                //   delete payload?.stateId;
                //   delete payload?.countryId;
                // }
                // return payload;
                if (objValue?.stateId && locationType === "City") {
                  // values.append("cityId", objValue?.cityId);
                  values.append("stateId", objValue?.stateId);
                  values.append("countryId", objValue?.countryId);
                }
                console.log(values, objValue, locationType);
                if (
                  objValue?.countryId &&
                  (locationType === "State" || locationType === "Island")
                ) {
                  // values.append("cityId", objValue?.cityId);
                  // values.append("stateId", objValue?.stateId);
                  values.append("countryId", objValue?.countryId);
                }
                // if (objValue?.countryId) {
                //   // values.append("countryId", objValue?.countryId);
                // }

                return values;
              },
            }
          }
          isSearch
          selectionOff
          props={props}
          externalRefreshState={externalRefreshState}
        />
      </Row>
    </>
  );
};

export default Location;
