import React, { useEffect, useState } from "react";
import { Button, Col, Image, Input, Row, Spin } from "antd";
import { getClearObject } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";

const GooglePhotoLocation = ({ locationData, API, onSuccess }) => {
  const [serpPhoto, setSerpPhoto] = useState([]);
  const [title, setTitle] = useState("");
  const [resfresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [nextPageLink, setnextPageLink] = useState(null);

  useEffect(() => {
    const payload = getClearObject({
      ...pagination,
      search: title,
      nextPageLink,
    });
    title &&
      API.sendRequest(
        CONSTANTS.API.SERP,
        (res) => {
          console.log(res, res?.nextPageLink, !!res?.nextPageLink);
          if (!!res?.nextPageLink) {
            setnextPageLink(res?.nextPageLink);
          } else {
            setnextPageLink("");
          }

          if (pagination.page === 1) {
            setSerpPhoto(res?.data);
          } else {
            setSerpPhoto((prev) => [...prev, ...res?.data]);
          }
        },
        payload
      );
  }, [pagination.page, resfresh]);
  useEffect(() => {
    setTitle(locationData?.name);
    setRefresh((prev) => !prev);
  }, [locationData]);
  console.log(
    nextPageLink === null || (!!nextPageLink && pagination.page > 0),
    nextPageLink === null,
    !!nextPageLink && pagination.page > 0,
    nextPageLink,
    pagination.page
  );
  return (
    <div>
      <Row>
        <Col sm={24} lg={8} className="p-3">
          <Input.Search
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onSearch={(v) => {
              setTitle(v);
              setPagination((prev) => {
                if (prev.page === 1) {
                  setRefresh((prevRef) => !prevRef);
                }
                return { ...prev, page: 1 };
              });
            }}
          />
        </Col>
      </Row>
      <Row className=" items-center justify-center">
        {serpPhoto?.map((img, i) => (
          <Col
            key={i}
            // style={{ height: "150px" }}
            sm={24}
            md={8}
            lg={6}
            className="p-3"
          >
            <Image
              alt="example"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                // minHeight: "175px",
                // maxHeight: "175px",
              }}
              style={{ width: "100%", height: "175px" }}
              rootClassName=" rounded-lg"
              wrapperClassName=" rounded-lg"
              draggable="false"
              className="!w-full !h-full rounded-lg cursor-pointer"
              key={img?.id}
              src={img?.image}
              onClick={() => {
                let description = img?.description?._content;

                const payload = {
                  imageUrl: img?.image,
                  licenseName: "CC BY 2.0",
                  licenseLink: "",
                  photoName: img?.user?.name,
                  photoLink: img?.user?.image,
                  photographerName: img?.user?.name,
                  photographerLink: img?.user?.link,
                  photoSource: "Google Photos",
                  description,
                };

                if (!API?.isLoading) {
                  const formData = new FormData();
                  Object?.entries(payload).forEach(([key, value]) => {
                    formData.append(key, value);
                  });
                  onSuccess(formData);
                }
              }}
              preview={false}
              //   src={
              //     img?.urls.small_s3 ??
              //     img?.urls?.regular ??
              //     img?.urls?.full ??
              //     img?.urls?.raw
              //   }
              // src={FinalImageGenerator(item, 1400)}
            />
            {/* <AddImageCard
              original={img}
              img={{
                small: img?.thumbnail,
                large: img?.image,
                id: img?.id,
              }}
              loading={API.isLoading}
              onAdd={(checkboxValues, image) => {
                let description = image?.description;
                // if (descriptionElement) {
                //   description = descriptionElement.value;
                // }

                const payload = {
                  imageUrl: image?.image,
                  AllBeachId: locationData?.id,
                  licenseName: "CC BY 2.0",
                  licenseLink: "",
                  photoName: locationData?.name,
                  photoLink: image?.user?.image,
                  photographerName: image?.user?.name,
                  photographerLink: image?.user?.image,
                  photoSource: "Google Photos",
                  description,
                  ...checkboxValues,
                };
                if (masterRefresh) {
                  payload.isCoverPhoto = true;
                }
                API.sendRequest(
                  CONSTANTS.API.BeachPhoto.AddPhoto,
                  (res) => {
                    if (masterRefresh) {
                      refresher();
                    }
                  },
                  payload
                );
              }}
            /> */}
          </Col>
        ))}
      </Row>
      <Row className=" items-center justify-center my-3">
        {API.isLoading ? (
          <Spin size="large" />
        ) : nextPageLink === null || (!!nextPageLink && pagination.page > 0) ? (
          <Button
            type="primary"
            onClick={() => {
              setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
            }}
          >
            Load More
          </Button>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
};

export default GooglePhotoLocation;
