import { Button, Card, Form, Image, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { MdRefresh } from "react-icons/md";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator, getClearObject } from "../../../../util/functions";
import { ROLES } from "../../../../util/Route";

const Setting = ({ beachData, refresher, identity, notdisabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [beachSEO, SetBeachSEO] = useState({});
  const [refresh, setRefresh] = useState(true);

  const API = useHttp();
  const handleEdit = () => {
    setIsEditing(true);
  };
  useEffect(() => {
    if (beachData?.id) {
      API.sendRequest(
        CONSTANTS.API.BeachSEO.getOne,
        (seo) => {
          SetBeachSEO(seo?.data?.[0]);
          setEditedData(seo?.data?.[0]);
        },
        { AllBeachId: beachData?.id }
      );
    }
  }, [beachData, refresh]);
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
        const payload = getClearObject(values);
        // if (payload?.keywords) {
        //   payload.keywords = `${payload.keywords}`.split(",");
        // }
        // setEditedData(values);
        setIsEditing(false);
        if (beachSEO?.id && beachData?.id) {
          payload.AllBeachId = beachData?.id;
          const UpdateSummary = apiGenerator(CONSTANTS.API.BeachSEO.update, {
            id: beachSEO?.id,
          });
          API.sendRequest(
            UpdateSummary,
            (res) => {
              setEditedData(values);
              setRefresh((prev) => !prev);
            },
            payload,
            "SEO Updated Successfully"
          );
        } else {
          if (beachData?.id) {
            const CreateSummary = apiGenerator(CONSTANTS.API.BeachSEO.create);
            payload.AllBeachId = beachData?.id;
            API.sendRequest(
              CreateSummary,
              (res) => {
                setEditedData(values);
                setRefresh((prev) => !prev);
              },
              payload,
              "SEO Updated Successfully"
            );
          }
        }

        // onCreate(values, () => {
        //   setDefaultData(null);
        // });
        // onChangedFields(changedFields);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const [form] = Form.useForm();
  return (
    <>
      <Card bordered className="m-4">
        <Typography.Title level={3} className="flex justify-between">
          Beach SEO
          <Row>
            {/* <Button onClick={refresher} className="mx-1">
              <MdRefresh />
            </Button> */}
            {[
              ROLES.ADMIN,
              ROLES.DATA,
              ROLES.IMAGE,
              ROLES.INTERN,
              ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              ROLES.WRITER,
            ].some((el) => el === identity) &&
              notdisabled &&
              (isEditing ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    // setEditedData({});
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <Button danger onClick={handleEdit}>
                  Edit
                </Button>
              ))}
          </Row>
        </Typography.Title>
        <FormFields
          menuFields={CONSTANTS.FORM_FIELD.BEACH_SETTING}
          formData={editedData}
          form={form}
          disabled={!isEditing}
        />
        {isEditing && (
          <>
            <Button type="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </>
        )}
        {/* : ( BasicArray?.map( (el) => el?.condition && (
        <Typography.Title
          key={el?.label}
          level={5}
          className="!py-1 !my-1 flex"
        >
          {`${el?.label} : `}
          <span className="font-light ml-1">{el?.value}</span>
        </Typography.Title>
        ) ) ) */}
        {/* <Descriptions title="Beach Main">
          {DisplayData?.map(
            (el) =>
              el?.condition && (
                <Descriptions.Item label={el?.label}>
                  {el?.value}
                </Descriptions.Item>
              )
          )}
        </Descriptions> */}
      </Card>
    </>
  );
};

export default Setting;
