import { Breadcrumb, Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS, { ROLES } from "../../../util/constant/CONSTANTS";
import FilterComponent from "../../../component/common/basic/Filter-Component";
import {
  FinalImageGenerator,
  ProceedImageURL,
  getClearObject,
} from "../../../util/functions";
import { useNavigate } from "react-router-dom";
import { ROUTES, appRoot } from "../../../util/Route";
import useHttp from "../../../hooks/use-http";

const Listicle = (props) => {
  const [extraQuery, setExtraQuery] = useState({
    type: "Country",
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [listicleType, setListicleType] = useState("Country");
  const [externalRefreshState, setExternalRefreshState] = useState(false);

  const API = useHttp();

  const ListicLeQueryGenerator = () => {
    const query = {};
    query.type = listicleType;
    if (listicleType === "Country") {
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }

      // query.stateId = null;
      // query.cityId = null;
    } else if (listicleType === "State") {
      // query[`countryName[ne]`] = null;
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
      if (selectedState) {
        query.stateId = selectedState;
      }
      // query.cityId = null;
      // query[`cityName`] = null;
    } else if (listicleType === "City") {
      if (selectedCity) {
        query.cityId = selectedCity;
      }
      if (selectedState) {
        query.stateId = selectedState;
      }
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
    } else if (listicleType === "worldwide") {
      query.worldwide = 1;
    }
    return query;
  };

  useEffect(() => {
    const AddListicleTagField = CONSTANTS.FORM_FIELD.BEACH_LISTICLE.find(
      (fil) => fil.id === "tag"
    );
    const EditListicleTagField = CONSTANTS.FORM_FIELD.BEACH_LISTICLE_EDIT.find(
      (fil) => fil.id === "tag"
    );

    if (AddListicleTagField?.filters?.length) {
      return;
    }
    API.sendRequest(CONSTANTS.API.ListicleTag.getAll, (res) => {
      const OptionTags = res?.tags?.map((tag) => ({
        id: tag,
        label: tag,
        value: tag,
      }));
      // console.log(options);
      AddListicleTagField.option = OptionTags;
      EditListicleTagField.option = OptionTags;
    });
  }, []);
  useEffect(() => {
    setExtraQuery(ListicLeQueryGenerator());
    setExternalRefreshState((prev) => !prev);
  }, [listicleType, selectedCountry, selectedCity, selectedState]);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.BEACH}`,
            title: "Listicles",
          },
          {
            title: listicleType,
          },
        ]}
      />
      <Row className="mt-6" gutter={[6, 8]}>
        <Col>
          <Button
            onClick={() => {
              setListicleType("Country");
            }}
            type={`${listicleType === "Country" ? "primary" : "default"}`}
          >
            Country Listicles
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setListicleType("State");
            }}
            type={`${listicleType === "State" ? "primary" : "default"}`}
          >
            State Listicles
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setListicleType("City");
            }}
            type={`${listicleType === "City" ? "primary" : "default"}`}
          >
            City Listicles
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setListicleType("worldwide");
            }}
            type={`${listicleType === "worldwide" ? "primary" : "default"}`}
          >
            Worldwide Listicles
          </Button>
        </Col>
      </Row>
      <Row>
        <CRUDComponent
          AddOnComponent={
            <>
              <Col span={24} sm={24} md={22} lg={22} xl={17} xxl={17}>
                <FilterComponent
                  selectedCity={selectedCity}
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  setSelectedCity={setSelectedCity}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedState={setSelectedState}
                  VisibleObject={{
                    cityId:
                      listicleType === "City" && listicleType !== "worldwide",
                    stateId:
                      listicleType !== "Country" &&
                      listicleType !== "worldwide",
                    countryId: listicleType !== "worldwide",
                  }}
                />
              </Col>
            </>
          }
          GET={{
            API: CONSTANTS.API.Listicle.getAll,
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                cityName: el?.city?.name,
                stateName: el?.state?.name,
                countryName: el?.country?.name ?? "-",
                // tagIds: el?.locationTags?.map((elp) => elp?.tag?.id),
                view: {
                  link: `${appRoot}/${ROUTES.LISTICLES}/${el?.id}`,
                  id: el?.id,
                },
                image: FinalImageGenerator(el?.listicleImage, 1200, 1),
              }));
            },
            column: CONSTANTS.TABLE.LISTICLE?.filter((el) => {
              if (el?.dataIndex === "cityName") {
                return listicleType === "City";
              }
              if (el?.dataIndex === "stateName") {
                return (
                  listicleType !== "Country" && listicleType !== "worldwide"
                );
              }
              if (el?.dataIndex === "countryName") {
                return listicleType !== "worldwide";
              }
              return true;
            }),
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: `Add a ${listicleType} Listicle`,
              API: CONSTANTS.API.Listicle.create,
              message: "Created Beach successfully",
              modaltitle: `Add a ${listicleType} Listicle`,
              modalFields: CONSTANTS.FORM_FIELD.BEACH_LISTICLE.filter(
                (el) =>
                  !el?.fieldFilter ||
                  !Array.isArray(el?.fieldFilter) ||
                  el?.fieldFilter?.findIndex((fil) => fil === listicleType) !==
                    -1
              ),
              isFormData: true,
              payloadModifier: (values, objValue) => {
                // const payload = getClearObject(values);
                // if (payload?.altName) {
                //   payload.altName = payload?.altName
                //     ?.split(",")
                //     ?.map((alt) => alt?.trim());
                // }
                // if (payload?.longitude && payload?.latitude) {
                //   payload.GeoLoc = {
                //     type: "Point",
                //     coordinates: [payload.longitude, payload.latitude],
                //   };
                // }
                // delete payload?.longitude;
                // delete payload?.latitude;
                // if (payload.cityId) {
                //   delete payload?.stateId;
                //   delete payload?.countryId;
                // }
                // return payload;
                if (objValue?.cityId && listicleType === "City") {
                  values.append("cityId", objValue?.cityId);
                }
                if (
                  objValue?.stateId &&
                  (listicleType === "State" || listicleType === "City")
                ) {
                  values.append("stateId", objValue?.stateId);
                }
                if (objValue?.countryId) {
                  values.append("countryId", objValue?.countryId);
                }
                if (listicleType === "worldwide") {
                  values.append("worldwide", 1);
                }

                return values;
              },
            }
          }
          UPDATE={{
            API: CONSTANTS.API.Listicle.update,
            message: "Updated Listicle successfully",

            modaltitle: `Update a ${listicleType} Listicle`,
            modalFields: CONSTANTS.FORM_FIELD.BEACH_LISTICLE_EDIT.filter(
              (el) =>
                !el?.fieldFilter ||
                !Array.isArray(el?.fieldFilter) ||
                el?.fieldFilter?.findIndex((fil) => fil === listicleType) !== -1
            ),
            isFormData: true,
            payloadModifier: (values, oldValue, newValue) => {
              if (
                newValue?.cityId &&
                listicleType === "City" &&
                newValue?.cityId !== oldValue?.cityId
              ) {
                values.append("cityId", newValue?.cityId);
              }
              if (
                newValue?.stateId &&
                (listicleType === "State" || listicleType === "City") &&
                newValue?.stateId !== oldValue?.stateId
              ) {
                values.append("stateId", newValue?.stateId);
              }
              if (
                newValue?.countryId &&
                newValue?.countryId !== oldValue?.countryId
              ) {
                values.append("countryId", newValue?.countryId);
              }
              // if (listicleType === "worldwide") {
              //   values.append("worldwide", 1);
              // }

              return values;
            },
          }}
          DELETE={{
            API: CONSTANTS.API.Listicle.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Listicle?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          selectionOff
          props={props}
          externalRefreshState={externalRefreshState}
        />
      </Row>
    </>
  );
};

export default Listicle;
