import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../../../hooks/use-http";
import { Breadcrumb, Card, Col, Row, Statistic, Tabs } from "antd";
import { ROUTES, appRoot } from "../../../../util/Route";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import AssignBeachesUser from "./AssignBeachesUser";
import UserBeach from "./UserBeach";
import UserLogs from "./UserLogs";
// import { getClearObject } from "../../../../util/functions";

const UserIdPage = React.memo(() => {
  const [refreshUser, setRefreshUser] = useState(false);

  const { userId } = useParams();
  const [extraQuery, setExtraQuery] = useState({ userId });
  const API = useHttp();
  const [userDetails, SetUserDetails] = useState({});
  useEffect(() => {
    const SingleUSerAPI = apiGenerator(CONSTANTS.API.Users.getOne, {
      id: userId,
    });
    API.sendRequest(SingleUSerAPI, (res) => {
      SetUserDetails(res?.data);
    });
  }, [refreshUser]);
  const DETAILS = [
    {
      title: "Total No. of Inprogress Beaches",
      count: userDetails?.inprogressBeaches,
    },
    { title: "Total No. of ToDo Beaches", count: userDetails?.todoBeaches },
    {
      title: "Total No. of Done Beaches",
      count: userDetails?.doneBeaches,
    },
    // { title: "Total Pro Photos", count: userDetails?.totalProPhoto || 0 },
  ];

  // const MemoizedInnerComponent = useMemo(() => CRUDComponent, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 my-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            href: `${appRoot}/${ROUTES.USERS}`,
            title: "All Users",
          },
          {
            title: userDetails?.name || "User Name",
          },
        ]}
      />
      <Tabs
        defaultActiveKey="1"
        type="card"
        tabBarStyle={{ marginBottom: "0" }}
        tabBarGutter={12}
        items={[
          {
            label: `User Beach Details`,
            key: 1,
            children: (
              <UserBeach
                DETAILS={DETAILS}
                setRefreshUser={setRefreshUser}
                extraQuery={extraQuery}
                setExtraQuery={setExtraQuery}
                userDetails={userDetails}
              />
            ),
          },
          {
            label: `User Log Details`,
            key: 2,
            children: <UserLogs extraQuery={extraQuery} />,
          },
        ]}
      />

      {/* <Row>
        <Statistic title="Email" value={userDetails?.email} />
      </Row> */}
      {/* <Row gutter={[16, 16]} className="mt-6">
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            key={el?.title + i}
          >
            <Card size="small" className="">
              <Statistic title={el?.title} value={el?.count ?? 0} />
            </Card>
          </Col>
        ))}
      </Row>
      <AssignBeachesUser
        refresher={() => {
          setRefreshUser((prev) => !prev);
          setExtraQuery((prev) => ({
            ...prev,
          }));
        }}
        user={userDetails}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.BeachUser.getAll,
            // extraQuery: { userId: userId },
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                name: el?.AllBeach?.name,
                cityName: el?.AllBeach?.city?.name,
                stateName: el?.AllBeach?.city?.state?.name,
                countryName: el?.AllBeach?.city?.state?.country?.name,
                status: {
                  onChange: (v) => {
                    // console.log(v);
                    const UpadteUSerBEach = apiGenerator(
                      CONSTANTS.API.BeachUser.update,
                      { id: el?.id }
                    );
                    API.sendRequest(
                      UpadteUSerBEach,
                      (res) => {
                        Setrefresh((prev) => !prev);
                        setRefreshUser((prev) => !prev);
                      },
                      {
                        status: v,
                      }
                    );
                  },
                  value: el?.status,
                },
              }));
            },

            column: CONSTANTS.TABLE.USER_BEACH,
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          // isSearch
          selectionOff
        />
      </Row> */}
    </>
  );
});

export default UserIdPage;
