import { Breadcrumb, Row } from "antd";
import React, { useState } from "react";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { ROLES, ROUTES, appRoot } from "../../../../util/Route";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../../util/functions";
import AddBeachesListicle from "./Add-Beaches-Listicle";

const ListclesIdWise = (props) => {
  const params = useParams();
  const [listicleDetails, setListicleDetails] = useState("");
  const [extraQuery, setExtraQuery] = useState({});

  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            href: `${appRoot}/${ROUTES.LISTICLES}`,
            title: "Listicle",
          },
          {
            // href: `${appRoot}/${ROUTES.Listicle}`,
            title: listicleDetails?.name,
          },
        ]}
      />
      <AddBeachesListicle
        {...props}
        listiclesMainId={params?.id}
        listicleDetails={listicleDetails}
        refresher={() => {
          setExtraQuery((prev) => ({ ...prev }));
        }}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Listicle_Beaches.getAll,
            extraReplaceObject: {
              listicleId: params?.id,
            },
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              setListicleDetails(res);

              return res?.listiclesBeaches?.map((el) => {
                return {
                  ...el?.AllBeach,
                  ...el,
                  no: `${el?.id}`.padStart(6, "0"),
                  urls: el?.urls?.map((ellInner) => ({
                    url: ellInner,
                  })),
                  rankInput: {
                    value: el?.rank,
                    id: el?.id,
                    onChange: (v) => {
                      const UpadteBeachRandListicle = apiGenerator(
                        CONSTANTS.API.Listicle_Beaches.update,
                        {},
                        v.id
                      );
                      API.sendRequest(
                        UpadteBeachRandListicle,
                        (res) => {
                          Setrefresh((prev) => !prev);
                         
                        },
                        {
                          rank: v?.value,
                        }
                      );
                    },
                  },
                };
              });
            },
            column: CONSTANTS.TABLE.LISTICLE_BEACHES,
          }}
          UPDATE={{
            API: CONSTANTS.API.Listicle_Beaches.update,
            message: "Updated Listicle successfully",
            modaltitle: "Update Listicle",
            modalFields: CONSTANTS?.FORM_FIELD?.LISTICLE_BEACHES,
            payloadModifier: (payload) => {
              payload.urls = payload.urls?.map((el) => el?.url);
              return payload;
            },
          }}
          DELETE={{
            API: CONSTANTS.API.Listicle_Beaches.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Beach from Listicle?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          // isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default ListclesIdWise;
