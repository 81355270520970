import React, { useEffect, useState } from "react";
import {
  FinalImageGenerator,
  ProceedImageURL,
  apiGenerator,
} from "../../../../util/functions";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { Breadcrumb, Button, Card, Form, Image, Row, Typography } from "antd";
import FormFields from "../../../../component/common/FormFields";
import { useNavigate, useParams } from "react-router-dom";
import { payloadGenerator } from "../../../../component/common/CRUD-Component";
import { ROUTES, appRoot } from "../../../../util/Route";
import AddLocationImage from "../../../../component/common/Location/Add-Location-Image";

const CountryLocation = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [IntialCountryData, setInitialCountryData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [refresh, setRefresh] = useState(true);
  const param = useParams();
  const navigate = useNavigate();
  const API = useHttp();
  const handleEdit = () => {
    setIsEditing(true);
  };
  useEffect(() => {
    const BasicDetailsAPI = apiGenerator(
      CONSTANTS.API.Country.getOne,
      {},
      `${param?.countrySlug}`
    );
    API.sendRequest(BasicDetailsAPI, (res) => {
      if (!res?.data) {
        navigate(`${appRoot}/${ROUTES.LOCATION}`);
      }
      setEditedData({
        ...res?.data,
        tagIds: res?.data?.locationTags?.map((el) => el?.tag?.id),
        lat: res?.data?.GeoLoc?.coordinates[1],
        lng: res?.data?.GeoLoc?.coordinates[0],
      });
      setInitialCountryData(res?.data);
    });
  }, [refresh]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // let payload = getClearObject(values);

        const forPayload = payloadGenerator(
          values,
          [
            ...CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
              (el) =>
                !el?.fieldFilter ||
                !Array.isArray(el?.fieldFilter) ||
                el?.fieldFilter?.findIndex((fil) => fil === "Country") !== -1
            ),
            ...(isEditing
              ? [
                  {
                    no: 7,
                    Label: "description",
                    name: "summary",
                    id: "summary",
                    type: "SimpleRichTextArea",
                    placeholder: "Enter description",
                  },
                ]
              : []),
          ],
          // CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
          //   (el) =>
          //     !el?.fieldFilter ||
          //     !Array.isArray(el?.fieldFilter) ||
          //     el?.fieldFilter?.findIndex((fil) => fil === "Country") !== -1
          // ),
          true
        );
        // if (
        //   IntialCountryData &&
        //   IntialCountryData?.rank &&
        //   IntialCountryData?.rank === values?.rank
        // ) {
        //   forPayload.delete("rank");
        // }

        const UpadteCountryDetail = apiGenerator(CONSTANTS.API.Country.update, {
          id: IntialCountryData?.id,
        });
        //  payload.AllBeachId = beachData?.id;
        API.sendRequest(
          UpadteCountryDetail,
          (res) => {
            setEditedData(values);
            setRefresh((prev) => !prev);
          },
          forPayload,
          "Country Details Update Successfully"
        );

        setIsEditing(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const [form] = Form.useForm();

  useEffect(() => {
    const AddLocationTagField =
      CONSTANTS.FORM_FIELD.ADD_COUNTRY_STATE_CITY.find(
        (fil) => fil.id === "tagIds"
      );
    const EditLocationTagField =
      CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.find(
        (fil) => fil.id === "tagIds"
      );

    if (AddLocationTagField?.filters?.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Tag.getAll,
      (res) => {
        const OptionTags = res?.data?.rows?.map((tag) => ({
          id: tag?.id,
          label: tag?.tag,
          value: tag?.id,
        }));
        // console.log(options);
        AddLocationTagField.option = OptionTags;
        EditLocationTagField.option = OptionTags;
      },
      {
        limit: 1000,
      }
    );
  }, []);

  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6 "
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            href: `${appRoot}/${ROUTES.LOCATION}`,
            title: "Location",
          },
          {
            title: IntialCountryData?.name,
          },
        ]}
      />

      <Card bordered className="m-4">
        <Row justify="space-between" className=" flex-wrap">
          <Typography.Title level={3} className="flex justify-between ">
            Country Details
          </Typography.Title>
          <div className="flex gap-3 flex-wrap">
            {isEditing ? (
              <Button
                type="primary"
                danger
                onClick={() => {
                  // setEditedData({});
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button danger onClick={handleEdit}>
                Edit
              </Button>
            )}
            <AddLocationImage
              locationData={IntialCountryData}
              refresher={() => {
                setRefresh((prev) => !prev);
              }}
              API={API}
              type="Country"
            />
          </div>
        </Row>

        {IntialCountryData?.imageId && (
          <Row className=" items-center justify-center">
            <Image
              width={230}
              src={FinalImageGenerator(IntialCountryData?.image, 400)}
              preview={{
                src: FinalImageGenerator(IntialCountryData?.image, 1600, 3),
              }}
            />
          </Row>
        )}
        <FormFields
          // menuFields={CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
          //   (el) =>
          //     !el?.fieldFilter ||
          //     !Array.isArray(el?.fieldFilter) ||
          //     el?.fieldFilter?.findIndex((fil) => fil === "Country") !== -1
          // )}
          menuFields={[
            ...CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
              (el) =>
                !el?.fieldFilter ||
                !Array.isArray(el?.fieldFilter) ||
                el?.fieldFilter?.findIndex((fil) => fil === "Country") !== -1
            ),
            ...(isEditing
              ? [
                  {
                    no: 7,
                    Label: "description",
                    name: "summary",
                    id: "summary",
                    type: "SimpleRichTextArea",
                    placeholder: "Enter description",
                  },
                ]
              : []),
          ]}
          formData={editedData}
          form={form}
          disabled={!isEditing}
        />
        {!isEditing ? (
          <Card bordered className="my-2 border-gray-400" key={"description"}>
            <Typography.Title level={4} className="!pb-1 !mb-1 capitalize">
              Description
            </Typography.Title>
            <Typography.Title
              level={5}
              className="!m-0 !p-0 capitalize"
              // dangerouslySetInnerHTML={{ __html: el?.value }}
            >
              {/* {`${el?.label} : `} */}
              <span
                className="font-light"
                dangerouslySetInnerHTML={{
                  __html: editedData?.summary,
                  // __html: editedData?.[el?.label],
                }}
              ></span>
            </Typography.Title>
          </Card>
        ) : null}
        {isEditing && (
          <>
            <Button type="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </>
        )}
      </Card>
    </>
  );
};

export default CountryLocation;
