import { Breadcrumb, Button, Card, Row } from "antd";
import React from "react";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { ROLES } from "../../../../util/Route";

const SubscribedEmail = (props) => {
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.SubscribeEmail}`,
            title: "Subscribed Email",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.SubscribeEmail.getAll,

            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
              }));
            },
            column: CONSTANTS.TABLE.SubscribeEmail,
          }}
          DELETE={{
            API: CONSTANTS.API.SubscribeEmail.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Subscribed Email?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          // isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default SubscribedEmail;
