import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, message, Row, Upload } from "antd";

const { Dragger } = Upload;
const LocationImageUpload = ({ onSuccess = () => {}, API }) => {
  const onFinish = (values) => {
    // console.log("Success:", values);
    const formData = new FormData();
    formData.append("image", values?.imageFile?.fileList?.[0]?.originFileObj);
    onSuccess(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="m-4">
      <Form
        name="basicUpload"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item id="imageFile" name="imageFile">
          <Dragger
            name="file"
            multiple={false}
            maxCount={1}
            beforeUpload={() => false} //   action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
            onChange={(info) => {
              const { status } = info.file;
              if (status !== "uploading") {
                // console.log(info.file, info.fileList);
              }
              if (status === "done") {
                message.success(
                  `${info.file.name} file uploaded successfully.`
                );
              } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            onDrop={(e) => {
              //   console.log("Dropped files", e.dataTransfer.files);
            }}
            // itemRender={(originNode, file, currentFileList, actios) => {
            //   return (
            //     <Row
            //       key={file.uid}
            //       className="mt-2 justify-between rounded-sm shadow-md hover:scale-105"
            //     >
            //       <Col className="">
            //         <Row className=" justify-center items-center">
            //           <Image
            //             src={URL.createObjectURL(file?.originFileObj)}
            //             width={100}
            //             className=" aspect-video"
            //           />
            //         </Row>
            //       </Col>
            //       <Col className="flex justify-center items-center">
            //         <Button
            //           className=" text-lg"
            //           type="danger"
            //           onClick={actios.remove}
            //         >
            //           <MdDelete />
            //         </Button>
            //       </Col>
            //     </Row>
            //   );
            // }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from
              uploading company data or other banned files.
            </p>
          </Dragger>
        </Form.Item>

        <Row className=" justify-end mt-5">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={API.isLoading}
              onClick={async () => {
                // const { errorMessages, successMessages } = await SubmitPhotos();
                // if (errorMessages?.length) {
                //   notification.error({
                //     message: `Total ${errorMessages?.length} upload failed `,
                //   });
                // }
                // if (successMessages?.length) {
                //   notification.success({
                //     message: `Total ${successMessages?.length} upload Successfully`,
                //   });
                // }
                // refresher();
                // setFileList([]);
                // setProgress([]);
              }}
            >
              Upload
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default LocationImageUpload;
