import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";

const ScrollLoader = (props) => {
  const {
    loadMoreFunction = () => {},
    threshold = 90,
    isLoading,
    loadoff,
    timeout = 500,
  } = props;
  const debounce = useRef(null);
  const [scroll, setScroll] = useState(1);
  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollPercentage = ((scrollTop + windowHeight) / scrollHeight) * 100;

    if (scrollPercentage >= threshold) {
      setScroll(scrollPercentage);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (scroll < threshold) {
      return;
    }
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      if (loadoff || isLoading) {
        return;
      } else {
        loadMoreFunction();
      }
    }, timeout);
  }, [scroll]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      {isLoading && !loadoff ? <Spin size="large"></Spin> : ""}
    </div>
  );
};

export default ScrollLoader;
