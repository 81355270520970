import React, { useEffect, useState } from "react";

import { Button, Card, Checkbox, Col, Image, Input, Row, Spin } from "antd";
// import useHttp from "../../../hooks/use-http";
// import CONSTANTS from "../../../util/constant/CONSTANTS";
import axios from "axios";
import useFlickrPhotos from "../../../hooks/useFlickrPhotos";


const Flicker = ({ locationData, API, onSuccess }) => {
    // const [flickrPhotos, setFlickerPhotos] = useState([]);
    const [title, setTitle] = useState(""); 
    const [resfresh, setRefresh] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 12,
    });
    const { flickrPhotos, loading, error } = useFlickrPhotos(title, pagination);
    // useEffect(() => {
    //     // title &&
    //     //     API.sendRequest(
    //     //         CONSTANTS.API.Flicker,
    //     //         (res) => {
    //     //             if (pagination.page === 1) {
    //     //                 setFlickerPhotos(res?.data);
    //     //             } else {
    //     //                 setFlickerPhotos((prev) => [...prev, ...res?.data]);
    //     //             }
    //     //         },
    //     //         { ...pagination, title }
    //     //     );
    //     // if (title) {
    //     //     const apiKey = "d203845cecd33185d926709991db4ee9";
      
    //     //     const searchURL = `https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=${apiKey}&text=${title}&page=${pagination?.page}&per_page=${pagination?.limit}&sort=relevance&format=json&nojsoncallback=1&extras=license,owner_name,url_o,url_c,url_l,url_m,description`;
      
    //     //     axios
    //     //       .get(searchURL)
    //     //       .then(async (response) => {
    //     //         if (
    //     //           response.data &&
    //     //           response.data.photos &&
    //     //           response.data.photos.photo
    //     //         ) {
    //     //           const photoList = response.data.photos.photo;
    //     //           // console.log(photoList, "photoListphotoList");
    //     //           if (pagination.page === 1) {
    //     //             setFlickerPhotos(photoList);
    //     //           } else {
    //     //             setFlickerPhotos((prev) => [...prev, ...photoList]);
    //     //           }
    //     //         } else {
    //     //           console.log(
    //     //             "Search response missing expected properties:",
    //     //             response.data
    //     //           );
    //     //         }
    //     //       })
    //     //       .catch((error) => {
    //     //         if (error.response) {
    //     //           console.log("Error status:", error.response.status);
    //     //           console.log("Error data:", error.response.data);
    //     //         } else if (error.request) {
    //     //           console.log("Error request:", error.request);
    //     //         } else {
    //     //           console.log("Error message:", error.message);
    //     //         }
    //     //       });
    //     //   }
    // }, [pagination.page, resfresh]);
    useEffect(() => {
        setTitle(locationData?.name);
        setRefresh((prev) => !prev);
    }, [locationData]);
    return (
        <div>
            <Row>
                <Col sm={24} lg={8} className="p-3">
                    <Input.Search
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                        onSearch={(v) => {
                            setTitle(v);
                            setPagination((prev) => {
                                if (prev.page === 1) {
                                    setRefresh((prevRef) => !prevRef);
                                }
                                return { ...prev, page: 1 };
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 8]} className=" mt-3">
                {flickrPhotos?.map((img, index) => (
                    <Col
                        key={`${img?.id}-${index}`}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                        xxl={6}
                        className="!h-[175px]"
                        onClick={() => {
                            let description = img?.description?._content;
                            // if (descriptionElement) {
                            //   description = descriptionElement.value;
                            // }
                            let imageUrl;

                            if (img && img.url_o) {
                                imageUrl = img.url_o;
                            } else if (img && img.url_l) {
                                imageUrl = img.url_l;
                            } else if (img && img.url_c) {
                                imageUrl = img.url_c;
                            } else if (img && img.url_m) {
                                imageUrl = img.url_m;
                            }

                            const payload = {
                                imageUrl,
                                licenseName: "CC BY 2.0",
                                licenseLink: "https://creativecommons.org/licenses/by/2.0/",
                                photoName: img?.title,
                                photoLink: `https://www.flickr.com/photos/${img?.owner}/${img?.id}`,
                                photographerName: img?.ownername,
                                photographerLink: `https://www.flickr.com/photos/${img?.owner}`,
                                photoSource: "flickr.com",
                                key: img?.secret,
                                description,
                            };
                            if (!API?.isLoading) {
                                const formData = new FormData();
                                Object?.entries(payload).forEach(([key, value]) => {
                                    formData.append(key, value)
                                })
                                onSuccess(formData)
                            }
                        }}
                    >
                        {/*                        
                        <AddImageCard
                            original={img}
                            img={{
                                small: img?.url_m,
                                large: img?.url_o,
                                id: img?.id,
                            }}
                            loading={API.isLoading}
                            onAdd={(checkboxValues, image) => {
                                let description = image?.description?._content;
                                // if (descriptionElement) {
                                //   description = descriptionElement.value;
                                // }
                                let imageUrl;

                                if (image && image.url_o) {
                                    imageUrl = image.url_o;
                                } else if (image && image.url_l) {
                                    imageUrl = image.url_l;
                                } else if (image && image.url_c) {
                                    imageUrl = image.url_c;
                                } else if (image && image.url_m) {
                                    imageUrl = image.url_m;
                                }

                                const payload = {
                                    imageUrl,
                                    AllBeachId: locationData?.id,
                                    licenseName: "CC BY 2.0",
                                    licenseLink: "https://creativecommons.org/licenses/by/2.0/",
                                    photoName: image?.title,
                                    photoLink: `https://www.flickr.com/photos/${image?.owner}/${image?.id}`,
                                    photographerName: image?.ownername,
                                    photographerLink: `https://www.flickr.com/photos/${image?.owner}`,
                                    photoSource: "flickr.com",
                                    key: image?.secret,
                                    description,
                                    ...checkboxValues,
                                };
                                API.sendRequest(
                                    CONSTANTS.API.BeachPhoto.AddPhoto,
                                    (res) => { },
                                    payload
                                );
                            }}
                        /> */}
                        <Image
                            alt="example"
                            wrapperStyle={{
                                width: "100%",
                                height: "100%",
                                minHeight: "175px",
                                maxHeight: "175px",
                            }}
                            rootClassName=" rounded-lg"
                            wrapperClassName=" rounded-lg"
                            draggable="false"
                            className="!w-full !h-full rounded-lg cursor-pointer"
                            // src={item?.imageUrl}
                            //   preview={{
                            //     src: FinalImageGenerator(item, 1600, 3),
                            //   }}
                            preview={false}
                            src={img.url_m ?? img?.url_c ?? img?.url_l ?? img?.url_o}
                        // src={FinalImageGenerator(item, 1400)}
                        />
                    </Col>
                ))}
            </Row>
            <Row className=" items-center justify-center my-3">
                {loading? (
                    <Spin size="large" />
                ) : (
                    <Button
                        type="primary"
                        onClick={() => {
                            setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
                        }}
                    >
                        Load More
                    </Button>
                )}
            </Row>
        </div>
    );
};

export default Flicker;
