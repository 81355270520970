import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import AssignBeachesUser from "./AssignBeachesUser";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";

const UserBeach = ({
  DETAILS,
  setRefreshUser,
  extraQuery,
  setExtraQuery,
  userDetails,
}) => {
  return (
    <>
      <Row gutter={[16, 16]} className="mt-6">
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            key={el?.title + i}
          >
            <Card size="small" className="">
              <Statistic title={el?.title} value={el?.count ?? 0} />
            </Card>
          </Col>
        ))}
      </Row>
      <AssignBeachesUser
        refresher={() => {
          setRefreshUser((prev) => !prev);
          setExtraQuery((prev) => ({
            ...prev,
          }));
        }}
        user={userDetails}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.BeachUser.getAll,
            // extraQuery: { userId: userId },
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                name: el?.AllBeach?.name,
                cityName: el?.AllBeach?.city?.name,
                stateName: el?.AllBeach?.city?.state?.name,
                countryName: el?.AllBeach?.city?.state?.country?.name,
                status: {
                  onChange: (v) => {
                    // console.log(v);
                    const UpadteUSerBEach = apiGenerator(
                      CONSTANTS.API.BeachUser.update,
                      { id: el?.id }
                    );
                    API.sendRequest(
                      UpadteUSerBEach,
                      (res) => {
                        Setrefresh((prev) => !prev);
                        setRefreshUser((prev) => !prev);
                      },
                      {
                        status: v,
                      }
                    );
                  },
                  value: el?.status,
                },
              }));
            },

            column: CONSTANTS.TABLE.USER_BEACH,
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          // isSearch
          selectionOff
        />
      </Row>
    </>
  );
};

export default UserBeach;
