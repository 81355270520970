import { Alert, Button, Col, List, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CustomSearchBar from "../../../../component/common/Custom-search";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { FaPlus, FaPlusCircle } from "react-icons/fa";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import { getClearObject } from "../../../../util/functions";

const AssignBeachesUser = ({ user, refresher }) => {
  const [modal, setModal] = useState(false);
  const [innermodal, setInnerModal] = useState(false);
  const [selectedBeach, setSelectedBeach] = useState(null);
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState([]);
  const API = useHttp();
  const InnerAPI = useHttp();
  useEffect(() => {
    if (search.trim().length > 0) {
      API.sendRequest(
        CONSTANTS.API.Beach.getAll,
        (res) => {
          setOptions(
            res?.data?.rows?.map(
              (el) =>
                ({
                  OldData: { ...el },
                  id: el?.id,
                  AllBeachId: el?.id,
                  name: el?.name,
                  //   location: `${el?.city?.name}, ${el?.city?.state?.name}, ${el?.city?.state?.country?.name}`,
                  location: `${el?.city?.name}, ${el?.state?.name}, ${el?.country?.name}`,
                } || [])
            )
          );
        },
        { searchQuery: search, page: 1, limit: 15 },
        null,
        () => {
          setOptions(null);
        }
      );
      //   API.sendRequest(
      //     {
      //       type: "GET",
      //       endpoint: `admin/beachMain/searchByName/${search}`,
      //     },
      //     (res) => {
      //       console.log(res);
      //     }
      //   );
    } else {
      setOptions([]);
    }
  }, [search]);
  // console.log(search, "++++++++", option);
  return (
    <>
      <Row className="mt-5">
        <Button
          onClick={() => {
            setModal(true);
          }}
        >
          Assign New Beaches
        </Button>
      </Row>
      <Modal
        open={modal}
        onCancel={() => {
          refresher();
          setModal(false);
        }}
        footer={null}
      >
        <Row className=" mt-6 mb-3">
          <CustomSearchBar setKeyword={(v) => setSearch(v)} isSearch />
        </Row>
        {API.isLoading ? (
          <Row className=" items-center justify-center">
            <Spin size="large" />
          </Row>
        ) : search?.trim().length > 0 && option?.length ? (
          <List
            style={{
              height: 400,
              overflow: "auto",
              padding: "0 16px",
              border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
            dataSource={option}
            renderItem={(item) => (
              <List.Item key={item.id} className="flex">
                <List.Item.Meta
                  // avatar={<Avatar src={item.picture.large} />}
                  title={item.name}
                  description={item.location}
                />
                <Button
                  type="default"
                  shape="circle"
                  className="flex items-center justify-center"
                  loading={InnerAPI.isLoading}
                  onClick={() => {
                    setSelectedBeach(item);
                    setInnerModal(true);
                    // InnerAPI.sendRequest(
                    //   CONSTANTS.API.BeachUser.create,
                    //   (res) => {},
                    //   {
                    //     AllBeachId: item?.id,
                    //     listiclesMainId,
                    //   }
                    // );
                  }}
                >
                  {!InnerAPI.isLoading && <FaPlus />}
                </Button>
              </List.Item>
            )}
          />
        ) : option !== null && search.trim().length === 0 ? (
          <Alert type="info" message="Please Search the beaches" />
        ) : (
          <Alert type="error" message="Error While fetching" />
        )}
        {/* {option?.map((op) => (
          <Row key={op?.id}>
            <Col> {op?.name}</Col>
            <Col> {op?.location}</Col>
          </Row>
        ))} */}
        <ModalFormCreator
          open={innermodal}
          onCancel={() => {
            setInnerModal(false);
            setSelectedBeach(null);
          }}
          onCreate={(v, clear) => {
            const payload = getClearObject(v);
            payload.beachIds = [selectedBeach?.id];
            payload.userId = user?.id;
            InnerAPI.sendRequest(
              CONSTANTS.API.BeachUser.create,
              (res) => {
                // console.log(res);
                clear();
                setInnerModal(false);
                setSelectedBeach(null);
              },
              payload
            );
            // console.log(payload);

            // setSelectedData({});
            // Setrefresh((prev) => !prev);
            // setExtraQuery({});
          }}
          menuFields={CONSTANTS.FORM_FIELD.BEACH_USER_RELATION_SINGLE}
          name={`Assign ${selectedBeach?.name} to User`}
        />
      </Modal>
    </>
  );
};

export default AssignBeachesUser;
