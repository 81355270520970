import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Modal, Row } from "antd";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import { ROUTES, appRoot } from "../../../../util/Route";
import FilterComponent from "../../../../component/common/basic/Filter-Component";
import { apiGenerator } from "../../../../util/functions";
const { confirm } = Modal;

const TaggedBeachPage = (props) => {
  //   const [analytics, setAnalytics] = useState({});
  const [extraQuery, setExtraQuery] = useState({ userId: props?.profile?.id });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [externalRefreshState, setExternalRefreshState] = useState(false);

  const [selectedCity, setSelectedCity] = useState(null);
  useEffect(() => {
    setExtraQuery((prev) => {
      const query = {};
      query.userId = props?.profile?.id;
      if (selectedCity) {
        query.cityId = selectedCity;
      }
      if (selectedState) {
        query.stateId = selectedState;
      }
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
      return query;
    });
    setExternalRefreshState((prev) => !prev);
  }, [selectedCity, selectedState, selectedCountry]);

  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.BEACH}`,
            title: "All Tagged Beaches",
          },
        ]}
      />

      <Row>
        <CRUDComponent
          AddOnComponent={
            <>
              <Col span={24} sm={24} md={22} lg={22} xl={17} xxl={17}>
                <FilterComponent
                  selectedCity={selectedCity}
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  setSelectedCity={setSelectedCity}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedState={setSelectedState}
                />
              </Col>
            </>
          }
          GET={{
            API: CONSTANTS.API.BeachUser.getAll,
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                name: el?.AllBeach?.name,
                cityName: el?.AllBeach?.city?.name,
                stateName: el?.AllBeach?.state?.name,
                countryName: el?.AllBeach?.country?.name,
                // cityName: el?.AllBeach?.city?.name,
                // stateName: el?.AllBeach?.city?.state?.name,
                // countryName: el?.AllBeach?.city?.state?.country?.name,
                view: {
                  //   link: `${appRoot}/${ROUTES.TAG_BEACH}/${el?.AllBeach?.city?.state?.country?.slug}/${el?.AllBeach?.city?.state?.slug}/${el?.AllBeach?.city?.slug}/${el?.AllBeach?.nameSlug}`,
                  link: `${appRoot}/${ROUTES.TAG_BEACH}/${el?.AllBeach?.country?.slug}/${el?.AllBeach?.state?.slug}/${el?.AllBeach?.city?.slug}/${el?.AllBeach?.nameSlug}`,
                  id: el?.id,
                },
                action: el?.action,
                notes: el?.notes,
                status: {
                  onChange: (v) => {
                    // console.log(v);
                    // console.log(el);
                    const UpadteUSerBEach = apiGenerator(
                      CONSTANTS.API.BeachUser.update,
                      { id: el?.id }
                    );
                    if (v !== "DONE") {
                      API.sendRequest(
                        UpadteUSerBEach,
                        (res) => {
                          Setrefresh((prev) => !prev);
                        },
                        {
                          status: v,
                        },
                        "Status Updated"
                      );
                    } else {
                      confirm({
                        content: <p>Are you sure to Done this task ? </p>,
                        onOk() {
                          API.sendRequest(
                            UpadteUSerBEach,
                            (res) => {
                              Setrefresh((prev) => !prev);
                            },
                            {
                              status: v,
                            },
                            "Status Updated"
                          );
                        },
                        onCancel() {
                          // API.sendRequest(
                          //   UpadteUSerBEach,
                          //   (res) => {
                          //     Setrefresh((prev) => !prev);
                          //   },
                          //   {
                          //     status: el?.status,
                          //   }
                          // );
                        },
                      });
                    }
                  },
                  value: el?.status,
                },
              }));
            },
            column: CONSTANTS.TABLE.USER_TAG_BEACH,
          }}
          DELETE={{
            API: CONSTANTS.API.Beach.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this beach?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          externalRefreshState={externalRefreshState}
          // datefilter
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default TaggedBeachPage;
