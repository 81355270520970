import React, { useEffect, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  FloatButton,
  Image,
  Popconfirm,
  Row,
  Spin,
  notification,
} from "antd";
import { FaCheck, FaDownload, FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FinalImageGenerator, apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";
const BeachCard = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const handleError = () => {
    setIsBroken(true);
    setIsLoading(false);
  };

  const handleLoad = () => {
    setIsLoading(false);
    setIsBroken(false);
  };
  console.log(props)
  return (
    <Card
      className="!m-0 !p-0"
      bodyStyle={{ margin: 0, padding: 0 }}
      actions={[
        props?.API?.sendRequest ? (
          <Popconfirm
            title="Are you to make this photo cover photo?"
            onConfirm={() => {
              if (props?.isCoverPhoto) {
                return notification.error({
                  message: "Atleast one cover photo is needed!",
                });
              }
              const UpdateImageAPI = apiGenerator(
                CONSTANTS.API.BeachPhoto.update,
                {
                  id: props?.id,
                }
              );
              props?.API?.sendRequest &&
                props?.API?.sendRequest(
                  UpdateImageAPI,
                  (res) => {
                    // SetIamgesData(res?.data);
                    props?.refresher();
                  },
                  {
                    isCoverPhoto: true,
                  },
                  "Cover Photo Updated successfully"
                );
            }}
          >
            <Button
              size="middle"
              shape="circle"
              type={props?.isCoverPhoto ? "primary" : "dashed"}
            >
              CP
            </Button>
          </Popconfirm>
        ) : (
          <Button
            size="middle"
            shape="circle"
            type={props?.isCoverPhoto ? "primary" : "dashed"}
          >
            CP
          </Button>
        ),
        <Button
          size="middle"
          shape="circle"
          type={props?.isFiveStarPhoto ? "primary" : "dashed"}
          onDoubleClick={() => {
            // console.log("object CP double");
            const UpdateImageAPI = apiGenerator(
              CONSTANTS.API.BeachPhoto.update,
              {
                id: props?.id,
              }
            );
            props?.API?.sendRequest &&
              props?.API?.sendRequest(
                UpdateImageAPI,
                (res) => {
                  // SetIamgesData(res?.data);
                  props?.refresher();
                },
                {
                  isFiveStarPhoto: !props?.isFiveStarPhoto,
                },
                `${
                  props?.isFiveStarPhoto
                    ? "Photo removed from 5-Star Successfully"
                    : "Photo added to 5-Star Successfully"
                }`
              );
          }}
        >
          5s
        </Button>,
        <Button
          size="middle"
          shape="circle"
          type={props?.isDronePhoto ? "primary" : "dashed"}
          onDoubleClick={() => {
            // console.log("object CP double");
            const UpdateImageAPI = apiGenerator(
              CONSTANTS.API.BeachPhoto.update,
              {
                id: props?.id,
              }
            );
            props?.API?.sendRequest &&
              props?.API?.sendRequest(
                UpdateImageAPI,
                (res) => {
                  // SetIamgesData(res?.data);
                  props?.refresher();
                },
                {
                  isDronePhoto: !props?.isDronePhoto,
                },
                `${
                  props?.isDronePhoto
                    ? "Photo removed from Drone Successfully"
                    : "Photo added to Drone Successfully"
                }`
              );
          }}
        >
          D
        </Button>,
        <Button
          size="middle"
          shape="circle"
          type={props?.isProPhoto ? "primary" : "dashed"}
          onDoubleClick={() => {
            // console.log("object CP double");
            const UpdateImageAPI = apiGenerator(
              CONSTANTS.API.BeachPhoto.update,
              {
                id: props?.id,
              }
            );
            props?.API?.sendRequest &&
              props?.API?.sendRequest(
                UpdateImageAPI,
                (res) => {
                  // SetIamgesData(res?.data);
                  props?.refresher();
                },
                {
                  isProPhoto: !props?.isProPhoto,
                },
                `${
                  props?.isProPhoto
                    ? "Photo removed from Pro Successfully"
                    : "Photo added to Pro Successfully"
                }`
              );
          }}
        >
          P
        </Button>,
        <Button
          size="middle"
          shape="circle"
          type={props?.isCheckPhoto ? "primary" : "dashed"}
          onDoubleClick={() => {
            // console.log("object CP double");
            const UpdateImageAPI = apiGenerator(
              CONSTANTS.API.BeachPhoto.update,
              {
                id: props?.id,
              }
            );
            props?.API?.sendRequest &&
              props?.API?.sendRequest(
                UpdateImageAPI,
                (res) => {
                  // SetIamgesData(res?.data);
                  props?.refresher();
                },
                {
                  isCheckPhoto: !props?.isCheckPhoto,
                },
                `${
                  props?.isCheckPhoto
                    ? "Photo removed from Check-Photo Successfully"
                    : "Photo added to Check-Photo Successfully"
                }`
              );
          }}
        >
          <FaCheck />
        </Button>,
      ]}
    >
      <div
        className=" w-full h-full "
        style={{
          maxHeight: "175px",
          height: "175px",
        }}
      >
        {props?.selection && (
          <Checkbox
            className=" absolute top-0 z-20 pl-2 pt-1"
            checked={!!props?.selected?.find((el) => el?.id === props?.id)}
            onChange={() => {
              props?.setSelected((prev) => {
                const oldValue = [...prev];
                if (!oldValue?.find((el) => el?.id === props?.id)) {
                  return [...oldValue, { id: props?.id, index: props?.index }];
                }
                // return oldValue;
                return [...oldValue?.filter((el) => el?.id !== props?.id)];
              });
            }}
          />
        )}
        <div className="flex justify-end">
          <FloatButton.Group
            className="absolute right-3 bottom-16 "
            trigger="click"
            tooltip="Menu"
            //   type="primary"
            // style={{
            //   right: 94,
            // }}
            icon={<EllipsisOutlined key="ellipsis" height={10} />}
          >
            {/* <FloatButton
              className="absolute top-0 left-0"
              type="primary"
              icon={<FaDownload />}
            /> */}
            <Row gutter={[4, 4]} className=" justify-center">
              {props?.deleteAllowed ? (
                <Popconfirm
                  title="Are you sure to Delete this Photo?"
                  onConfirm={() => {
                    props?.API?.sendRequest &&
                      props?.API?.sendRequest(
                        CONSTANTS.API.BeachPhoto.DeletePhoto,
                        (res) => {
                          props?.refresher();
                        },
                        { ids: [props?.id] }
                      );
                  }}
                >
                  <Button type="primary" shape="circle" size="middle" danger>
                    <MdDelete />
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
              {/* <Button
                type="primary"
                shape="circle"
                size="middle"
                onClick={() => {
                  props?.SetIamgesData({
                    id: props?.id,
                    image_o: props?.imageUrl,
                    image_l: FinalImageGenerator(props, 1600, 3),
                    image_m: FinalImageGenerator(props, 1600, 2),
                    image_s: FinalImageGenerator(props, 1600),
                  });
                }}
              >
                <FaEdit />
              </Button> */}

              <Button type="primary" shape="circle" size="middle">
                {/* <a href={props?.imageUrl} download target="_blank"> */}
                <FaDownload
                  onClick={() => {
                    window.open(props?.imageUrl, "download");
                  }}
                />
                {/* </a> */}
              </Button>
            </Row>
            {/* <FloatButton
                // type="primary"
             
                icon={<MdDelete />}
              /> */}
          </FloatButton.Group>
        </div>
        {/* {isBroken ? (
          <>
            <div className="flex justify-center py-2"><Spin size="small" /></div>
            <p className="text-center px-5">{`Uploading your photo, please wait... We're optimizing it for storage and processing. Please refresh the page after 1 minute.`}</p>
          </>
        ) : ( */}
        {/* <Image
          alt={
            isBroken
              ? "Loading..., Please refresh the page after 1 minute."
              : "example"
          }
          wrapperStyle={{
            width: "100%",
            height: "100%",
            minHeight: "175px",
            maxHeight: "175px",
          }}
          draggable="false"
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "175px",
          }}
          // className="!w-full !h-full"
          // src={props?.imageUrl}
          preview={
            props?.preview
              ? {
                  src: FinalImageGenerator(props, 1600, 3),
                }
              : false
          }
          src={isBroken ? null : FinalImageGenerator(props, 1400)}
          onLoad={handleLoad} // Set loading to false on image load success
          onError={handleError} // Retry on error
        /> */}
        {/* )} */}

        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin size="large" />
          </div>
        )}

        <Image
          // alt="example"
          alt={
            isBroken
              ? "Loading error, please refresh the page after some time."
              : "Beach Image"
          }
          wrapperStyle={{
            width: "100%",
            height: "100%",
            minHeight: "175px",
            maxHeight: "175px",
          }}
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "175px",
            display: isLoading ? "none" : "block",
          }}
          src={FinalImageGenerator(props, 1400)}
          onError={handleError}
          onLoad={handleLoad}
        />
      </div>
    </Card>
  );
};
export default BeachCard;
