// import { setToken } from "../../hooks/use-localStorage";
// import { setCookie, getCookie, eraseCookie } from "../Cookies";

export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const setAuthDetails = (accessToken) => {
  // setCookie("SAID", accessToken, 1);
  localStorage.setItem("token", accessToken);
  // setToken("tokenB", accessToken);
};

export const deleteAuthDetails = () => {
  // eraseCookie("SAID");
  localStorage.clear();
};
