import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Image, Input, Row, Spin } from "antd";
import axios from "axios";
import AddImageCard from "../../basic/Add-Image-Card";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";

const Unsplash = ({ beachData, refresher, masterRefresh }) => {
  const [unsplashPhoto, setUnsplashPhotos] = useState([]);
  const [title, setTitle] = useState("");
  const [resfresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 12,
  });
  const API = useHttp();
  // const API = useHttp();
  useEffect(() => {
    // title &&
    //   API.sendRequest(
    //     CONSTANTS.API.Flicker,
    //     (res) => {
    //       if (pagination.page === 1) {
    //         setUnsplashPhotos(res?.data);
    //       } else {
    //         setUnsplashPhotos((prev) => [...prev, ...res?.data]);
    //       }
    //     },
    //     { ...pagination, title }
    //   );
    const UnspashFetcher = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `https://api.unsplash.com/search/photos?query=${title}&client_id=7LsMX01l2I637GvemDecFmBBUXoXXj9DEEe1lu2CLwc&page=${pagination.page}&per_page=${pagination.limit}&orientation=landscape`
      );
      // const res = JSON.parse(resF)
      if (pagination.page === 1) {
        setUnsplashPhotos(res?.data?.results);
      } else {
        setUnsplashPhotos((prev) => [...prev, ...res?.data?.results]);
      }
      setIsLoading(false);
    };
    if (title && pagination.page && pagination.limit) {
      try {
        UnspashFetcher();
      } catch (error) {
        console.log(error);
      }
    }
  }, [pagination.page, resfresh]);
  useEffect(() => {
    setTitle(beachData?.name);
    setRefresh((prev) => !prev);
  }, [beachData]);

  // const handleCheckboxChange = (file, property) => (e) => {
  //   // file[property] = e.target.checked;
  //   // setFileList([...fileList]);
  //   console.log(file, property, e.target.checked, "Checked Prop");
  // };
  return (
    <div>
      <Row>
        <Col sm={24} lg={8} className="p-3">
          <Input.Search
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onSearch={(v) => {
              setTitle(v);
              setPagination((prev) => {
                if (prev.page === 1) {
                  setRefresh((prevRef) => !prevRef);
                }
                return { ...prev, page: 1 };
              });
            }}
          />
        </Col>
      </Row>
      <Row className=" items-center justify-center" gutter={[18, 8]}>
        {unsplashPhoto?.map((img) => (
          <Col
            key={img?.id}
            // style={{ height: "150px" }}
            sm={24}
            md={8}
            lg={6}
            className="p-3 "
          >
            {/* <Card
              // key={img?.id}
              className="!m-0 !p-0 shadow-md"
              bodyStyle={{ margin: 0, padding: 0 }}
            >
              <Image
                alt="example"
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  // minHeight: "175px",
                  // maxHeight: "175px",
                }}
                style={{ width: "100%", height: "175px" }}
                // style={{ width: "100%", height: "100%", objectFit: "cover" }}
                key={img?.id}
                src={img?.urls?.small_s3}
                preview={{ src: img?.urls?.regular }}
              />
              <Row className=" justify-around p-4">
                <Col
                  xs={18}
                  className="flex flex-col items-start justify-start"
                >
                  <Checkbox
                    onChange={handleCheckboxChange(img?.id, "isProPhoto")}
                  >
                    Pro Photo
                  </Checkbox>
                  <Checkbox
                    onChange={handleCheckboxChange(img?.id, "isDronePhoto")}
                  >
                    Drone Photo
                  </Checkbox>
                  <Checkbox
                    onChange={handleCheckboxChange(img?.id, "isFiveStarPhoto")}
                  >
                    5-Star Photo
                  </Checkbox>
                </Col>
                <Col xs={6} className="flex justify-center items-center">
                  <Button type="primary">Add</Button>
                </Col>
              </Row>
            </Card> */}
            <AddImageCard
              original={img}
              img={{
                small: img?.urls?.small_s3,
                large: img?.urls?.regular,
                id: img?.id,
              }}
              loading={API.isLoading}
              onAdd={(checkboxValues, image) => {
                let description = image?.description;
                // if (descriptionElement) {
                //   description = descriptionElement.value;
                // }
                const payload = {
                  imageUrl: image?.urls?.full,
                  AllBeachId: beachData?.id,
                  licenseName: "CC BY 2.0",
                  licenseLink: "https://creativecommons.org/licenses/by/2.0/",
                  photoName: beachData?.name,
                  photoLink: image?.urls?.full,
                  photographerName: image?.user?.name,
                  photographerLink: image?.user?.profile_image?.large,
                  photoSource: "unsplash.com",
                  key: image?.id,
                  description,
                  ...checkboxValues,
                };
                if (masterRefresh) {
                  payload.isCoverPhoto = true;
                }
                API.sendRequest(
                  CONSTANTS.API.BeachPhoto.AddPhoto,
                  (res) => {
                    if (masterRefresh) {
                      refresher();
                    }
                  },
                  payload,
                  "Uploading your photo... Creating a copy for better storage."
                );
              }}
            />
          </Col>
        ))}
      </Row>
      <Row className=" items-center justify-center my-3">
        {/* {API.isLoading ? ( */}
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Button
            type="primary"
            onClick={() => {
              setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
            }}
          >
            Load More
          </Button>
        )}
      </Row>
    </div>
  );
};

export default Unsplash;
