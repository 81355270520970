import { useState, useCallback } from "react";
import { notification } from "antd";
import Services from "../util/API/service";
import { deleteAuthDetails } from "../util/API/authStorage";

const LogOutMessages = {
  "token expire please login first": true,
};
const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (
      url,
      responseHandler,
      payload,
      successMessage,
      errorHandler,
      progressHandler
    ) => {
      setIsLoading(true);
      if (url?.endpoint && url?.type) {
        try {
          let response;
          switch (url.type) {
            case "POST":
              response = await Services.post(url.endpoint, payload);
              break;

            case "PUT":
              response = await Services.put(url.endpoint, payload, {
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  if (progressHandler) {
                    progressHandler(percentCompleted);
                  }
                },
              });

              break;
            case "DELETE":
              if (!process.env.REACT_APP_SENSITIVE) {
                response = await Services.delete(url.endpoint);
              } else {
                response = {};
                notification.warning({
                  message: `Mode : SENSITIVE : DELETE API called for ${url?.endpoint}`,
                });
              }
              break;

            case "PATCH":
              if (!process.env.REACT_APP_SENSITIVE) {
                response = await Services.patch(url.endpoint, payload);
              } else {
                response = {};
                console.log(payload);
                notification.warning({
                  message: `Mode : SENSITIVE : PATCH API called for ${url?.endpoint}`,
                });
              }
              break;
            case "BULKPOST":
              const successMessages = [];
              const errorMessages = [];
              const AllPromises = payload?.map(async (SinglePayload, i) => {
                try {
                  const responseSingle = await Services.post(
                    url.endpoint,
                    SinglePayload,
                    {
                      onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        console.log(percentCompleted, "Hook", progressHandler);
                        if (progressHandler) {
                          progressHandler({ key: i, percentCompleted });
                        }
                      },
                    }
                  );
                  const data = await responseSingle.data;

                  // Track success messages for individual payloads
                  successMessages.push({
                    id: i + 1,
                    type: "success",
                    data: data,
                  });

                  // Execute responseSingle handler for individual payloads
                } catch (err) {
                  // Track error messages for individual payloads
                  errorMessages.push({ id: i + 1, type: "fail", error: err });
                }
              });
              await Promise.all(AllPromises);
              response = {
                data: {
                  data: {
                    successMessages,
                    errorMessages,
                  },
                },
              };
              break;
            default:
              const queryParams = buildQueryString(payload);
              response = await Services.get(url.endpoint + queryParams);
              break;
          }

          const data = await response.data;
          if (successMessage) {
            notification.success({ message: successMessage, duration: "3" });
          }
          try {
            if (responseHandler) {
              responseHandler(data);
            }
          } catch (e) {
            console.error(e);
          }
        } catch (err) {
          if (LogOutMessages[err?.response?.data?.message]) {
            deleteAuthDetails();
            localStorage.clear();
            window.location.assign("/");
            return;
          }
          if (err?.response?.data?.message) {
            if (errorHandler) {
              errorHandler(err?.response?.data?.message);
            } else {
              notification.error({
                message: err?.response?.data?.message,
                duration: "3",
              });
            }
          } else {
            notification.error({
              message: "Something Wrong Please Try again",
              duration: 2,
            });
          }
        }
      } else {
        notification.error({
          message: "Not valid endpoint and type",
          duration: 2,
        });
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;

export const buildQueryString = (params) => {
  const queryParts = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (key.startsWith("autogenerate-mul-array-") && Array.isArray(value)) {
        const arrayKey = key.slice("autogenerate-mul-array-".length);
        value.forEach((item) => {
          queryParts.push(
            `${arrayKey}=${item}`
            // `${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        // Handle other cases
        queryParts.push(
          `${key}=${value}`
          // `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
};
