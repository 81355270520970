export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};

// export function convertToPowerOf10(number) {
//   // Get the exponent of 10
//   let exponent = Math.floor(Math.log10(Math.abs(number)));

//   // Calculate the coefficient
//   let coefficient = number / Math.pow(10, exponent);

//   return { coefficient, exponent };
// }
export function convertToPowerOf10(number) {
  // Get the exponent of 10
  let exponent = Math.floor(Math.log10(Math.abs(number)));

  // Calculate the result
  let result = number * Math.pow(10, -exponent);

  return result;
}
export const generateMenuFromRoutes = (routes = [], role, basePath = "") => {
  let menu = [];

  routes?.forEach((route) => {
    let nestedBasePath = route?.index
      ? `${basePath}/`
      : route?.path
      ? basePath + "/" + route.path
      : "/";
    nestedBasePath = nestedBasePath.trim().replace("//", "/");
    const menuItem = {
      id: nestedBasePath,
      icon: route?.icon,
      label: route?.label,
      key: nestedBasePath,
    };

    if (route?.children && route?.children?.length > 0) {
      // Recursive call for nested children

      const children = generateMenuFromRoutes(
        route?.children,
        role,
        nestedBasePath
      );
      if (children.length) menuItem.children = children;
    }
    if (route?.icon && (!route?.Role || route?.Role?.includes(role))) {
      menu?.push(menuItem);
    } else if (
      menuItem?.children?.length &&
      (!route?.Role || route?.Role?.includes(role))
    ) {
      menu?.push(...menuItem.children);
    }
  });
  return menu;
};
export const generateRoutes = (routes = [], role) => {
  let Routes = [];

  routes?.forEach((route) => {
    const mainRoute = { ...route };
    // nestedBasePath = nestedBasePath.trim().replace("//", "/");
    // const menuItem = {
    //   id: nestedBasePath,
    //   icon: route?.icon,
    //   label: route?.label,
    //   key: nestedBasePath,
    // };

    if (route?.children && route?.children?.length > 0) {
      // Recursive call for nested children

      const children = generateRoutes(route?.children, role);
      if (children.length) mainRoute.children = children;
    }
    if (!route?.Role || route?.Role?.includes(role)) {
      Routes?.push(mainRoute);
    } else if (
      mainRoute?.children?.length &&
      (!route?.Role || route?.Role?.includes(role))
    ) {
      Routes?.push(...mainRoute.children);
    }
  });
  return Routes;
};
export const ProceedImageURL = (keyPrefix, type = 1) => {
  const HWGenerator = {
    1: { width: 390, height: 260 },
    2: { width: 1050, height: 700 },
    3: { width: 1650, height: 1100 },
  };
  if (keyPrefix) {
    return `https://cdn.sandee.com/${keyPrefix}_${HWGenerator[type].width}_${HWGenerator[type].height}.avif`;
  }
  return null;
};
export const ProceedImageURLForBlog = (keyPrefix, type = 1) => {
  const HWGenerator = {
    1: { width: 100, height: 60 },
    2: { width: 500, height: 300 },
    3: { width: 900, height: 540 },
  };
  if (keyPrefix) {
    return `${
      process.env.REACT_APP_BLOG_CDN_URL ||
      "https://sandee-stag-blog-imgs.s3.amazonaws.com/"
    }blog_${keyPrefix}_${HWGenerator[type].width}_${
      HWGenerator[type].height
    }.avif`;
  }
  return null;
};

export const getClearObject = (obj = {}) => {
  let newObj = {};
  Object.keys(obj).map((key) => {
    let item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });

  return newObj;
};

export function generateBase64Image(
  h,
  w,
  color = "grey",
  textStyle = { text: "", color: "black", font: "26px Arial" }
) {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;

  // Get the 2D rendering context
  const ctx = canvas.getContext("2d");

  // Fill the canvas with the specified color
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, w, h);

  // Add text to the canvas
  ctx.fillStyle = textStyle.color; // Set text color
  ctx.font = textStyle.font; // Set font size and type
  ctx.textAlign = "center"; // Set text alignment to center
  ctx.fillText(textStyle?.text, w / 2, h / 2); // Place text in the center of the canvas

  // Convert the canvas content to base64
  const base64Image = canvas.toDataURL("image/png");

  return base64Image;
}

export function FinalImageGenerator(data, imageSize = 1200, type = 1) {
  // Regular expression to match the specific image URL pattern
  const googleImageRegex = /https:\/\/lh[0-9]+\.googleusercontent\.com/;

  if (data?.imageUrl && googleImageRegex.test(data.imageUrl)) {
    // If it's a Google image, replace the size parameter
    const updatedUrl = data.imageUrl.replace(/=s[0-9]+/, `=s${imageSize}`);
    return updatedUrl;
  } else if (data?.imageProcessed) {
    // If image is processed, use alternateIdLink
    const keyPrefix = data?.id; // Assuming 'id' is the appropriate property for keyPrefix
    // console.log(data, ProceedImageURL(keyPrefix, type));
    return ProceedImageURL(keyPrefix, type);
  } else if (data?.imageUrl) {
    // If imageUrl exists, generate a new URL using ProceedImageURL
    return data?.imageUrl;
  }
  return null;
}

export function getArticle(word) {
  return /^[aeiou]/i.test(word) ? 'an' : 'a';
}