import React, { useState } from "react";
import { FaFlag, FaRegFlag } from "react-icons/fa";
import { apiGenerator } from "../../../../util/functions";
import { ROLES } from "../../../../util/Route";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";

const BeachFlagComponent = ({ beachData, API, setRefresh, identity }) => {
  const [createOpen, setCreateOpen] = useState(false);

  const FlagToggle = () => {
    if (
      beachData?.id &&
      [
        ROLES.ADMIN,
        ROLES.DATA,
        // ROLES.IMAGE,
        // ROLES.INTERN,
        // ROLES.SEO,
        ROLES.SUPER_ADMIN,
        // ROLES.SUPERUSER,
        // ROLES.WRITER,
      ].some((el) => el === identity)
    ) {
      const UpadteBeachMain = apiGenerator(CONSTANTS.API.BeachMain.update, {
        id: beachData?.id,
      });
      API.sendRequest(
        UpadteBeachMain,
        () => {
          setRefresh((prev) => !prev);
        },
        { isFlag: !!!beachData?.isFlag }
      );
    }
  };
  return (
    <div>
      {" "}
      <FaRegFlag
        className={`${
          !beachData?.isFlag ? "text-[#000]" : "text-red-600 hidden"
        } cursor-pointer mx-2 text-lg`}
        onClick={() => {
          setCreateOpen(true);
        }}
      />
      <FaFlag
        className={`${
          !beachData?.isFlag ? "text-[#000] hidden" : "text-red-600 "
        } cursor-pointer mx-2 text-lg`}
        onClick={FlagToggle}
      />
      <ModalFormCreator
        loading={API.isLoading}
        open={
          createOpen &&
          [
            ROLES.ADMIN,
            ROLES.DATA,
            // ROLES.IMAGE,
            // ROLES.INTERN,
            // ROLES.SEO,
            ROLES.SUPER_ADMIN,
            // ROLES.SUPERUSER,
            // ROLES.WRITER,
          ].some((el) => el === identity)
        }
        onCreate={(v, clear) => {
          const payload = { ...v };
          payload.AllBeachId = beachData?.id;
          if (payload.topics || payload.suggestion) {
            API.sendRequest(
              CONSTANTS.API.BeachSuggestions.create,
              (res) => {
                setCreateOpen(false);
                FlagToggle();
                clear();
              },
              payload
            );
          } else {
            setCreateOpen(false);
            FlagToggle();
            clear();
          }
        }}
        onCancel={() => {
          setCreateOpen(false);
        }}
        menuFields={CONSTANTS.FORM_FIELD.BEACH_SUGGESTIONS}
        formData={{}}
        name={"Add Suggestions for Flag Beach "}
        SubmitName={"Submit"}
      />
    </div>
  );
};

export default BeachFlagComponent;
