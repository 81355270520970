import React from "react";

const Label = (props) => {
  return props.required ? (
    <p className="font-medium capitalize mb-1">
      {props.children}
      <span className=" text-red-500 pr-1 mr-1 mr-3"> &nbsp;*</span>
    </p>
  ) : (
    <p className="font-medium title-head capitalize">{props.children}</p>
  );
};

export default Label;
