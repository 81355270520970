import {Breadcrumb, Row} from "antd";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import UserLogs from "../user/Id/UserLogs";
import {useEffect} from "react";
import useHttp from "../../../hooks/use-http";
import {ROLES} from "../../../util/Route";

const Log = (props) => {
  const API = useHttp();
  useEffect(() => {
    const LogField = CONSTANTS.TABLE.LOG.find(
      (fil) => fil.dataIndex === "userName"
    );
    const SuggestionsField = CONSTANTS.TABLE.Suggestions.find(
      (fil) => fil.dataIndex === "userName"
    );
    const BeachSuggestionsField = CONSTANTS.TABLE.Beach_Suggestions.find(
      (fil) => fil.dataIndex === "userName"
    );
    if (LogField?.filters?.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Users.getAll,
      (res) => {
        const options = res?.data?.rows
          ?.filter((el) => el?.role !== ROLES.USER && !el?.inactive)
          ?.map((user) => ({
            id: user?.id,
            label: `${user?.name} [${user?.role}]`,
            text: `${user?.name} [${user?.role}]`,
            value: user?.id,
          }));
        // console.log(options);
        LogField.filters = options;
        SuggestionsField.filters = options;
        BeachSuggestionsField.filters = options;
      },
      {
        limit: 10000,
        userRole: 0,
      }
    );
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            title: "Log",
          },
        ]}
      />
      <Row>
        <UserLogs />
      </Row>
    </>
  );
};

export default Log;
