import React, { useEffect, useState } from "react";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { Button, Card, Checkbox, Col, Image, Input, Row, Spin } from "antd";
import AddImageCard from "../../basic/Add-Image-Card";
import axios from "axios";
import useFlickrPhotos from "../../../../hooks/useFlickrPhotos";

const Flicker = ({ beachData, refresher, masterRefresh }) => {
  // const [flickrPhotos, setFlickerPhotos] = useState([]);
  const [title, setTitle] = useState("");
  const [resfresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 12,
  });
  const API = useHttp();
  const { flickrPhotos, loading, error } = useFlickrPhotos(title, pagination);
  useEffect(() => {
    setTitle(beachData?.name);
    setRefresh((prev) => !prev);
  }, [beachData]);
  return (
    <div>
      <Row>
        <Col sm={24} lg={8} className="p-3">
          <Input.Search
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onSearch={(v) => {
              setTitle(v);
              setPagination((prev) => {
                if (prev.page === 1) {
                  setRefresh((prevRef) => !prevRef);
                }
                return { ...prev, page: 1 };
              });
            }}
          />
        </Col>
      </Row>
      <Row className=" items-center justify-center">
        {flickrPhotos?.map((img) => (
          <Col
            key={img?.id}
            // style={{ height: "150px" }}
            sm={24}
            md={8}
            lg={6}
            className="p-3 "
          >
            <AddImageCard
              original={img}
              img={{
                small: img?.url_m,
                large: img?.url_o,
                id: img?.id,
              }}
              loading={API.isLoading}
              onAdd={(checkboxValues, image) => {
                let description = image?.description?._content;
                // if (descriptionElement) {
                //   description = descriptionElement.value;
                // }
                let imageUrl;

                if (image && image.url_o) {
                  imageUrl = image.url_o;
                } else if (image && image.url_l) {
                  imageUrl = image.url_l;
                } else if (image && image.url_c) {
                  imageUrl = image.url_c;
                } else if (image && image.url_m) {
                  imageUrl = image.url_m;
                }

                const payload = {
                  imageUrl,
                  AllBeachId: beachData?.id,
                  licenseName: "CC BY 2.0",
                  licenseLink: "https://creativecommons.org/licenses/by/2.0/",
                  photoName: image?.title,
                  photoLink: `https://www.flickr.com/photos/${image?.owner}/${image?.id}`,
                  photographerName: image?.ownername,
                  photographerLink: `https://www.flickr.com/photos/${image?.owner}`,
                  photoSource: "flickr.com",
                  key: image?.secret,
                  description,
                  ...checkboxValues,
                };
                if (masterRefresh) {
                  payload.isCoverPhoto = true;
                }
                API.sendRequest(
                  CONSTANTS.API.BeachPhoto.AddPhoto,
                  (res) => {
                    if (masterRefresh) {
                      refresher();
                    }
                  },
                  payload,
                  "Uploading your photo... Creating a copy for better storage."
                );
              }}
            />
          </Col>
        ))}
      </Row>
      <Row className=" items-center justify-center my-3">
        {loading ? (
          <Spin size="large" />
        ) : (
          <Button
            type="primary"
            onClick={() => {
              setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
            }}
          >
            Load More
          </Button>
        )}
      </Row>
    </div>
  );
};

export default Flicker;
