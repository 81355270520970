import { Button, Divider, Modal, Tabs } from "antd";
import React, { useState } from "react";
import Unsplash from "./Image/Unsplash";
import GooglePhoto from "./Image/GooglePhoto";
import Flicker from "./Image/Flicker";
import UploadImage from "./Image/UploadImage";
import LinkUploadPhoto from "./Image/LinkUploadPhoto";

const AddBeachImage = ({ beachData, refresher, masterRefresh = false }) => {
  const [open, setOpen] = useState(false);
  const RefreshAllDone = () => {
    refresher();
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        type="primary"
      >
        {masterRefresh ? " Add a Cover Photo" : "Add Photo"}
      </Button>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
          refresher();
        }}
        width={1000}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <h1 className=" text-center">
          {" "}
          {masterRefresh ? " Add a Cover Photo" : "Add Photo"}
        </h1>
        <Divider />
        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          tabBarStyle={{ marginBottom: "0" }}
          tabBarGutter={12}
          items={[
            {
              label: `Upload`,
              key: 1,
              children: (
                <UploadImage
                  beachData={beachData}
                  refresher={RefreshAllDone}
                  masterRefresh={masterRefresh}
                />
              ),
            },
            {
              label: `Google Photo`,
              key: 2,
              children: (
                <GooglePhoto
                  beachData={beachData}
                  refresher={RefreshAllDone}
                  masterRefresh={masterRefresh}
                />
              ),
            },
            {
              label: `Unsplash`,
              key: 3,
              children: (
                <Unsplash
                  beachData={beachData}
                  refresher={RefreshAllDone}
                  masterRefresh={masterRefresh}
                />
              ),
            },
            {
              label: `Flickr`,
              key: 4,
              children: (
                <Flicker
                  beachData={beachData}
                  refresher={RefreshAllDone}
                  masterRefresh={masterRefresh}
                />
              ),
            },
            {
              label: `Link Upload Photo`,
              key: 5,
              children: (
                <LinkUploadPhoto
                  beachData={beachData}
                  refresher={RefreshAllDone}
                  masterRefresh={masterRefresh}
                />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default AddBeachImage;
