import { Button, Card, Form, Image, Typography } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator, getClearObject } from "../../../../util/functions";
import { ROLES } from "../../../../util/Route";

const BeachBasicDetail = ({ beachData, identity, notdisabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [BasicDetailData, setBasicDetailData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [refresh, setRefresh] = useState(true);

  const API = useHttp();
  const handleEdit = () => {
    setIsEditing(true);

    // Copy current data to the editedData state
    // setEditedData((prev) => {
    //   return prev?.address
    //     ? { ...prev }
    //     : {
    //         ...beachData?.beachBasicDetail,
    //         restRooms: beachData?.beachCategory?.restRooms,
    //         shower: beachData?.beachCategory?.shower,
    //         address: beachData?.address,
    //       };
    // });
  };
  useEffect(() => {
    if (beachData?.id) {
      const BasicDetailsAPI = apiGenerator(
        CONSTANTS.API.BeachBasicDetails.getOne,
        {
          AllBeachId: beachData?.id,
        }
      );
      API.sendRequest(BasicDetailsAPI, (res) => {
        setEditedData({
          ...res?.data?.[0],
          prefix: res?.data?.[0]?.size?.split(" ")?.[1],
        });
        setBasicDetailData(res?.data?.[0]);
      });
      //  setEditedData((prev) => {
      //    return prev?.address
      //      ? { ...prev }
      //      : {
      //          ...beachData?.beachBasicDetail,
      //          restRooms: beachData?.beachCategory?.restRooms,
      //          shower: beachData?.beachCategory?.shower,
      //          address: beachData?.address,
      //          cellReception: beachData?.cellReception || "Not Available",
      //          prefix: beachData?.beachBasicDetail?.size?.split(" ")?.[1],
      //        };
      //  });
    }
  }, [beachData, refresh]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let payload = { ...values };
        // if (payload?.prefix) {
        //   payload.size = `${payload.size} ${payload.prefix}`;
        // } else {
        payload.size = `${payload.size}`;
        // }
        // delete payload?.prefix;
        if (!Array.isArray(payload?.sandColor) && payload?.sandColor) {
          payload.sandColor = [payload.sandColor];
        }
        // else if (!payload.sandColor) {
        //   payload.sandColor = [];
        // }
        if (!Array.isArray(payload?.sandTexture) && payload?.sandTexture) {
          payload.sandTexture = [payload.sandTexture];
        }
        // else if (!payload.sandTexture) {
        //   payload.sandTexture = [];
        // }
        payload = getClearObject(payload);
        if (BasicDetailData?.id && beachData?.id) {
          payload.AllBeachId = beachData?.id;
          const UpdateBasicDetail = apiGenerator(
            CONSTANTS.API.BeachBasicDetails.update,
            {
              id: BasicDetailData?.id,
            }
          );
          API.sendRequest(
            UpdateBasicDetail,
            (res) => {
              setEditedData(values);
              // console.log(res?.data);
            },
            payload,
            "Beach Basic Details Updated successfully"
          );
        } else {
          if (beachData?.id) {
            const CreateBasicDetail = apiGenerator(
              CONSTANTS.API.BeachBasicDetails.create
            );
            payload.AllBeachId = beachData?.id;
            API.sendRequest(
              CreateBasicDetail,
              (res) => {
                setEditedData(values);
                setRefresh((prev) => !prev);
              },
              payload,
              "Beach Basic Details Updated successfully"
            );
          }
        }
        setIsEditing(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const [form] = Form.useForm();
  return (
    <>
      <Card bordered className="m-4">
        <Typography.Title level={3} className="flex justify-between">
          Beach Basic Details
          {[
            ROLES.ADMIN,
            ROLES.DATA,
            ROLES.IMAGE,
            ROLES.INTERN,
            ROLES.SEO,
            ROLES.SUPER_ADMIN,
            ROLES.SUPERUSER,
            ROLES.WRITER,
          ].some((el) => el === identity) &&
            notdisabled &&
            (isEditing ? (
              <Button
                type="primary"
                danger
                onClick={() => {
                  // setEditedData({});
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button danger onClick={handleEdit}>
                Edit
              </Button>
            ))}
        </Typography.Title>
        <FormFields
          menuFields={CONSTANTS.FORM_FIELD.BEACH_BASIC_EDIT}
          formData={editedData}
          form={form}
          disabled={!isEditing}
        />
        {isEditing && (
          <>
            <Button type="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </>
        )}
        {/* : ( BasicArray?.map( (el) => el?.condition && (
        <Typography.Title
          key={el?.label}
          level={5}
          className="!py-1 !my-1 flex"
        >
          {`${el?.label} : `}
          <span className="font-light ml-1">{el?.value}</span>
        </Typography.Title>
        ) ) ) */}
        {/* <Descriptions title="Beach Main">
          {DisplayData?.map(
            (el) =>
              el?.condition && (
                <Descriptions.Item label={el?.label}>
                  {el?.value}
                </Descriptions.Item>
              )
          )}
        </Descriptions> */}
      </Card>
    </>
  );
};

export default BeachBasicDetail;
