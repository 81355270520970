import { useState, useEffect } from "react";
// check deploye
// Custom hook to use localStorage
const useLocalStorage = (key, initialValue) => {
  // Get data from localStorage or use initial value
  const storedValue = localStorage.getItem(key);
  const initial =
    storedValue !== null && storedValue !== undefined
      ? JSON.parse(storedValue)
      : initialValue;

  // State to hold the current value
  const [value, setValue] = useState(initial);

  // Update localStorage and state when the value changes
  const setStoredValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  // Listen for changes in other tabs/windows
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        const newValue =
          event.newValue !== null && event.newValue !== undefined
            ? JSON.parse(event.newValue)
            : null;
        setValue(newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  return [value, setStoredValue];
};

export default useLocalStorage;
