import {Alert, Breadcrumb, Row, Tabs} from "antd";
import React, {useEffect} from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import AdminPanelSuggestions from "./AdminPanelSuggestions";
import BeachSuggestions from "./BeachSuggestions";
import useHttp from "../../../hooks/use-http";
import {ROLES} from "../../../util/Route";
// /admin/adminPanel/suggestions
const Suggestions = (props) => {
  const API = useHttp();
  useEffect(() => {
    const LogField = CONSTANTS.TABLE.LOG.find(
      (fil) => fil.dataIndex === "userName"
    );
    const SuggestionsField = CONSTANTS.TABLE.Suggestions.find(
      (fil) => fil.dataIndex === "userName"
    );
    const BeachSuggestionsField = CONSTANTS.TABLE.Beach_Suggestions.find(
      (fil) => fil.dataIndex === "userName"
    );
    if (LogField?.filters?.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Users.getAll,
      (res) => {
        const options = res?.data?.rows
          ?.filter((el) => el?.role !== ROLES.USER && !el?.inactive)
          ?.map((user) => ({
            id: user?.id,
            label: `${user?.name} [${user?.role}]`,
            text: `${user?.name} [${user?.role}]`,
            value: user?.id,
          }));
        // console.log(options);
        LogField.filters = options;
        SuggestionsField.filters = options;
        BeachSuggestionsField.filters = options;
      },
      {
        limit: 10000,
        userRole: 0,
      }
    );
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            title: "Suggestions",
          },
        ]}
      />
      <Tabs
        defaultActiveKey="1"
        type="card"
        tabBarStyle={{marginBottom: "0"}}
        tabBarGutter={12}
        items={[
          {
            label: `Admin Panel Suggestions`,
            key: 1,
            children: <AdminPanelSuggestions {...props} />,
          },
          {
            label: `Beach Suggestions`,
            key: 2,
            children: <BeachSuggestions {...props} />,
          },
        ]}
      />
      {/* <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Suggestions.getAll,

            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
              }));
            },
            column: CONSTANTS.TABLE.Suggestions,
          }}
          DELETE={{
            API: CONSTANTS.API.Suggestions.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Suggestion?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          // isSearch
          selectionOff
          props={props}
        />
      </Row> */}
    </>
  );
};

export default Suggestions;
