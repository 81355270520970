import React, { useEffect, useState } from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Statistic,
  Switch,
} from "antd";
import { ROLES, ROUTES, appRoot } from "../../../util/Route";
import FilterComponent from "../../../component/common/basic/Filter-Component";
import useHttp from "../../../hooks/use-http";
import { apiGenerator, getClearObject } from "../../../util/functions";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
const { confirm } = Modal;

const BeachPage = (props) => {
  const [analytics, setAnalytics] = useState({});
  const [extraQuery, setExtraQuery] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [noCoverImage, setnoCoverImage] = useState(false);
  const [externalRefreshState, setExternalRefreshState] = useState(false);
  const API = useHttp();
  const DETAILS = [
    {
      title: "Total No. of Beaches",
      count: analytics?.beachMainCount,
    },
    { title: "Total No. of Cities", count: analytics?.cityCount },
    {
      title: "Total No. of States",
      count: analytics?.stateCount,
    },
    { title: "Total No. Of Countries", count: analytics?.countryCount },
    // { title: "Total Pro Photos", count: analytics?.totalProPhoto || 0 },
  ];
  useEffect(() => {
    setExtraQuery((prev) => {
      const query = {};
      if (selectedCity) {
        query.cityId = selectedCity;
      }
      if (selectedState) {
        query.stateId = selectedState;
      }
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }
      if (noCoverImage) {
        query.noCoverImage = 1;
      }
      return query;
    });
    setExternalRefreshState((prev) => !prev);
  }, [selectedCity, selectedState, selectedCountry, noCoverImage]);

  useEffect(() => {
    API.sendRequest(
      CONSTANTS.API.getAnalyticsAll,
      (res) => {
        setAnalytics(res?.data);
      },
      extraQuery
    );
  }, [extraQuery]);
  useEffect(() => {
    const field = CONSTANTS.FORM_FIELD.BEACH_USER_RELATION.find(
      (fil) => fil.id === "userId"
    );
    if (field.option.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Users.getAll,
      (res) => {
        const options = res?.data?.rows
          ?.filter((el) => el?.role !== ROLES.USER && !el?.inactive)
          ?.map((user) => ({
            id: user?.id,
            label: `${user?.name} [${user?.role}]`,
            value: user?.id,
          }));
        // console.log(options);
        field.option = options;
      },
      {
        limit: 10000,
        userRole: 0,
      }
    );
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.BEACH}`,
            title: "All Beaches",
          },
        ]}
      />
      <Row gutter={[16, 16]} className="mt-6">
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            key={el?.title + i}
          >
            <Card size="small" className="">
              <Statistic title={el?.title} value={el?.count ?? 0} />
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <CRUDComponent
          AddOnComponent={
            <>
              <Col
                span={24}
                sm={24}
                md={22}
                lg={22}
                xl={17}
                xxl={17}
                className="flex gap-4"
              >
                <FilterComponent
                  selectedCity={selectedCity}
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  setSelectedCity={setSelectedCity}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedState={setSelectedState}
                />
                <div className="ml-2">
                  <Switch
                    checked={noCoverImage}
                    onChange={(v) => {
                      setnoCoverImage((prev) => !prev);
                    }}
                    checkedChildren="No Cover Image"
                    unCheckedChildren="All"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </Col>
              {/* {selectedData?.selected?.length ? (
                <Col
                  span={24}
                  sm={24}
                  md={1}
                  lg={1}
                  xl={1}
                  xxl={1}
                  className="flex justify-end"
                >
                  <Button
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    Assign
                  </Button>
                </Col>
              ) : (
                ""
              )} */}
            </>
          }
          GET={{
            API: CONSTANTS.API.Beach.getAll,
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                cityName: el?.city?.name,
                stateName: el?.state?.name,
                countryName: el?.country?.name,
                // cityName: el?.city?.name,
                // stateName: el?.city?.state?.name,
                // countryName: el?.city?.state?.country?.name,
                // isBlocked: {
                //   value: el?.isBlocked,
                //   id: el.id,
                //   onClick: (id, val) => {
                // const UpdateAPIEnd = { ...CONSTANTS?.API.Beach.update };
                // UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                //     API.sendRequest(
                //       UpdateAPIEnd,
                //       () => {
                //         Setrefresh((prev) => !prev);
                //       },
                //       { isBlocked: val },
                //       `Beach ${val ? "blocked" : "Unblocked"} Successfully`
                //     );
                //   },
                // },
                view: {
                  link: `${appRoot}/${ROUTES.BEACH}/${el?.country?.slug}/${el?.state?.slug}/${el?.city?.slug}/${el?.nameSlug}`,
                  id: el?.id,
                },
                action: el?.taggedBeach?.[0]?.action,
                notes: el?.taggedBeach?.[0]?.notes,
                status: {
                  onChange: (v) => {
                    // console.log(v);
                    // console.log(el);
                    const UpadteUSerBEach = apiGenerator(
                      CONSTANTS.API.BeachUser.update,
                      { id: el?.taggedBeach?.[0]?.id }
                    );
                    if (v !== "DONE") {
                      API.sendRequest(
                        UpadteUSerBEach,
                        (res) => {
                          Setrefresh((prev) => !prev);
                        },
                        {
                          status: v,
                        },
                        "Status Updated"
                      );
                    } else {
                      confirm({
                        content: <p>Are you sure to Done this task ? </p>,
                        onOk() {
                          API.sendRequest(
                            UpadteUSerBEach,
                            (res) => {
                              Setrefresh((prev) => !prev);
                            },
                            {
                              status: v,
                            },
                            "Status Updated"
                          );
                        },
                        onCancel() {
                          // API.sendRequest(
                          //   UpadteUSerBEach,
                          //   (res) => {
                          //     Setrefresh((prev) => !prev);
                          //   },
                          //   {
                          //     status: el?.taggedBeach?.[0]?.status,
                          //   }
                          // );
                        },
                      });
                    }
                  },
                  value: el?.taggedBeach?.[0]?.status,
                },
              }));
            },
            column: CONSTANTS.TABLE.BEACH,
          }}
          // UPDATE={{
          //   API: CONSTANTS.API.Beach.update,
          //   message: "Updated Beach successfully",
          //   modaltitle: "Update Beach",
          //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
          //   // payloadModifier: (res) => res,
          // }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add a Beach",
              API: CONSTANTS.API.Beach.create,
              message: "Created Beach successfully",
              modaltitle: "Add Beach",
              modalFields: CONSTANTS.FORM_FIELD.BEACHMAIN_ADD,
              payloadModifier: (values) => {
                const payload = getClearObject(values);
                if (payload?.altName) {
                  payload.altName = payload?.altName
                    ?.split(",")
                    ?.map((alt) => alt?.trim());
                }
                if (payload?.longitude && payload?.latitude) {
                  payload.GeoLoc = {
                    type: "Point",
                    coordinates: [payload.longitude, payload.latitude],
                  };
                }
                delete payload?.longitude;
                delete payload?.latitude;
                // if (payload.cityId) {
                //   delete payload?.stateId;
                //   delete payload?.countryId;
                // }
                return payload;
              },
              AddAfterAddButton: (
                <>
                  {selectedData?.selected?.length ? (
                    <Col
                      // span={24}
                      // sm={24}
                      // md={1}
                      // lg={1}
                      // xl={1}
                      // xxl={1}
                      className="flex justify-end"
                    >
                      <Button
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        {/* <MdEdit /> */}
                        Assign
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </>
              ),
            }
          }
          DELETE={{
            API: CONSTANTS.API.Beach.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this beach?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          // datefilter
          // selectionOff
          rowSelectionObj={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              type: "checkbox",
              onChange: (selected, selectedRow) => {
                // console.log(selected, selectedRow);
                setSelectedData({ selected, selectedRow });
              },
            }
          }
          externalRefreshState={externalRefreshState}
          props={props}
        />

        <ModalFormCreator
          open={modalOpen && selectedData?.selected?.length}
          onCancel={() => {
            setModalOpen(false);
          }}
          onCreate={(v, clear) => {
            // console.log(v);
            const payload = getClearObject(v);
            payload.beachIds = selectedData?.selected;
            // payload.action = payload?.action?.join(",");
            API.sendRequest(
              CONSTANTS.API.BeachUser.create,
              (res) => {
                // console.log(res);
                clear();
                setModalOpen(false);
              },
              payload
            );

            // setSelectedData({});
            // Setrefresh((prev) => !prev);
            // setExtraQuery({});
          }}
          menuFields={CONSTANTS.FORM_FIELD.BEACH_USER_RELATION}
          name="Assign Beach to User"
        />
      </Row>
    </>
  );
};

export default BeachPage;
