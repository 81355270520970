import { Modal } from "antd";
import React from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

export function BeachPhotoEditor({ source, imageData, SetIamgesData }) {
  const closeImgEditor = () => {
    SetIamgesData(null);
  };

  return (
    <>
      <Modal
        open={imageData !== null && imageData?.image_o}
        onCancel={closeImgEditor}
        width={1000}
        zIndex={100}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <FilerobotImageEditor
          source={source || imageData?.image_o}
          useZoomPresetsMenu
          onSave={(editedImageObject, designState) => {
            // console.log("saved", editedImageObject, designState);
            // console.log("object saved", editedImageObject?.imageBase64);
          }}
          defaultTabId={TABS.ANNOTATE} // or 'Annotate'
          defaultToolId={TOOLS.BRIGHTNESS} // or 'Text'
        />
        {/* )} */}
      </Modal>
    </>
  );
}
