import {
  Breadcrumb,
  Button,
  Card,
  Col,
  FloatButton,
  Image,
  Popconfirm,
  Row,
  Select,
  Spin,
  Statistic,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { ROLES } from "../../../util/Route";
import useHttp from "../../../hooks/use-http";
import FilterComponent from "../../../component/common/basic/Filter-Component";
import { FinalImageGenerator, apiGenerator } from "../../../util/functions";
import { FaCheck, FaDownload, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { EllipsisOutlined } from "@ant-design/icons";
import ScrollLoader from "../../../component/common/advance/ScrollLoader";
import { BsQuestionCircle } from "react-icons/bs";
const { Option } = Select;
const filterImages = [
  { id: 1, value: "all", label: "All" },
  { id: 2, value: 1, label: "No License Image" },
];
const AllPhotosPage = (props) => {
  const API = useHttp();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [allPhotoGrapher, setAllPhotographer] = useState([]);
  const [selectedPhotographer, setSelectedPhotographer] = useState(null);

  const [loadoff, setLoadOff] = useState(false);

  const [SelectedAtributes, setSelectedAttributes] = useState([]);
  const [images, SetIamges] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refreshAnalytic, setRefreshAnalytic] = useState(false);
  const [analytics, setAnalytic] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedFilterImage, setSelectedFilterImage] = useState("all");

  useEffect(() => {
    const query = {};

    if (selectedCountry) {
      query.countryId = selectedCountry;
    }
    if (selectedState) {
      delete query.countryId;
      query.stateId = selectedState;
    }
    if (selectedCity) {
      delete query.countryId;
      delete query.stateId;
      query.cityId = selectedCity;
    }
    if (!!selectedPhotographer) {
      query.createdByDisplayName = selectedPhotographer;
    }
    if (SelectedAtributes.length > 0) {
      SelectedAtributes.forEach((el) => {
        query[`${el}`] = true;
      });
    }
    if (selectedFilterImage === 1) {
      query.noLicense = 1;
    }

    API.sendRequest(
      { type: "GET", endpoint: "/admin/image" },
      (res) => {
        // SetIamgesData(res?.data);
        if (pagination.page === 1) {
          SetIamges(res?.data || []);
        } else {
          if (res?.data?.length < pagination?.limit) {
            setLoadOff(true);
          }
          SetIamges((prev) => [...prev, ...res?.data]);
        }
      },
      { ...pagination, ...query }
    );
  }, [pagination.page, refresh]);
  useEffect(() => {
    setLoadOff(false);
    SetIamges([]);
    setRefreshAnalytic((prev) => !prev);
    setPagination((prev) => {
      if (prev.page === 1) {
        setRefresh((prev) => !prev);
      }
      return { ...prev, page: 1 };
    });
  }, [
    selectedCity,
    selectedState,
    selectedCountry,
    selectedPhotographer,
    SelectedAtributes,
    selectedFilterImage !== "all",
  ]);

  useEffect(() => {
    const query = {};

    if (selectedCountry) {
      query.countryId = selectedCountry;
    }
    if (selectedState) {
      delete query.countryId;
      query.stateId = selectedState;
    }
    if (selectedCity) {
      delete query.countryId;
      delete query.stateId;
      query.cityId = selectedCity;
    }
    if (!!selectedPhotographer) {
      query.createdByDisplayName = selectedPhotographer;
    }
    if (selectedFilterImage === 1) {
      query.noLicense = 1;
    }
    // if (SelectedAtributes.length > 0) {
    //   SelectedAtributes.forEach((el) => {
    //     query[`${el}`] = true;
    //   });
    // }
    API.sendRequest(
      CONSTANTS.API.overview,
      (res) => {
        setAnalytic({ ...res?.data, ...res?.data?.imageCounts });
        // setAllPhotographer(
        //   res?.data
        //     ?.filter((el) => el?.createdByDisplayName)
        //     ?.map((el) => el?.createdByDisplayName)
        // );
      },
      {
        ...query,
      }
    );
  }, [refreshAnalytic]);

  useEffect(() => {
    API.sendRequest(
      {
        type: "GET",
        endpoint: "/admin/image/getCreatedByDisplayName",
      },
      (res) => {
        // console.log(res?.data);
        setAllPhotographer(
          res?.data
            ?.filter((el) => el?.createdByDisplayName)
            ?.map((el) => el?.createdByDisplayName)
        );
      }
    );
  }, []);

  const handlephotographerChange = (value) => {
    setSelectedPhotographer(value);
  };
  const handleFilterImageChange = (value) => {
    setSelectedFilterImage(value);
  };
  const DETAILS = [
    {
      title: "Total No. of Photos",
      count: analytics?.totalPhoto,
    },
    { title: "Total No. of Cover Photos", count: analytics?.CoverPhoto },
    {
      title: "Total No. of 5-Star Photos",
      count: analytics?.FiveStarPhoto,
    },
    { title: "Total No. of Drone Photos", count: analytics?.DronePhoto },
    {
      title: "Total No. of Pro Photos",
      count: analytics?.ProPhoto || 0,
    },
    {
      title: "Total No. of Person Photos",
      count: analytics?.CheckPhoto || 0,
    },
  ];
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            title: "All Photos",
          },
        ]}
      />
      <Row gutter={[16, 16]} className="mt-6 mb-4">
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            key={el?.title + i}
          >
            <Card size="small" className="">
              <Statistic title={el?.title} value={el?.count ?? 0} />
            </Card>
          </Col>
        ))}
      </Row>
      <Card className=" ">
        <Row className="w-full">
          <Col
            span={24}
            // sm={24}
            // md={22}
            // lg={22}
            // xl={17}
            // xxl={17}
            className="pl-2 pb-4"
          >
            <FilterComponent
              selectedCity={selectedCity}
              selectedCountry={selectedCountry}
              selectedState={selectedState}
              setSelectedCity={setSelectedCity}
              setSelectedCountry={setSelectedCountry}
              setSelectedState={setSelectedState}
              extraComponentBehind={
                <>
                  <Select
                    className="mx-1 ml-2"
                    showSearch
                    placeholder="Select a photographer"
                    onChange={handlephotographerChange}
                    style={{ width: "200px" }}
                    // disabled={disabled}
                    value={selectedPhotographer}
                    allowClear
                    filterOption={(inputValue, option) => {
                      const value = `${option?.children}`;
                      return value
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase());
                    }}
                  >
                    {allPhotoGrapher.map((photographer) => (
                      <Option key={photographer} value={photographer}>
                        {photographer}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    mode="multiple"
                    className="mx-1"
                    showSearch
                    placeholder="Select a Attributes"
                    onChange={(val) => {
                      setSelectedAttributes(val);
                    }}
                    style={{ minWidth: "200px" }}
                    // disabled={disabled}
                    // value={selectedPhotographer}
                    allowClear
                    filterOption={(inputValue, option) => {
                      const value = `${option?.children}`;
                      return value
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase());
                    }}
                  >
                    {FILTER_ITEMS.map((attribute) => (
                      <Option key={attribute.id} value={attribute.id}>
                        {attribute.name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    className="mx-1 ml-2"
                    showSearch
                    placeholder="Select..."
                    onChange={handleFilterImageChange}
                    style={{ width: "200px" }}
                    // disabled={disabled}
                    value={selectedFilterImage}
                    defaultValue="all"
                    // allowClear
                    filterOption={(inputValue, option) => {
                      const value = `${option?.children}`;
                      return value
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase());
                    }}
                  >
                    {filterImages.map((el) => (
                      <Option key={el?.id} value={el?.value}>
                        {el?.label}
                      </Option>
                    ))}
                  </Select>
                </>
              }
            />
          </Col>
          {/* <Col span={7}>
            <div className="">
              <Switch
                checked={noLicenseImage}
                onChange={(v) => {
                  setnoLicenseImage((prev) => !prev);
                }}
                checkedChildren="No License Image"
                unCheckedChildren="All"
                // style={{
                //   marginLeft: "10px",
                // }}
              />
            </div>
          </Col> */}
        </Row>
        <Row gutter={[16, 8]}>
          {images.map((item, index) => (
            <Col
              key={`${item?.id}-${index}`}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              xxl={4}
            >
              <Card
                className="!m-0 !p-0"
                rootClassName=" rounded-lg"
                bodyStyle={{ margin: 0, padding: 0 }}
                actions={[
                  <Button
                    size="middle"
                    shape="circle"
                    type={item?.isCoverPhoto ? "primary" : "dashed"}
                  >
                    CP
                  </Button>,
                  <Button
                    size="middle"
                    shape="circle"
                    type={item?.isFiveStarPhoto ? "primary" : "dashed"}
                  >
                    5s
                  </Button>,
                  <Button
                    size="middle"
                    shape="circle"
                    type={item?.isDronePhoto ? "primary" : "dashed"}
                  >
                    D
                  </Button>,
                  <Button
                    size="middle"
                    shape="circle"
                    type={item?.isProPhoto ? "primary" : "dashed"}
                  >
                    P
                  </Button>,
                  <Button
                    size="middle"
                    shape="circle"
                    type={item?.isCheckPhoto ? "primary" : "dashed"}
                  >
                    <FaCheck />
                  </Button>,
                ]}
              >
                <div className=" relative">
                  <div
                    className=" w-full h-full "
                    style={{
                      maxHeight: "175px",
                      height: "175px",
                    }}
                  >
                    <div className="flex justify-end">
                      <FloatButton.Group
                        className="absolute right-3 bottom-2 "
                        trigger="click"
                        tooltip="Menu"
                        icon={<EllipsisOutlined key="ellipsis" height={10} />}
                      >
                        <Row gutter={[4, 4]} className=" justify-center">
                          {[
                            ROLES.ADMIN,
                            // ROLES.DATA,
                            ROLES.IMAGE,
                            // ROLES.INTERN,
                            // ROLES.SEO,
                            ROLES.SUPER_ADMIN,
                            // ROLES.SUPERUSER,
                            // ROLES.WRITER,
                          ].some((el) => el === props?.profile?.role) ? (
                            <Popconfirm
                              title="Are you sure to Delete this Photo?"
                              onConfirm={() => {
                                API?.sendRequest(
                                  CONSTANTS.API.BeachPhoto.DeletePhoto,
                                  (res) => {
                                    // item?.refresher();
                                    setPagination((prev) => {
                                      if (prev.page === 1) {
                                        setRefresh((prev) => !prev);
                                      }
                                      return { ...prev, page: 1 };
                                    });
                                  },
                                  { ids: [item?.id] }
                                );
                              }}
                            >
                              <Button
                                type="primary"
                                shape="circle"
                                size="middle"
                                danger
                              >
                                <MdDelete />
                              </Button>
                            </Popconfirm>
                          ) : (
                            " "
                          )}
                          {selectedFilterImage === 1 ? (
                            <Popconfirm
                              title={
                                <p className=" flex items-center justify-center">
                                  <BsQuestionCircle className=" text-red-600 mx-2" />{" "}
                                  {`Are you sure you want to proceed with this update for Randall Kaplan?`}
                                </p>
                              }
                              onConfirm={() => {
                                if (item?.id) {
                                  const EditCreatedByDisplayName = apiGenerator(
                                    CONSTANTS.API.BeachPhoto.update,
                                    {
                                      id: item?.id,
                                    }
                                  );
                                  API.sendRequest(
                                    EditCreatedByDisplayName,
                                    (res) => {
                                      setRefresh((prev) => !prev);
                                      //  console.log(res,"**-*--")
                                    },
                                    {
                                      createdByDisplayName: "Randall Kaplan",
                                    },
                                    "Add default license successfully."
                                  );
                                }
                                // item?.SetIamgesData({
                                //   id: item?.id,
                                //   image_o: item?.imageUrl,
                                //   image_l: FinalImageGenerator(item, 1600, 3),
                                //   image_m: FinalImageGenerator(item, 1600, 2),
                                //   image_s: FinalImageGenerator(item, 1600),
                                // });
                              }}
                              align="center"
                              icon={null}
                            >
                              <Button
                                type="primary"
                                shape="circle"
                                size="middle"
                                tooltip="Menu"
                                // onClick={() => {
                                //   if (item?.id) {
                                //     const EditCreatedByDisplayName =
                                //       apiGenerator(
                                //         CONSTANTS.API.BeachPhoto.update,
                                //         {
                                //           id: item?.id,
                                //         }
                                //       );
                                //     API.sendRequest(
                                //       EditCreatedByDisplayName,
                                //       (res) => {
                                //         setRefresh((prev) => !prev);
                                //         //  console.log(res,"**-*--")
                                //       },
                                //       {
                                //         createdByDisplayName: "Randall Kaplan",
                                //       },
                                //       "Add default license successfully."
                                //     );
                                //   }
                                //   // item?.SetIamgesData({
                                //   //   id: item?.id,
                                //   //   image_o: item?.imageUrl,
                                //   //   image_l: FinalImageGenerator(item, 1600, 3),
                                //   //   image_m: FinalImageGenerator(item, 1600, 2),
                                //   //   image_s: FinalImageGenerator(item, 1600),
                                //   // });
                                // }}
                              >
                                <FaPlus />
                              </Button>
                            </Popconfirm>
                          ) : null}

                          {/* <Button
                          type="primary"
                          shape="circle"
                          size="middle"
                          onClick={() => {
                            item?.SetIamgesData({
                              id: item?.id,
                              image_o: item?.imageUrl,
                              image_l: FinalImageGenerator(item, 1600, 3),
                              image_m: FinalImageGenerator(item, 1600, 2),
                              image_s: FinalImageGenerator(item, 1600),
                            });
                          }}
                        >
                          <FaEdit />
                        </Button> */}
                          <Button type="primary" shape="circle" size="middle">
                            {/* <a href={item?.imageUrl} download target="_blank"> */}
                            <FaDownload
                              onClick={() => {
                                window.open(item?.imageUrl, "download");
                              }}
                            />
                            {/* </a> */}
                          </Button>
                        </Row>
                        {/* <FloatButton
                // type="primary"
             
                icon={<MdDelete />}
              /> */}
                      </FloatButton.Group>
                    </div>

                    <Image
                      alt="example"
                      wrapperStyle={{
                        width: "100%",
                        // height: "100%",
                        minHeight: "175px",
                        maxHeight: "175px",
                        height: "175px",
                      }}
                      rootClassName=" rounded-lg"
                      wrapperClassName=" rounded-lg"
                      draggable="false"
                      className="!w-full !h-full rounded-lg"
                      // src={item?.imageUrl}
                      preview={{
                        src: FinalImageGenerator(item, 1600, 3),
                      }}
                      src={FinalImageGenerator(item, 1400)}
                    />
                  </div>
                </div>
                <p className=" p-1 text-[16px] font-normal text-gray-400">
                  {item?.AllBeach?.name}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className=" items-center justify-center my-3">
          {API.isLoading ? (
            <Spin size="large" />
          ) : (
            <ScrollLoader
              threshold={60}
              loadMoreFunction={() => {
                setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
              }}
              isLoading={API.isLoading}
              loadoff={loadoff}
              timeout={10}
            />
            // <Button
            //   type="primary"
            //   onClick={() => {
            //     setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
            //   }}
            // >
            //   Load More
            // </Button>
          )}
        </Row>
      </Card>
    </>
  );
};

export default AllPhotosPage;

export const FILTER_ITEMS = [
  { name: "Cover Photo", id: "isCoverPhoto", label: "isCoverPhoto" },
  { name: "5 Star", id: "isFiveStarPhoto", label: "isFiveStarPhoto" },
  { name: "Drone", id: "isDronePhoto", label: "isDronePhoto" },
  { name: "Pro", id: "isProPhoto", label: "isProPhoto" },
  { name: "Person Photo", id: "isCheckPhoto", label: "isCheckPhoto" },
  // { name: "Influencer Photo", id: "isCrossPhoto", label: "isCrossPhoto" },
  // { name: 'Person Photo', id: 'isPersonPhoto', label: 'isPersonPhoto' },
  // {
  //   name: 'Influencer Photo',
  //   id: 'isInfluencerPhoto',
  //   label: 'isInfluencerPhoto',
  // },
];
