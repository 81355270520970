import React, { useState } from "react";
import {
  Image,
  message,
  Upload,
  Checkbox,
  Button,
  Row,
  Col,
  notification,
  Progress,
  Modal,
  Spin,
} from "antd";
import { FaInbox } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import Services from "../../../../util/API/service";

const UploadImage = ({ beachData, refresher, masterRefresh }) => {
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState([]);

  const handleChange = (info) => {
    const { status } = info.file;

    if (status !== "uploading") {
      setFileList(info.fileList);
    }

    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    }
  };

  const handleCheckboxChange = (file, property) => (e) => {
    file[property] = e.target.checked;
    setFileList([...fileList]);
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    onChange: handleChange,
    // onDrop: (e) => console.log("Dropped files", e.dataTransfer.files),
  };

  const API = useHttp();

  // const SubmitPhotos = async () => {
  //   const MegaPayload = [];
  //   fileList.forEach((singleFile, i) => {
  //     const payload = {};
  //     const formData = new FormData();
  //     if (singleFile?.originFileObj) {
  //       payload.imageUrl = singleFile?.originFileObj;
  //       formData.append("imageUrl", singleFile?.originFileObj);
  //     }
  //     if (singleFile?.isProPhoto) {
  //       payload.isProPhoto = singleFile?.isProPhoto;
  //       formData.append("isProPhoto", singleFile?.isProPhoto);
  //     }
  //     if (singleFile?.isFiveStarPhoto) {
  //       payload.isFiveStarPhoto = singleFile?.isFiveStarPhoto;
  //       formData.append("isFiveStarPhoto", singleFile?.isFiveStarPhoto);
  //     }
  //     if (singleFile?.isDronePhoto) {
  //       payload.isDronePhoto = singleFile?.isDronePhoto;
  //       formData.append("isDronePhoto", singleFile?.isDronePhoto);
  //     }
  //     if (i === 0 && masterRefresh) {
  //       formData.append("isCoverPhoto", true);
  //     }
  //     formData.append("AllBeachId", beachData?.id);
  //     MegaPayload.push(formData);
  //     // console.log(payload, formData);
  //   });

  //   const successMessages = [];
  //   const errorMessages = [];
  //   const AllPromises = MegaPayload?.map(async (SinglePayload, i) => {
  //     try {
  //       const responseSingle = await Services.post(
  //         CONSTANTS.API.BeachPhoto.AddPhotoBulk.endpoint,
  //         SinglePayload,
  //         {
  //           onUploadProgress: (progressEvent) => {
  //             const percentCompleted = Math.round(
  //               (progressEvent.loaded * 100) / progressEvent.total
  //             );
  //             setProgress((prev) => {
  //               prev[i] = percentCompleted;
  //               // console.log(prev);
  //               return [...prev];
  //             });
  //           },
  //         }
  //       );
  //       const data = responseSingle.data;

  //       successMessages.push({
  //         id: i + 1,
  //         type: "success",
  //         data: data,
  //       });
  //     } catch (err) {
  //       errorMessages.push({ id: i + 1, type: "fail", error: err });
  //     }
  //   });
  //   await Promise.all(AllPromises);
  //   return {
  //     errorMessages,
  //     successMessages,
  //   };

  //   // API.sendRequest(
  //   //   CONSTANTS.API.BeachPhoto.AddPhotoBulk,
  //   //   (allResponse) => {
  //   //     // console.log(allResponse.data);
  //   // if (allResponse?.data?.errorMessages?.length) {
  //   //   notification.error({
  //   //     message: `Total ${allResponse?.data?.errorMessages?.length} upload failed `,
  //   //   });
  //   // }
  //   // if (allResponse?.data?.successMessages?.length) {
  //   //   notification.success({
  //   //     message: `Total ${allResponse?.data?.successMessages?.length} upload failed `,
  //   //   });
  //   // }
  //   // refresher();
  //   //   },
  //   //   MegaPayload,
  //   //   null,
  //   //   (val) => {
  //   //     // console.log("Progress called inside bulk");
  //   //     const { key, percentCompleted } = val;
  //   //     // console.log(val);
  //   // setProgress((prev) => {
  //   //   prev[key] = percentCompleted;
  //   //   // console.log(prev);
  //   //   return [...prev];
  //   // });
  //   //   }
  //   // );
  // };
  const SubmitPhotos = async () => {
    const MegaPayload = fileList.map((singleFile, i) => {
      const formData = new FormData();
      
      if (singleFile?.originFileObj) {
        formData.append("imageUrl", singleFile?.originFileObj);
      }
      if (singleFile?.isProPhoto) {
        formData.append("isProPhoto", singleFile?.isProPhoto);
      }
      if (singleFile?.isFiveStarPhoto) {
        formData.append("isFiveStarPhoto", singleFile?.isFiveStarPhoto);
      }
      if (singleFile?.isDronePhoto) {
        formData.append("isDronePhoto", singleFile?.isDronePhoto);
      }
      if (i === 0 && masterRefresh) {
        formData.append("isCoverPhoto", true);
      }
      formData.append("AllBeachId", beachData?.id);
  
      return Services.post(
        CONSTANTS.API.BeachPhoto.AddPhotoBulk.endpoint,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress((prev) => {
              prev[i] = percentCompleted;
              return [...prev];
            });
          },
        }
      ).then(response => ({
        id: i + 1,
        type: "success",
        data: response.data
      })).catch(error => ({
        id: i + 1,
        type: "fail",
        error: error
      }));
    });
  
    const results = await Promise.all(MegaPayload);
  
    const successMessages = results.filter(result => result.type === "success");
    const errorMessages = results.filter(result => result.type === "fail");
  
    return {
      errorMessages,
      successMessages,
    };
  };
  
  return (
    <div className="m-4">
      <Dragger
        {...props}
        fileList={fileList}
        itemRender={(originNode, file, currentFileList, actios) => {
          return (
            <Row
              key={file.uid}
              className="mt-2 justify-between rounded-sm shadow-md hover:scale-105"
            >
              <Col className="">
                <Row className=" justify-center items-center">
                  <Image
                    src={URL.createObjectURL(file?.originFileObj)}
                    width={100}
                    className=" aspect-video"
                  />
                  <Col>
                    <Checkbox
                      className="ml-2"
                      onChange={handleCheckboxChange(file, "isProPhoto")}
                    >
                      Pro Photo
                    </Checkbox>
                    <Checkbox
                      className="ml-2"
                      onChange={handleCheckboxChange(file, "isDronePhoto")}
                    >
                      Drone Photo
                    </Checkbox>
                    <Checkbox
                      className="ml-2"
                      onChange={handleCheckboxChange(file, "isFiveStarPhoto")}
                    >
                      5-Star Photo
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
              <Col className="flex justify-center items-center">
                <Button
                  className=" text-lg"
                  type="danger"
                  onClick={actios.remove}
                >
                  <MdDelete />
                </Button>
              </Col>
            </Row>
          );
        }}
      >
        <p className="ant-upload-drag-icon">
          <FaInbox />
        </p>
        <p className="ant-upload-text capitalize">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint capitalize">
          Support for a single or bulk upload.
        </p>
      </Dragger>
      {/* {fileList.map((file) => (
        <div key={file.uid} className="mt-2">
          <Image src={URL.createObjectURL(file?.originFileObj)} width={100} />
          <Checkbox
            className="ml-2"
            onChange={handleCheckboxChange(file, "isProPhoto")}
          >
            Pro Photo
          </Checkbox>
          <Checkbox
            className="ml-2"
            onChange={handleCheckboxChange(file, "isDronePhoto")}
          >
            Drone Photo
          </Checkbox>
          <Checkbox
            className="ml-2"
            onChange={handleCheckboxChange(file, "isFiveStarPhoto")}
          >
            5-Star Photo
          </Checkbox>
        </div>
      ))} */}
      <Row className=" justify-end mt-5">
        <Button
          type="primary"
          htmlType="submit"
          loading={API.isLoading}
          onClick={async () => {
            const { errorMessages, successMessages } = await SubmitPhotos();

            if (errorMessages?.length) {
              notification.error({
                message: `Total ${errorMessages?.length} upload failed `,
              });
            }
            if (successMessages?.length) {
              notification.success({
                message: `Total ${successMessages?.length} uploading your photo... Creating a copy for better storage.`,
              });
            }
            setProgress([]);
            refresher();
            setFileList([]);
           
          }}
        >
          Upload
        </Button>
      </Row>
      <Modal
        open={progress?.length > 0}
        // open={true}
        footer={[]}
        closeIcon={null}
        className=""
      >
        <div className=" h-24 flex items-center flex-col justify-around ">
          <Progress
            percent={
              (progress?.reduce((partialSum, a) => partialSum + a, 0) /
              progress?.length).toFixed()
            }
          />
          {!!(
            progress?.reduce((partialSum, a) => partialSum + a, 0) /
              progress?.length >
            99
          ) && (
            <div className="w-full">
              <Spin
                tip="File Uploaded to Server and We are processing it"
                size="large"
              >
                <div className="content" />
              </Spin>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UploadImage;
