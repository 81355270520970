import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Input, Row, Spin } from "antd";
import axios from "axios";
import useHttp from "../../../hooks/use-http";

const LocationUnsplash = ({ locationData, API, onSuccess }) => {
    const [unsplashPhoto, setUnsplashPhotos] = useState([]);
    const [title, setTitle] = useState("");
    const [resfresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 12,
    });


    useEffect(() => {
        // title &&
        //   API.sendRequest(
        //     CONSTANTS.API.Flicker,
        //     (res) => {
        //       if (pagination.page === 1) {
        //         setUnsplashPhotos(res?.data);
        //       } else {
        //         setUnsplashPhotos((prev) => [...prev, ...res?.data]);
        //       }
        //     },
        //     { ...pagination, title }
        //   );
        const UnspashFetcher = async () => {
            setIsLoading(true);
            const res = await axios.get(
                `https://api.unsplash.com/search/photos?query=${title}&client_id=7LsMX01l2I637GvemDecFmBBUXoXXj9DEEe1lu2CLwc&page=${pagination.page}&per_page=${pagination.limit}&orientation=landscape`
            );
            // const res = JSON.parse(resF)
            if (pagination.page === 1) {
                setUnsplashPhotos(res?.data?.results);
            } else {
                setUnsplashPhotos((prev) => [...prev, ...res?.data?.results]);
            }
            setIsLoading(false);
        };
        if (title && pagination.page && pagination.limit) {
            try {
                UnspashFetcher();
            } catch (error) {
                console.log(error);
            }
        }
    }, [pagination.page, resfresh]);
    useEffect(() => {
        setTitle(locationData?.name);
        setRefresh((prev) => !prev);
    }, [locationData]);

    // const handleCheckboxChange = (file, property) => (e) => {
    //   // file[property] = e.target.checked;
    //   // setFileList([...fileList]);
    //   console.log(file, property, e.target.checked, "Checked Prop");
    // };
    return (
        <div>
            <Row>
                <Col sm={24} lg={8} className="p-3">
                    <Input.Search
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                        onSearch={(v) => {
                            setTitle(v);
                            setPagination((prev) => {
                                if (prev.page === 1) {
                                    setRefresh((prevRef) => !prevRef);
                                }
                                return { ...prev, page: 1 };
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row className=" items-center justify-center" gutter={[18, 8]}>
                {unsplashPhoto?.map((img, index) => (
                    <Col
                        key={`${img?.id}-${index}`}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                        xxl={6}
                        className="!h-[175px]"
                        onClick={() => {
                            let description = img?.description;
                            // if (descriptionElement) {
                            //   description = descriptionElement.value;
                            // }
                            const payload = {
                                imageUrl: img?.urls?.full,
                                // AllBeachId: img?.id,
                                licenseName: "CC BY 2.0",
                                licenseLink: "https://creativecommons.org/licenses/by/2.0/",
                                photoName: img?.name,
                                photoLink: img?.urls?.full,
                                photographerName: img?.user?.name,
                                photographerLink: img?.user?.profile_image?.large,
                                photoSource: "unsplash.com",
                                key: img?.id,
                                description,
                            };
                            if (!API?.isLoading) {
                                const formData = new FormData();
                                Object?.entries(payload).forEach(([key, value]) => {
                                    formData.append(key, value)
                                })
                                onSuccess(formData)
                            }
                        }}
                    >
                        <Image
                            alt="example"
                            wrapperStyle={{
                                width: "100%",
                                height: "100%",
                                minHeight: "175px",
                                maxHeight: "175px",
                            }}
                            rootClassName=" rounded-lg"
                            wrapperClassName=" rounded-lg"
                            draggable="false"
                            className="!w-full !h-full rounded-lg cursor-pointer"
                            // src={item?.imageUrl}
                            //   preview={{
                            //     src: FinalImageGenerator(item, 1600, 3),
                            //   }}
                            preview={false}
                            src={img?.urls.small_s3 ?? img?.urls?.regular ?? img?.urls?.full ?? img?.urls?.raw}
                        // src={FinalImageGenerator(item, 1400)}
                        />
                    </Col>
                ))}
            </Row>
            <Row className=" items-center justify-center my-3">
                {/* {API.isLoading ? ( */}
                {isLoading ? (
                    <Spin size="large" />
                ) : (
                    <Button
                        type="primary"
                        onClick={() => {
                            setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
                        }}
                    >
                        Load More
                    </Button>
                )}
            </Row>
        </div>
    );
};

export default LocationUnsplash;
