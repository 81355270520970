import {Row} from "antd";
import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
// /admin/adminPanel/suggestions
const AdminPanelSuggestions = (props) => {
  return (
    <>
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Suggestions.getAll,

            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                userName: el?.user?.name,
              }));
            },
            column: CONSTANTS.TABLE.Suggestions,
          }}
          DELETE={{
            API: CONSTANTS.API.Suggestions.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Suggestion?",
          }}
          FILTERSORTKEY={{
            userRole: "$user.role$",
            userName: "userId",
            userEmail: "$user.email$",
            // name: "$AllBeach.name$",
          }}
          // isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default AdminPanelSuggestions;
