import React, { useEffect, useState } from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Breadcrumb, Card, Col, Row, Statistic } from "antd";
import { ROLES, ROUTES, appRoot } from "../../../util/Route";
import FilterComponent from "../../../component/common/basic/Filter-Component";
import useHttp from "../../../hooks/use-http";

const WithoutCoverBeachPage = (props) => {
  const [analytics, setAnalytics] = useState({});
  const [extraQuery, setExtraQuery] = useState({
    noCoverImage: 1,
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [externalRefreshState, setExternalRefreshState] = useState(false);

  const API = useHttp();
  const DETAILS = [
    {
      title: "Total No. of Beaches",
      count: analytics?.beachMainCount,
    },
    { title: "Total No. of Cities", count: analytics?.cityCount },
    {
      title: "Total No. of States",
      count: analytics?.stateCount,
    },
    { title: "Total No. Of Countries", count: analytics?.countryCount },
  ];
  useEffect(() => {
    setExtraQuery((prev) => {
      const query = {};
      if (selectedCity) {
        query.cityId = selectedCity;
      }
      if (selectedState) {
        query.stateId = selectedState;
      }
      if (selectedCountry) {
        query.countryId = selectedCountry;
      }

      query.noCoverImage = 1;

      return query;
    });
    setExternalRefreshState((prev) => !prev);
  }, [selectedCity, selectedState, selectedCountry]);

  useEffect(() => {
    API.sendRequest(
      CONSTANTS.API.getAnalyticsAll,
      (res) => {
        setAnalytics(res?.data);
      },
      extraQuery
    );
  }, [extraQuery]);
  useEffect(() => {
    const field = CONSTANTS.FORM_FIELD.BEACH_USER_RELATION.find(
      (fil) => fil.id === "userId"
    );
    if (field.option.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Users.getAll,
      (res) => {
        const options = res?.data?.rows
          ?.filter((el) => el?.role !== ROLES.USER && !el?.inactive)
          ?.map((user) => ({
            id: user?.id,
            label: `${user?.name} [${user?.role}]`,
            value: user?.id,
          }));
        field.option = options;
      },
      {
        limit: 10000,
        userRole: 0,
      }
    );
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            title: "All Beaches",
          },
        ]}
      />
      <Row gutter={[16, 16]} className="mt-6">
        {DETAILS?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            key={el?.title + i}
          >
            <Card size="small" className="">
              <Statistic title={el?.title} value={el?.count ?? 0} />
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <CRUDComponent
          AddOnComponent={
            <>
              <Col
                span={24}
                sm={24}
                md={22}
                lg={22}
                xl={17}
                xxl={17}
                className="flex gap-4"
              >
                <FilterComponent
                  selectedCity={selectedCity}
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  setSelectedCity={setSelectedCity}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedState={setSelectedState}
                />
              </Col>
            </>
          }
          GET={{
            API: CONSTANTS.API.Beach.getAll,
            extraQuery,
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                cityName: el?.city?.name,
                stateName: el?.state?.name,
                countryName: el?.country?.name,
                view: {
                  link: `${appRoot}/${ROUTES.BEACH}/${el?.country?.slug}/${el?.state?.slug}/${el?.city?.slug}/${el?.nameSlug}`,
                  id: el?.id,
                },
                coverPhotoAdd: {
                  beachData: { ...el },
                  refresher: () => {
                    Setrefresh((prev) => !prev);
                  },
                },
              }));
            },
            column: CONSTANTS.TABLE.BEACH_WITHOUT_PHOTOS,
          }}
          isSearch
          selectionOff
          externalRefreshState={externalRefreshState}
          props={props}
        />
      </Row>
    </>
  );
};

export default WithoutCoverBeachPage;
