import { Alert, Breadcrumb, Button, Card, Row } from "antd";
import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { ROLES } from "../../../util/Route";

const RedirectionPage = (props) => {
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.Redirection to URL}`,
            title: "Redirect URL",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.RedirectUrl.getAll,

            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
              }));
            },
            column: CONSTANTS.TABLE.REDIRECT_URL,
          }}
          UPDATE={{
            API: CONSTANTS.API.RedirectUrl.update,
            message: "Updated Redirection to URL successfully",
            modaltitle: "Update Redirection to URL",
            modalFields: CONSTANTS.FORM_FIELD.REDIRECT_URL,
            // payloadModifier: (res) => res,
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add a Redirection to URL",
              API: CONSTANTS.API.RedirectUrl.create,
              message: "Created Redirection to URL successfully",
              modaltitle: (
                <>
                  Add Redirection to URL
                  <Alert
                    type="info"
                    message="Example for creating a Redirection [ Do not add a full url with http:// or https:// as well do not include the domain ]"
                    description={
                      <>
                        Old URL :
                        '/united-states/florida/santa-rosa-beach/grayton-beach-state-park'{" "}
                        <br></br>New URL :
                        '/united-states/florida/santa-rosa-beach/grayton-beach'{" "}
                      </>
                    }
                    showIcon
                  />
                </>
              ),
              modalFields: CONSTANTS.FORM_FIELD.REDIRECT_URL,
            }
          }
          DELETE={{
            API: CONSTANTS.API.RedirectUrl.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Redirection to URL?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default RedirectionPage;
