import {Button} from "antd";
import React, {useState} from "react";
import {MdSettingsSuggest} from "react-icons/md";
import ModalFormCreator from "./ModalFormCreator";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";
import {ROLES} from "../../util/Route";

const SuggestionForm = (props) => {
  const API = useHttp();
  const [createOpen, setCreateOpen] = useState(false);

  return [
    ROLES.ADMIN,
    ROLES.DATA,
    // ROLES.IMAGE,
    ROLES.INTERN,
    // ROLES.SEO,
    // ROLES.SUPER_ADMIN,
    // ROLES.SUPERUSER,
    // ROLES.WRITER,
  ].some((el) => el === props?.profile?.role) ? (
    <div
      className=" flex justify-center items-center mx-3"
      title="Suggest improvement for Admin Panel"
    >
      <Button
        type="default"
        size="large"
        shape="circle"
        onClick={() => {
          setCreateOpen(true);
        }}
      >
        <MdSettingsSuggest size={25} />
      </Button>
      <ModalFormCreator
        loading={API.isLoading}
        open={createOpen}
        onCreate={(v, clear) => {
          // console.log(v);
          API.sendRequest(
            CONSTANTS.API.Suggestions.create,
            (res) => {
              setCreateOpen(false);
              clear();
            },
            v
          );
        }}
        onCancel={() => {
          setCreateOpen(false);
        }}
        menuFields={CONSTANTS.FORM_FIELD.SUGGESTIONS}
        formData={{}}
        name={"Add Suggestions for advancements "}
        SubmitName={"Submit"}
      />
    </div>
  ) : (
    ""
  );
};

export default SuggestionForm;
