import React, { useEffect, useState } from "react";
import ColumnChart from "./ColumnChart";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { ActionAssignArray } from "../../../util/constant/CONSTANTS";
import { Alert, Col, DatePicker, Row, Select, Spin } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const UserGraph = () => {
  const [data, setData] = useState([]);
  const [dates, setDates] = useState({
    startDate: dayjs().subtract(7, "day")?.format("YYYY-MM-DD"),
    endDate: dayjs()?.format("YYYY-MM-DD"),
  });
  // const [model, setModel] = useState("");

  const API = useHttp();
  useEffect(() => {
    let QuaryParams = {};
    if (
      dates &&
      dates?.startDate &&
      dates?.endDate &&
      dates?.startDate !== null &&
      dates?.endDate !== null
    ) {
      QuaryParams.startDate = dates?.startDate;
      QuaryParams.endDate = dates?.endDate;
    }
    // if (!!model) {
    //   QuaryParams.model = model;
    // }

    API.sendRequest(
      CONSTANTS.API.Analytics.InternAnalytics,
      (res) => {
        setData(
          res?.data?.data?.map((el) => ({
            OldData: { ...el },
            ...el,
            // ...el?.user,
            type: `${el?.createdByDisplayName}`,
            value: el?.count,
          }))
        );
      },
      QuaryParams
    );
  }, [dates]);

  const dateFilterFunction = (e) => {
    if (e) {
      setDates({
        startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
        endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} sm={24} md={20} lg={8} xl={6} xxl={5}>
          <RangePicker
            onChange={dateFilterFunction}
            defaultValue={[dayjs(dates.startDate), dayjs(dates.endDate)]}
          />
        </Col>
        {/* <Col span={24} sm={24} md={20} lg={8} xl={5} xxl={4}>
          <Select
            options={ActionAssignArray}
            placeholder="Select Catgory"
            allowClear
            style={{
              minWidth: 200,
            }}
            showSearch
            onChange={setModel}
          />
        </Col> */}
        <Col span={24} className=" w-full">
          {API.isLoading ? (
            <div className="flex justify-center items-center">
              <Spin size="large" />
            </div>
          ) : data?.length ? (
            <ColumnChart data={data} />
          ) : (
            <Alert
              message="Data not found for applied filters"
              showIcon
              // description="Filters applied can not find data"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserGraph;
