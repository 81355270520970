import { useState, useEffect } from "react";
import axios from "axios";

// Custom Hook for fetching Flickr photos
const useFlickrPhotos = (title, pagination) => {
  const [flickrPhotos, setFlickerPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      if (!title) return; // Exit early if title is not provided

      setLoading(true);
      setError(null);

      const apiKey = process.env.REACT_APP_FLICKER_API_KEY;
      const searchURL = `https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=${apiKey}&text=${title}&page=${pagination?.page}&per_page=${pagination?.limit}&sort=relevance&format=json&nojsoncallback=1&extras=license,owner_name,url_o,url_c,url_l,url_m,description&license=1,2,3,4,5,6,7,9,10`;

      try {
        const response = await axios.get(searchURL);
        if (
          response.data &&
          response.data.photos &&
          response.data.photos.photo
        ) {
          const photoList = response.data.photos.photo;
          if (pagination.page === 1) {
            setFlickerPhotos(photoList);
          } else {
            setFlickerPhotos((prev) => [...prev, ...photoList]);
          }
        } else {
          console.log(
            "Search response missing expected properties:",
            response.data
          );
        }
      } catch (error) {
        if (error.response) {
          console.log("Error status:", error.response.status);
          console.log("Error data:", error.response.data);
        } else if (error.request) {
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, [title, pagination]); // Dependencies

  return { flickrPhotos, loading, error };
};

export default useFlickrPhotos;
