import { Button, Card, Checkbox, Col, Image, Row } from "antd";
import React, { useState } from "react";
// const CheckboxAll = {};
const AddImageCard = ({
  img = { id: "Ab", small: "", large: "" },
  onAdd = () => {},
  original,
  loading,
}) => {
  const [checkboxAll, setCheckboxAll] = useState({});
  const handleCheckboxChange = (file, property) => (e) => {
    setCheckboxAll((prev) => ({ ...prev, [property]: e.target.checked }));
    // CheckboxAll[property] = e.target.checked;
  };
  return (
    <Card
      // key={img?.id}
      className="!m-0 !p-0 shadow-md"
      bodyStyle={{ margin: 0, padding: 0 }}
    >
      <Image
        alt="example"
        wrapperStyle={{
          width: "100%",
          height: "100%",
          // minHeight: "175px",
          // maxHeight: "175px",
        }}
        style={{ width: "100%", height: "175px" }}
        // style={{ width: "100%", height: "100%", objectFit: "cover" }}
        key={img?.id}
        src={img?.small}
        preview={{ src: img?.large }}
      />
      <Row className=" justify-around p-4">
        <Col xs={18} className="flex flex-col items-start justify-start">
          <Checkbox onChange={handleCheckboxChange(img?.id, "isProPhoto")}>
            Pro Photo
          </Checkbox>
          <Checkbox onChange={handleCheckboxChange(img?.id, "isDronePhoto")}>
            Drone Photo
          </Checkbox>
          <Checkbox onChange={handleCheckboxChange(img?.id, "isFiveStarPhoto")}>
            5-Star Photo
          </Checkbox>
        </Col>
        <Col xs={6} className="flex justify-center items-center">
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              onAdd(checkboxAll, original);
            }}
          >
            Add
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddImageCard;
