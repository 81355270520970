import { Breadcrumb, Button, Card, Row } from "antd";
import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { ROLES } from "../../../util/Route";

const CategoryPage = (props) => {
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.Category}`,
            title: "Category",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Category.getAll,
            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
              }));
            },
            column: CONSTANTS.TABLE.CATEGORY,
          }}
          UPDATE={{
            API: CONSTANTS.API.Category.update,
            message: "Updated Category successfully",
            modaltitle: "Update Category",
            modalFields: CONSTANTS.FORM_FIELD.CATEGORY,
            // payloadModifier: (res) => res,
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add a Category",
              API: CONSTANTS.API.Category.create,
              message: "Created Category successfully",
              modaltitle: "Add Category",
              modalFields: CONSTANTS.FORM_FIELD.CATEGORY,
            }
          }
          DELETE={{
            API: CONSTANTS.API.Category.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this category?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default CategoryPage;
