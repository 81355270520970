import React, { useEffect, useState } from "react";
import { Row, Select } from "antd";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../util/functions";
// import "antd/dist/antd.css";

const { Option } = Select;

function FilterComponent({
  disabled,
  selectedCity,
  selectedCountry,
  selectedState,
  setSelectedCity,
  setSelectedCountry,
  setSelectedState,
  VisibleObject = {
    cityId: true,
    stateId: true,
    countryId: true,
  },
  extraComponentBehind = <></>,
}) {
  const [statesData, setStatesData] = useState({});
  const [citiesData, setCitiesData] = useState({});

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const API = useHttp();
  // const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectedState, setSelectedState] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    const StateAPI = { ...CONSTANTS.API.getAllState };
    StateAPI.endpoint = `${StateAPI.endpoint}${value}`;
    if (value) {
      if (statesData?.[`${value}`]) {
        setStates(statesData[`${value}`]);
      } else {
        API.sendRequest(StateAPI, (res) => {
          setStatesData((prev) => ({
            ...prev,
            [`${value}`]: res?.data?.rows,
          }));
          setStates(res?.data?.rows);
        });
      }
    }
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(null);
    // const CityAPI = { ...CONSTANTS.API.getAllCity };
    // CityAPI.endpoint = `${CityAPI.endpoint}${selectedCountry}/${value}`;
    const CityAPI = apiGenerator(CONSTANTS.API.getAllCity, {
      countryId: selectedCountry,
      stateId: value,
    });
    if (value) {
      if (citiesData?.[`${selectedCountry}_${value}`]) {
        setCities(citiesData[`${selectedCountry}_${value}`]);
      } else {
        API.sendRequest(CityAPI, (res) => {
          setCitiesData((prev) => ({
            ...prev,
            [`${selectedCountry}_${value}`]: res?.data?.rows,
          }));
          setCities(res?.data?.rows);
        });
      }
    }
    // setCities(citiesData[`${selectedCountry}_${value}`] || []);
  };
  const handleCityChange = (value) => {
    setSelectedCity(value);
    // setCities(citiesData[`${selectedCountry}_${value}`] || []);
  };

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getAllCountry, (res) => {
      setCountries(
        res?.data?.rows?.filter((el) => el?.name && el?.slug) || []
        // ?.map((el, i) => ({ ...el, key: i }))
      );
    });
  }, []);
  useEffect(() => {
    if (selectedCountry && selectedState) {
      // setCountries((prev) => [...prev]);
      if (statesData?.[`${selectedCountry}`]) {
        setStates(statesData[`${selectedCountry}`]);
      } else {
        const StateAPI = { ...CONSTANTS.API.getAllState };
        StateAPI.endpoint = `${StateAPI.endpoint}${selectedCountry}`;
        API.sendRequest(StateAPI, (res) => {
          setStatesData((prev) => ({
            ...prev,
            [`${selectedCountry}`]: res?.data?.rows,
          }));
          setStates(res?.data?.rows);
        });
      }
    }
    if (selectedCountry && selectedState && selectedCity) {
      if (citiesData?.[`${selectedCountry}_${selectedState}`]) {
        setCities(citiesData[`${selectedCountry}_${selectedState}`]);
      } else {
        const CityAPI = apiGenerator(CONSTANTS.API.getAllCity, {
          countryId: selectedCountry,
          stateId: selectedState,
        });
        API.sendRequest(CityAPI, (res) => {
          setCitiesData((prev) => ({
            ...prev,
            [`${selectedCountry}_${selectedState}`]: res?.data?.rows,
          }));
          setCities(res?.data?.rows);
        });
      }
    }
  }, [selectedCountry, selectedCity, selectedState]);
  return (
    <Row
      gutter={[16, 16]}
      className=" justify-center items-center md:justify-start"
    >
      {VisibleObject?.countryId && (
        <Select
          showSearch
          placeholder="Select a Country"
          onChange={handleCountryChange}
          style={{ width: "200px" }}
          disabled={disabled}
          value={selectedCountry}
          allowClear
          filterOption={(inputValue, option) => {
            const value = `${option?.children}`;
            return value?.toLowerCase()?.includes(inputValue?.toLowerCase());
          }}
        >
          {countries.map((country) => (
            <Option key={country.id} value={country.id}>
              {country.name}
            </Option>
          ))}
        </Select>
      )}
      {VisibleObject?.stateId && VisibleObject?.countryId && (
        <Select
          showSearch
          placeholder="Select a State"
          onChange={handleStateChange}
          style={{ width: "200px", margin: "0 8px" }}
          disabled={!selectedCountry || disabled}
          value={selectedState}
          allowClear
          filterOption={(inputValue, option) => {
            const value = `${option?.children}`;
            return value?.toLowerCase()?.includes(inputValue?.toLowerCase());
          }}
        >
          {states.map((state) => (
            <Option key={state.id} value={state.id}>
              {state.name}
            </Option>
          ))}
        </Select>
      )}
      {VisibleObject?.stateId &&
        VisibleObject?.countryId &&
        VisibleObject?.cityId && (
          <Select
            showSearch
            placeholder="Select a City"
            value={selectedCity}
            onChange={handleCityChange}
            style={{ width: "200px" }}
            allowClear
            filterOption={(inputValue, option) => {
              const value = `${option?.children}`;
              return value?.toLowerCase()?.includes(inputValue?.toLowerCase());
            }}
            disabled={!selectedState || !selectedCountry || disabled}
          >
            {cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </Select>
        )}
      {extraComponentBehind}
    </Row>
  );
}

export default FilterComponent;
