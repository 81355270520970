import {Button, Card, Row} from "antd";
import React from "react";
import useHttp from "../../../hooks/use-http";

const Setting = () => {
  const API = useHttp();
  const FlushAll = () => {
    API.sendRequest(
      {type: "POST", endpoint: `/admin/flushAll`},
      () => {},
      null,
      "Data will be filled in ~5 minutes."
    );
  };
  const RefillData = () => {
    API.sendRequest(
      {type: "POST", endpoint: `/admin/refillElasticData`},
      () => {},
      null,
      "Data has been refill successfully."
    );
  };
  const AlgoliaScriptRun = () => {
    API.sendRequest(
      {type: "GET", endpoint: `/admin/general/algolia`},
      () => {},
      null,
      "Algolia Script started successfully Data will be updated in 5 min please wait."
    );
  };
  return (
    <>
      <Card className=" mt-6">
        <Row className="flex justify-between">
          <Button type="primary" onClick={RefillData} loading={API.isLoading}>
            Refill Elastic Data
          </Button>
          <Button
            type="default"
            // danger
            onClick={AlgoliaScriptRun}
            loading={API.isLoading}
          >
            Algolia Fill Up
          </Button>
          <Button
            type="default"
            danger
            onClick={FlushAll}
            loading={API.isLoading}
          >
            Refill Redis-stack Data
          </Button>
        </Row>
      </Card>
    </>
  );
};

export default Setting;
