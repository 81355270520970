import axios from "axios";
const Services = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL_SECOND,
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${
      // document.cookie ? document.cookie.replace("SAID=", "") : null
      localStorage
        ? localStorage?.getItem("token") && localStorage.getItem("token")
        : null
    }`,
    "ngrok-skip-browser-warning": "bro",
  },
});

export default Services;
