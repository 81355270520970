import { Breadcrumb, Button, Card, Row, Tag } from "antd";
import React, { useEffect } from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { ROLES } from "../../../util/Route";
import moment from "moment";
import useHttp from "../../../hooks/use-http";
import { ProceedImageURLForBlog, apiGenerator } from "../../../util/functions";

const BlogPage = (props) => {
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(
      CONSTANTS.API.Tag.getAll,
      (res) => {
        const blogfields = CONSTANTS.FORM_FIELD.BLOG_MODAL.find(
          (field) => field.id === "tags"
        );
        const editblogfields = CONSTANTS.FORM_FIELD.EDIT_BLOG_MODAL.find(
          (field) => field.id === "tags"
        );
        const OptionTags = res?.data?.rows?.map((tag) =>
          //   {
          //   // ...tag,
          //   name: tag?.tag,
          //   label: tag?.tag,
          //   value: tag?.id,
          //   id: tag?.id,
          // }
          ({ id: tag?.id, label: tag?.tag, value: tag?.id })
        );
        blogfields.option = OptionTags;
        editblogfields.option = OptionTags;
      },
      {
        limit: 2000,
        page: 1,
        sortBy: "tag",
        sortOrder: "ASC",
      }
    );
    API.sendRequest(
      CONSTANTS.API.Category.getAll,
      (res) => {
        const blogfields = CONSTANTS.FORM_FIELD.BLOG_MODAL.find(
          (field) => field.id === "categories"
        );
        const editblogfields = CONSTANTS.FORM_FIELD.EDIT_BLOG_MODAL.find(
          (field) => field.id === "categories"
        );
        const OptionCategories = res?.data?.rows?.map((category) =>
          // ({
          //   ...category,
          //   // name: category?.name,
          //   // label: category?.name,
          //   // value: category?.id,
          //   // id: category?.id,
          //   Label: category?.name,
          //   value: category?.id,
          //   id: category?.id,
          // })
          ({ id: category?.id, label: category?.name, value: category?.id })
        );
        blogfields.option = OptionCategories;
        editblogfields.option = OptionCategories;
      },
      {
        limit: 2000,
        page: 1,
        sortBy: "name",
        sortOrder: "ASC",
      }
    );
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.Blog}`,
            title: "Blog",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.Blog.getAll,
            DataModifier: (res, API, Setrefresh) => {
              // console.log(
              //   res?.map((el) => ({
              //     ...el,
              //     no: `${el?.id}`.padStart(6, "0"),
              //     date: moment(el?.createdAt).format("lll"),
              //     categories: el?.blogCategories?.map((ele) => ele?.categoryId),
              //     tags: el?.blogTags?.map((ele) => ele?.tagId),
              //     readTime: `${el?.readTime} Minutes`,
              //   })),
              //   "GENERATED DATA"
              // );
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                date: moment(el?.createdAt).format("lll"),
                categories: el?.blogCategories?.map((ele) => ele?.categoryId),
                tags: el?.blogTags?.map((ele) => ele?.tagId),
                readTime: `${el?.readTime} Minutes`,
                image: ProceedImageURLForBlog(el?.id, 3),
                rankInput: {
                  value: el?.index,
                  id: el?.id,
                  onChange: (v) => {
                    const UpadteBlogRandListicle = apiGenerator(
                      CONSTANTS.API.Blog.update,
                      {},
                      v.id
                    );
                    API.sendRequest(
                      UpadteBlogRandListicle,
                      (res) => {
                        Setrefresh((prev) => !prev);
                      },
                      {
                        index: v?.value,
                      }
                    );
                  },
                },
              }));
            },

            column: CONSTANTS.TABLE.BLOG,
          }}
          UPDATE={{
            API: CONSTANTS.API.Blog.update,
            message: "Updated Blog successfully",
            modaltitle: "Update Blog",
            modalFields: CONSTANTS.FORM_FIELD.EDIT_BLOG_MODAL,
            isFormData: true,
            payloadModifier: (res) => {
              return res;
            },
          }}
          CREATE={
            [
              ROLES.ADMIN,
              // ROLES.DATA,
              // ROLES.IMAGE,
              ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add a Blog",
              API: CONSTANTS.API.Blog.create,
              message: "Created Blog successfully",
              modaltitle: "Add Blog",
              modalFields: CONSTANTS.FORM_FIELD.BLOG_MODAL,
              isFormData: true,
            }
          }
          DELETE={{
            API: CONSTANTS.API.Blog.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this category?",
          }}
          FILTERSORTKEY={{
            views: "$views",
            likes: "$likes",
            wishlists: "$wishlists",
            date: "updatedAt",
            // views: "$views",
            // comments: "$comments",
          }}
          isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default BlogPage;
