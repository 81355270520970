import React, {useState} from "react";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";

const UserLogs = ({extraQuery}) => {
  return (
    <CRUDComponent
      GET={{
        API: CONSTANTS.API.Log.getAll,
        extraQuery,
        DataModifier: (res) => {
          return res?.map((el) => ({
            ...el,
            name: el?.AllBeach?.name,
            userName: el?.user?.name,
            userEmail: el?.user?.email,
            userRole: el?.user?.role,
            no: `${el?.id}`.padStart(6, "0"),
          }));
        },
        column: extraQuery?.userId
          ? CONSTANTS.TABLE.LOG?.filter((el) => !el?.particularUser)
          : CONSTANTS.TABLE.LOG,
      }}
      //   DELETE={{
      //     API: CONSTANTS.API.Log.delete,
      //     message: "Deleted Successfully",
      //     confirm_message: "Are you sure to Delete this Contact?",
      //   }}
      FILTERSORTKEY={{
        userRole: "$user.role$",
        userName: "userId",
        userEmail: "$user.email$",
        // name: "$AllBeach.name$",
      }}
      isSearch
      datefilter
      selectionOff
    />
  );
};

export default UserLogs;
