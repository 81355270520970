import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import theme from "./theme.json";
import "./index.css";
import ErrorBoundary from "./component/layout/ErrorBoundry";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ErrorBoundary>
      <ConfigProvider theme={theme}>
        <App />
      </ConfigProvider>
    </ErrorBoundary>
  </>
);
