import { Button, Card, Divider, Form, Radio, Typography } from "antd";
import React, { useEffect, useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator, getClearObject } from "../../../../util/functions";
import { ROLES } from "../../../../util/Route";

const Summary = ({ beachData, identity, notdisabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [SummaryData, setSummaryData] = useState({});
  const [refresh, setRefresh] = useState(true);

  const API = useHttp();

  const handleEdit = () => {
    setIsEditing(true);

    // Copy current data to the editedData state
  };
  const DisplayData = [
    {
      label: "introduction",
      value: editedData?.introduction || editedData?.introduction,
      condition: editedData?.introduction,
    },
    {
      label: "summary",
      value: editedData?.summary || editedData?.summary,
      condition: editedData?.summary,
    },
    {
      label: "activities",
      value: editedData?.activities || editedData?.activities,
      condition: editedData?.activities,
    },
    {
      label: "amenities",
      value: editedData?.amenities || editedData?.amenities,
      condition: editedData?.amenities,
    },
    {
      label: "attraction",
      value: editedData?.attraction || editedData?.attraction,
      condition: editedData?.attraction,
    },
    {
      label: "disabilityAccess",
      value: editedData?.disabilityAccess || editedData?.disabilityAccess,
      condition: editedData?.disabilityAccess,
    },
    {
      label: "funFact",
      value: editedData?.funFact || editedData?.funFact,
      condition: editedData?.funFact,
    },
    {
      label: "hotel",
      value: editedData?.hotel || editedData?.hotel,
      condition: editedData?.hotel,
    },
    {
      label: "parking",
      value: editedData?.parking || editedData?.parking,
      condition: editedData?.parking,
    },
    {
      label: "restaurant",
      value: editedData?.restaurant || editedData?.restaurant,
      condition: editedData?.restaurant,
    },
  ];
  const ContentSource = [
    { id: 1, label: "AI-Generated", value: "AI-Generated" },
    { id: 2, label: "Hand-Written", value: "Human-Written" },
  ];
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // setEditedData(values);
        const clearObject = getClearObject(values);
        const payload = {};
        Object.entries(values).forEach((el) => {
          payload[`${el[0]}`] = !clearObject[el[0]] ? "<p><br></p>" : el[1];
        });
        payload.contentSource=values.contentSource ?? null;
        setIsEditing(false);
        if (SummaryData?.id && beachData?.id) {
          payload.AllBeachId = beachData?.id;
          const UpdateSummary = apiGenerator(
            CONSTANTS.API.BeachSummary.update,
            {
              id: SummaryData?.id,
            }
          );
          API.sendRequest(
            UpdateSummary,
            (res) => {
              setEditedData(values);
              setRefresh((prev) => !prev);
            },
            payload,
            "Summary Updated successfully"
          );
        } else {
          if (beachData?.id) {
            const CreateSummary = apiGenerator(
              CONSTANTS.API.BeachSummary.create
            );
            payload.AllBeachId = beachData?.id;
            API.sendRequest(
              CreateSummary,
              (res) => {
                setEditedData(values);
                setRefresh((prev) => !prev);
              },
              payload,
              "Summary Updated successfully"
            );
          }
        }
        // onCreate(values, () => {
        //   setDefaultData(null);
        // });
        // onChangedFields(changedFields);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  useEffect(() => {
    if (beachData?.id) {
      const BasicDetailsAPI = apiGenerator(CONSTANTS.API.BeachSummary.getOne, {
        AllBeachId: beachData?.id,
      });
      API.sendRequest(BasicDetailsAPI, (res) => {
        setEditedData(res?.data || {});
        setSummaryData(res?.data || {});
      });
    }
  }, [beachData, refresh]);
  const [form] = Form.useForm();

  return (
    <>
      <Card bordered className="m-4">
        <Typography.Title level={3} className="flex justify-between">
          Summary
          {[
            ROLES.ADMIN,
            ROLES.DATA,
            ROLES.IMAGE,
            ROLES.INTERN,
            ROLES.SEO,
            ROLES.SUPER_ADMIN,
            ROLES.SUPERUSER,
            ROLES.WRITER,
          ].some((el) => el === identity) &&
            notdisabled &&
            (isEditing ? (
              <Button
                type="primary"
                danger
                onClick={() => {
                  // setEditedData({});
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button danger onClick={handleEdit}>
                Edit
              </Button>
            ))}
        </Typography.Title>
        <Divider />
        {isEditing ? (
          <>
            <FormFields
              menuFields={CONSTANTS.FORM_FIELD.BEACH_SUMMARY_EDIT}
              formData={editedData}
              form={form}
            />
            <Button type="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </>
        ) : (
          <>
            <div className="flex items-center gap-x-4 my-3">
              <span className="text-lg font-semibold">Content Source : </span>
              <div className="flex items-center gap-x-4">
                {ContentSource?.map((i, index) => {
                  return (
                    <div
                      className={`text-sm flex items-center gap-x-1`}
                    >
                      <div
                        className={`w-4 h-4 rounded-full relative flex items-center justify-center ${
                          editedData?.contentSource === i?.value
                            ? "bg-[#00aae3]"
                            : "border border-gray-400"
                        }`}
                      >
                        <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                      </div>
                      {i?.label}
                    </div>
                  );
                })}
              </div>
            </div>
            {DisplayData?.map(
              (el) =>
                el?.condition &&
                el?.condition !== null &&
                el?.condition !== "<p><br></p>" &&
                el?.condition !== "" && (
                  <Card
                    bordered
                    className="my-2 border-gray-400"
                    key={el?.label}
                  >
                    <Typography.Title
                      level={4}
                      className="!pb-1 !mb-1 capitalize"
                    >
                      {`${el?.label} : `}
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="!m-0 !p-0 capitalize"
                      // dangerouslySetInnerHTML={{ __html: el?.value }}
                    >
                      {/* {`${el?.label} : `} */}
                      <span
                        className="font-light"
                        dangerouslySetInnerHTML={{
                          __html: el?.value,
                          // __html: editedData?.[el?.label],
                        }}
                      ></span>
                    </Typography.Title>
                  </Card>
                )
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default Summary;
