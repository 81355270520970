import { Alert, Button, Col, Image, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ScrollLoader from "../advance/ScrollLoader";
import { FinalImageGenerator } from "../../../util/functions";

const LocationImageSelect = ({
  locationData = {},
  refresher = () => {},
  type = "Country",
  API,
  onSuccess,
}) => {
  const [loadoff, setLoadOff] = useState(false);
  const [images, SetIamges] = useState([]);
  //   const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  useEffect(() => {
    const query = {};
    if (type === "Country" && locationData?.id) {
      query.countryId = locationData?.id;
    } else if (type === "State" && locationData?.id) {
      query.stateId = locationData?.id;
    } else if (type === "City" && locationData?.id) {
      query.cityId = locationData?.id;
    }
    else if (type === "Island" && locationData?.id) {
      query.islandId = locationData?.id;
    }

    // if (!!selectedPhotographer) {
    //   query.createdByDisplayName = selectedPhotographer;
    // }
    // if (SelectedAtributes.length > 0) {
    //   SelectedAtributes.forEach((el) => {
    //     query[`${el}`] = true;
    //   });
    // }
    API.sendRequest(
      { type: "GET", endpoint: "/admin/image" },
      (res) => {
        // SetIamgesData(res?.data);
        if (res?.data?.length < pagination?.limit) {
          setLoadOff(true);
        }
        if (pagination.page === 1) {
          SetIamges(res?.data || []);
        } else {
          SetIamges((prev) => [...prev, ...res?.data]);
        }
      },
      { ...pagination, ...query }
    );
  }, [pagination.page]);
  return (
    <>
      <Row gutter={[16, 8]} className=" mt-3">
        {images.map((item, index) => (
          <Col
            key={`${item?.id}-${index}`}
            xs={24}
            sm={12}
            md={8}
            lg={6}
            xl={6}
            xxl={6}
            onClick={() => {
              const formData = new FormData();
              formData.append("imageId", item?.id);
              onSuccess(formData);
            }}
            className="cursor-pointer"
          >
            <Image
              alt="example"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                minHeight: "175px",
                maxHeight: "175px",
              }}
              rootClassName=" rounded-lg"
              wrapperClassName=" rounded-lg"
              draggable="false"
              className="!w-full !h-full rounded-lg cursor-pointer"
              // src={item?.imageUrl}
              //   preview={{
              //     src: FinalImageGenerator(item, 1600, 3),
              //   }}
              preview={false}
              src={FinalImageGenerator(item, 1400)}
            />
          </Col>
        ))}
      </Row>

      <Row className=" items-center justify-center my-3">
        {!images?.length && !API.isLoading && (
          <Alert
            message="No Photos Found"
            description="We couldn't find any photos of the specified beach. It's possible that there are no images available for this location. Please check the spelling or try another beach to discover beautiful photos."
            type="info"
            showIcon
          />
        )}
        {API.isLoading ? (
          <Spin size="large" />
        ) : loadoff ? (
          ""
        ) : (
          //   <ScrollLoader
          //     threshold={60}
          //     loadMoreFunction={() => {
          //       setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
          //     }}
          //     isLoading={API.isLoading}
          //     loadoff={loadoff}
          //     timeout={10}
          //   />
          <Button
            type="primary"
            className=" mt-3"
            onClick={() => {
              setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
            }}
          >
            Load More
          </Button>
        )}
      </Row>
    </>
  );
};

export default LocationImageSelect;
