import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import {
  Breadcrumb,
  Card,
  Col,
  Image,
  Popconfirm,
  Row,
  notification,
} from "antd";
import { MdApproval, MdDeleteSweep } from "react-icons/md";
import Meta from "antd/es/card/Meta";

const UserPhotos = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const API = useHttp();
  useEffect(() => {
    API.sendRequest(
      {
        type: "GET",
        endpoint: "/admin/image?approved=false",
      },
      (res) => {
        setData(
          res?.data?.map((row) => {
            return {
              ...row,
            };
          }) || []
        );
      }
    );
  }, [refresh]);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            title: "UnApproved Photos",
          },
        ]}
      />
      <Row gutter={[13, 12]}>
        {data?.map((image) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={image?.image}>
            <Card
              style={
                {
                  // width: 300,
                  // height: 300,
                }
              }
              cover={
                <>
                  <Image
                    alt="example"
                    src={image?.imageUrl}
                    style={{
                      height: 200,
                      minHeight: "200px",
                    }}
                  />
                </>
              }
              actions={[
                <Popconfirm
                  title="Sure to delete Photo?"
                  onConfirm={() => {
                    API.sendRequest(
                      {
                        type: "DELETE",
                        endpoint: `/admin/image/${image?.id}`,
                      },
                      (res) => {
                        if (res) {
                          setRefresh((prev) => !prev);
                          notification.success({
                            message: "Image Deleted Successfully",
                            duration: 3,
                          });
                        }
                      }
                    );
                  }}
                >
                  <MdDeleteSweep key="Delete" className=" fill-red-400" />
                </Popconfirm>,
                // <EditOutlined key="edit" />,
                <MdApproval
                  key="Approve"
                  onClick={() => {
                    API.sendRequest(
                      {
                        type: "PATCH",
                        endpoint: `/admin/image/${image?.id}`,
                      },
                      (res) => {
                        if (res) {
                          setRefresh((prev) => !prev);
                          notification.success({
                            message: "Image Approved Successfully",
                            duration: 3,
                          });
                        }
                      },
                      { approved: true }
                    );
                  }}
                />,
              ]}
            >
              <Meta title={`${image?.AllBeach?.name}`} />
            </Card>

            {/* <div
              style={{
                background: "#f0f0f0",
                width: "100%",
                borderRadius: "10px",
                height: "195px",
                padding: 0,
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  bottom: "8px",
                  left: "9px",
                }}
              >
                {image?.createdByDisplayName && (
                  <>
                    <div
                      style={{
                        color: "white",
                        background: "#00000063",
                        borderRadius: "60px",
                        margin: "auto",
                        fontWeight: "200",
                        padding: "0 3px",
                        paddingRight: "0px",
                      }}
                    >
                      ©
                    </div>
                    <div
                      style={{
                        background: "#ffffffcc",
                        color: "#000000cc",
                        width: "auto",
                        borderRadius: "50px",
                        padding: "0px 9px",
                        marginLeft: "4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {image?.createdByDisplayName}
                    </div>
                  </>
                )}
              </div>
            </div> */}
            {/* <p>{`${image?.AllBeach?.name}`}</p> */}
            {/* <p>{`${image?.AllBeach?.name}, ${image?.AllBeach?.city}, ${image?.AllBeach?.state}, ${image?.AllBeach?.country}`}</p> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <button
                type="button"
                style={{
                  background: "#ea7e7e",
                  color: "#fff",
                  fontSize: "18px",
                  borderRadius: "5pt",
                  border: "none",
                  height: "35px",
                  width: "100px",
                }}
                onClick={async () => {
                  API.sendRequest(
                    {
                      type: "DELETE",
                      endpoint: `/admin/image/${image?.id}`,
                    },
                    (res) => {
                      if (res) {
                        setRefresh((prev) => !prev);
                        notification.success({
                          message: "Image Deleted Successfully",
                          duration: 3,
                        });
                      }
                    }
                  );
                }}
              >
                Delete
              </button>

              <button
                type="button"
                style={{
                  background: "#3688c9",
                  color: "#fff",
                  fontSize: "18px",
                  borderRadius: "5pt",
                  border: "none",
                  height: "35px",
                  width: "100px",
                }}
                onClick={() => {
                  API.sendRequest(
                    {
                      type: "PATCH",
                      endpoint: `/admin/image/${image?.id}`,
                    },
                    (res) => {
                      if (res) {
                        setRefresh((prev) => !prev);
                        notification.success({
                          message: "Image Approved Successfully",
                          duration: 3,
                        });
                      }
                    },
                    { approved: true }
                  );
                }}
              >
                Approve
              </button>
            </div> */}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default UserPhotos;
