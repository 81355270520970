import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ALL_ROUTES, { ROLES } from "./util/Route";
import useLocalStorage from "./hooks/use-localStorage";
import { generateRoutes } from "./util/functions";
function App() {
  const [profile, SetProfile] = useLocalStorage("profile", {
    role: ROLES.DEFAULT,
  });

  if (Object.values(ROLES).findIndex((el) => el === profile.role) === -1) {
    localStorage.clear();
    document.cookie = "SAID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const router = createBrowserRouter(
    generateRoutes(
      ALL_ROUTES({ data: profile, profile, SetProfile }),
      profile?.role
    )
  );
  return <RouterProvider router={router} />;
}

export default App;
