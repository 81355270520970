import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

export default function BeachSample({ beaches }) {
  const [loaded, setLoaded] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const SetBeaches = () => {
    if (!mapContainer.current || !beaches?.length || !loaded) {
      return;
    }

    if (map.current.getSource("beaches")) {
      map.current.getSource("beaches").setData({
        type: "FeatureCollection",
        features: beaches.map((beach) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [beach.long, beach.lat],
          },
          properties: beach,
        })),
      });
    } else {
      map.current.addSource("beaches", {
        type: "geojson",

        data: {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "urn:ogc:def:crs:OGC:1.3:CRS84",
            },
          },
          features: beaches.map((beach) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [beach.long, beach.lat, 0],
            },
            properties: beach,
          })),
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });
      map.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "beaches",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 25px circles when point count is between 100 and 750
          //   * Pink, 30px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#f1f075",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            25,
            750,
            30,
          ],
        },
      });

      map.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "beaches",
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });
      map.current.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "beaches",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-radius": 10,
          "circle-color": ["get", "color"], // use color from feature properties
          // "circle-color": "red", // use color from feature properties
          "circle-stroke-color": "white",
          "circle-stroke-width": 1,
          "circle-opacity": 0.9,
        },
      });

      // inspect a cluster on click
      map.current.on("click", "clusters", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource("beaches")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      const popup = new mapboxgl.Popup({
        // closeButton: false,
        // closeOnClick: false,
      });
      map.current.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();

        const { locationAdress } = e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(coordinates)
          .setHTML(`${locationAdress}`)
          .addTo(map.current);
      });

      map.current.on("mouseenter", "clusters", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });
      map.current.on("mouseleave", "clusters", () => {
        map.current.getCanvas().style.cursor = "";
      });
      map.current.addControl(new mapboxgl.ScaleControl());
    }
  };
  useEffect(() => {
    if (!beaches?.length) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      // center: [69.10855, 22.455],
      // center: [-71.246587, 41.4889363],
      // zoom: 10,
      center: [-110.25, 36.46],
      // center: [-119.25, 36.46],
      // center: [-75.0, 43.0],
      zoom: 5,
    });
    map.current.on("load", () => {
      setLoaded(true);
    });

    return () => map.current.remove();
  }, []);
  useEffect(() => {
    SetBeaches();
  }, [beaches, loaded]);

  return (
    <div className="relative">
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}
