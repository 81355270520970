import { Breadcrumb, Button, Card, Row } from "antd";
import React, { useEffect } from "react";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { ROLES } from "../../../../util/Route";
import useHttp from "../../../../hooks/use-http";

const SharkSpecies = (props) => {
  const API = useHttp();

  useEffect(() => {
    API.sendRequest(CONSTANTS.API.getAllCountry, (res) => {
      const countryList = res?.data?.rows?.map((i) => {
        return {
          ...i,
          label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.SHARK_SPECIES_MODAL.find(
        (el) => el?.id === "countryIds"
      ).option = countryList;
    });
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.Category}`,
            title: "Shark Species",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.SharkSpecies.getAll,
            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                countryIds: el?.sharkSpeciesCountries?.map((i) => {
                  return {
                    ...i,
                    label: i?.country?.name,
                    value: i?.country?.id,
                    id: i?.country?.id,
                  };
                }),
              }));
            },
            column: CONSTANTS.TABLE.SHARK_SPECIES,
          }}
          UPDATE={{
            API: CONSTANTS.API.SharkSpecies.update,       
            message: "Shark species updated successfully",
            modaltitle: "Update Shark Species",
            modalFields: CONSTANTS.FORM_FIELD.SHARK_SPECIES_MODAL,
            payloadModifier: (res) => {
              const updatedRes = { ...res };
              // Check if 'countryIds' exists
              if (updatedRes.hasOwnProperty("countryIds")) {
                const countryIds = updatedRes["countryIds"];

                // Check if 'countryIds' is an array of objects
                if (
                  Array.isArray(countryIds) &&
                  countryIds.length > 0 &&
                  typeof countryIds[0] === "object"
                ) {
                  delete updatedRes["countryIds"];
                }
              }

              if (updatedRes?.sizeSummary === null) {
                delete updatedRes["sizeSummary"];
              }
              return updatedRes;
            },
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add Shark Species",
              API: CONSTANTS.API.SharkSpecies.create,
              message: "Shark species created successfully",
              modaltitle: "Add Shark Species",
              modalFields: CONSTANTS.FORM_FIELD.SHARK_SPECIES_MODAL,
            }
          }
          DELETE={{
            API: CONSTANTS.API.SharkSpecies.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Shark Species?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default SharkSpecies;
